<template>
    <select @change="onSelect()" v-model="selectedMacro" v-if="canShow">
        <option value="" hidden>
            Selecione uma macro de feedback      
        </option>
        <option v-for="macro in macros" :key="macro.id" :value="macro.content">
            {{macro.title}}
        </option>
    </select>
</template>
<script>

import {mapGetters} from "vuex"
import auditMacros from "../../../api/auditMacros" 

export default {
    data() {
        return{
            selectedMacro: "",
            macros: []
        }
    },

    async mounted() {
       const {data:macros} = await auditMacros.get()
       this.macros = macros
    },

    methods: {
        onSelect() {
            this.$emit("audit-macro-selected", {content: this.selectedMacro})
        }
    },

    computed: {
        ...mapGetters({
            flags: "getAppFlags"
        }),
        canShow() {
            return this.flags.audit_macro
        }
    }
}
</script>