<template>
  <div
    class="avatar-container"
    :class="{
      'avatar-container--good': criticality.good,
      'avatar-container--medium': criticality.medium,
      'avatar-container--bad': criticality.bad
    }"
    :style="[style, customStyle]"
    aria-hidden="true"
    v-tooltip="tooltip ? tooltip : null"
  >
    <img v-if="criticality.medium" class="avatar-icon" src="~dashboard/assets/images/warning_medium.svg"/>
    <img v-else-if="criticality.bad" class="avatar-icon" src="~dashboard/assets/images/warning_bad.svg"/>
    <span v-else> {{ userInitial }} </span>
  </div>
</template>

<script>
export default {
  name: 'Avatar',
  props: {
    username: {
      type: String,
      default: '',
    },
    backgroundColor: {
      type: String,
      default: 'white',
    },
    color: {
      type: String,
      default: '#1f93ff',
    },
    customStyle: {
      type: Object,
      default: undefined,
    },
    size: {
      type: Number,
      default: 40,
    },
    src: {
      type: String,
      default: '',
    },
    rounded: {
      type: Boolean,
      default: true,
    },
    criticality: {
      type: Object,
      default(rawProps){
        return {
          good: true,
          medium: false,
          bad: false,
        }
      }
    },
    tooltip: {
      type: String,
      default: '',
    }
  },
  computed: {
    style() {
      return {
        width: `${this.size}px`,
        height: `${this.size}px`,
        borderRadius: this.rounded ? '50%' : 0,
        lineHeight: `${this.size + Math.floor(this.size / 20)}px`,
        backgroundColor: this.backgroundColor,
        fontSize: `${Math.floor(this.size / 2.5)}px`,
        color: this.color,
      };
    },
    userInitial() {
      return this.initials || this.initial(this.username);
    },
  },
  methods: {
    initial(username) {
      const parts = username ? username.split(/[ -]/) : [];
      let initials = '';
      for (let i = 0; i < parts.length; i += 1) {
        initials += parts[i].charAt(0);
      }
      if (initials.length > 2 && initials.search(/[A-Z]/) !== -1) {
        initials = initials.replace(/[a-z]+/g, '');
      }
      initials = initials.substr(0, 2).toUpperCase();
      return initials;
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar-container {
  display: flex;
  font-weight: 500;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-image: linear-gradient(to top, #c3713c 0%, #ff8638 100%);

  &--bad {
    background-image: linear-gradient(to top, #b00000 0%,#f00 100%);
  }

  &--medium {
    background-image: linear-gradient(to top, #bb9f00 0%, #efcb00 100%);
  }
}
.avatar-icon {
  position: relative;
  top: -2px;
  width: 60%;
  height: 60%
}
</style>
