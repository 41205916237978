/* global axios */
import ApiClient from './ApiClient';

class UsersAPI extends ApiClient {
  constructor() {
    super('users', { accountScoped: true });
  }

  async getStatus() {
    const result = await axios.get(`${this.url}/status`);
    return result.data
  }

  async setCompleteTutorial() {
    const result = await axios.post(`${this.url}/complete_tutorial`)
    return result.data
  }

  async setUserActive() {
    const result = await axios.post(`${this.url}/active_user`)
    return result.data
  }
}

export default new UsersAPI();
