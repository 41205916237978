<template>
  <modal :close-on-backdrop-click="false" :show.sync="show" :on-close="handleClose">
    <woot-modal-header :header-title="textModalHeader" :header-content="textHeaderContent" />
    
    <div v-if="showFeedbackCategories" class="modal-content add-comment">
      <feedback-category-select 
        @feedback-category-selected="handleCommentSelected" 
        :is-add-label="isAddLabel" 
        :comment="messageComment"
      />
      <div v-if="content" class="feedback-preview">
        <div class="feedback-preview__title">Devolutiva</div> 
        <div class="feedback-preview__content">"{{content}}"</div>
      </div>
    </div>
    <div v-else class="modal-content add-comment">
      <macro-select @audit-macro-selected="handleCommentSelected"/>
      <woot-text-area 
        v-model.trim="content" 
        class="add-comment__input"
        :class="{error: $v.content.$error}"
        rows="4"
        :error="$v.content.$error ? 'Campo obrigatório' : ''"
        @blur="$v.content.$touch"
        >
      </woot-text-area>
    </div>

    <div class="modal-footer delete-item">
      <button 
        :disabled="$v.content.$invalid" 
        class="button nice" @click="handleConfirm"
      >
        {{ messageComment ? 'Atualizar' : 'Adicionar' }}
      </button>
      <button class="button clear" @click="handleClose">
        Cancelar
      </button>
      <button class="button alert delete-button" @click="handleDeleteComment">
        <i class="ion-ios-trash" />
      </button>
    </div>
  </modal>
</template>

<script>
import { mapGetters } from 'vuex';
import Modal from '../../Modal';
import MacroSelect from './MacroSelect';
import FeedbackCategorySelect from './FeedbackCategorySelect';
import { MESSAGE_TYPE } from 'shared/constants/messages';
import { required } from 'vuelidate/lib/validators';

export default {
  components: {
    Modal,
    MacroSelect,
    FeedbackCategorySelect
  },
  data() {
    return {
      content: '',
      metadata: null,
    };
  },
  props: {
    show: Boolean,
    messageComment: Object,
    onConfirm: Function,
    onDelete: Function,
    messageId: Number,
    onClose: Function,
    messageType: Number
  },
  computed: {
    ...mapGetters({ 
      currentChat: 'getSelectedChat',
      flags: "getAppFlags"
    }),
    showFeedbackCategories(){
      return !!this.flags.feedback_category.projects.includes(this.currentChat.project)
    },
    textModalHeader(){
      if (this.showFeedbackCategories && this.isAddLabel){
        return 'Adicionar Feedback de Tag incorreta'
      } else if(this.showFeedbackCategories) {
        return 'Adicionar Feedback'
      } else {
        return 'Adicionar comentário'
      }
    },
    isAddLabel(){
      return this.messageType === MESSAGE_TYPE.ADD_LABEL
    },
    textHeaderContent(){
      if (this.showFeedbackCategories && this.isAddLabel){
        return 'Selecione qual deveria ser a tag correta'
      } else if(this.showFeedbackCategories) {
        return 'Selecione a categoria do feedback.'
      } else {
        return 'Detalhe aqui o ponto de melhoria observado na interação e/ou como a pessoa Pro poderia ter feito diferente.'
      }
    }
  },
  mounted() {
    this.content = this.messageComment?.content || ''
    this.metadata = this.messageComment?.metadata
  },
  methods: {
    handleClose() {
      this.content = this.messageComment?.content || ''
      this.onClose()
    },
    async handleConfirm() {
      const conversationId = this.currentChat.id
      const auditComment = { messageId: this.messageId, content: this.content, metadata: this.metadata }
      
      await this.$store.dispatch('addAuditComment', {
        conversationId,
        auditComment,
      });
      
      this.onConfirm(auditComment)
    },
    handleCommentSelected(value){
      this.content = value.content
      this.metadata = value.metadata
    },
    async handleDeleteComment() {
      const conversationId = this.currentChat.id
      const auditComment = { messageId: this.messageId, content: this.content, metadata: this.metadata }

      await this.$store.dispatch('removeAuditComment', {
        conversationId,
        auditComment,
      });
      this.content = ''
      this.onDelete()
    }
  },
  validations: {
    content: {
      required
    }
  }
};
</script>

<style lang="scss" scoped>
.add-comment {
  &__input {
    resize: none;
  }
}
.delete-button {
  margin: auto !important;
  margin-right: 0 !important;

  i {
    font-size: 20px;
    color: rgba(255, 255, 255, 0.904);
  }
}

.feedback-preview {
  border: 1px solid #bbb;
  border-radius: 4px;
  margin-top: 5px;
  background-color: #eee;
  color: #333;
  padding: 10px;
  font-size: 14px;

  &__title {
    font-weight: bold;
  }

  &__content {
    font-style: italic;
    margin-top: 5px;
  }

}
</style>
