<template>
  <div
    class="conversation"
    :class="{
      active: isActiveChat,
      'unread-chat': hasUnread,
      'has-inbox-name': showInboxName,
      'conversation--good': isSLAShowlable && sla.good,
      'conversation--medium': isSLAShowlable && sla.medium,
      'conversation--bad': isSLAShowlable && sla.bad
    }"
    @click="cardClick(chat)"
    v-if="shouldShowDiv"
  >
    <thumbnail
      v-if="!hideThumbnail"
      :src="currentContact.thumbnail"
      :badge="chatBadge"
      class="columns"
      :username="currentContact.name"
      :status="currentContact.availability_status"
      :criticality="getCriticality"
      :avatarTooltip="getTooltip"
      size="40px"
    />
    <div class="conversation--details columns">
      <div  class="conversation--metadata">
        <inbox-name v-if="showInboxName" :inbox="inbox" />
        <span
          v-if="showAssignee && assignee"
          class="label assignee-label text-truncate"
        >
          <i class="ion-person" />
          {{ assignee.name }}
        </span>
      </div>
      <h4 class="conversation--user">
        {{ currentContact.name }}
      </h4>
      <p v-if="lastMessageInChat" class="conversation--message">
        <i v-if="isMessagePrivate" class="ion-locked last-message-icon" />
        <i v-else-if="messageByAgent" class="ion-ios-undo last-message-icon" />
        <i
          v-else-if="isMessageAnActivity"
          class="ion-information-circled last-message-icon"
        />
        <span v-if="lastMessageInChat.content && isSLAShowlable">
          {{ parsedLastMessage }}
        </span>
        <span v-else-if="lastMessageInChat.attachments">
          <i :class="`small-icon ${this.$t(`${attachmentIconKey}.ICON`)}`"></i>
          {{ this.$t(`${attachmentIconKey}.CONTENT`) }}
        </span>
        <span v-else>
          {{ $t('CHAT_LIST.NO_CONTENT') }}
        </span>
      </p>
      <p v-else class="conversation--message">
        <i class="ion-android-alert"></i>
        <span>
          {{ this.$t(`CHAT_LIST.NO_MESSAGES`) }}
        </span>
      </p>
      <div class="conversation--meta">
        <span class="timestamp" v-if="chat.sla_timestamp && isSLAShowlable">
          [cliente] há {{ dynamicTime(chat.sla_timestamp) }}
        </span>
        <span class="timestamp" v-else-if="lastMessageInChat && lastMessageInChat.created_at && isSLAShowlable"> 
          [Pro] há {{ dynamicTime(lastMessageInChat.created_at) }}
        </span>
        <span class="unread">{{ unreadCount > 9 ? '9+' : unreadCount }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { MESSAGE_TYPE } from 'widget/helpers/constants';
import messageFormatterMixin from 'shared/mixins/messageFormatterMixin';
import Thumbnail from '../Thumbnail';
import conversationMixin from '../../../mixins/conversations';
import timeMixin from '../../../mixins/time';
import router from '../../../routes';
import { frontendURL, conversationUrl } from '../../../helper/URLHelper';
import InboxName from '../InboxName';
import inboxMixin from 'shared/mixins/inboxMixin';
import adminMixin from '../../../mixins/isAdmin';

const toMilliseconds = (x) => x * 1000

export default {
  components: {
    InboxName,
    Thumbnail,
  },

  mixins: [inboxMixin, timeMixin, conversationMixin, messageFormatterMixin, adminMixin],
  props: {
    activeLabel: {
      type: String,
      default: '',
    },
    chat: {
      type: Object,
      default: () => {},
    },
    hideInboxName: {
      type: Boolean,
      default: false,
    },
    hideThumbnail: {
      type: Boolean,
      default: false,
    },
    teamId: {
      type: [String, Number],
      default: 0,
    },
    showAssignee: {
      type: Boolean,
      default: false,
    },
    itsMyTab: {
      type: Boolean,
      default: false,
    }

  },
  data() {
    return {
      shouldShowDiv: true,
      delayTimer: null
    };
  },
  computed: {
    ...mapGetters({
      currentChat: 'getSelectedChat',
      inboxesList: 'inboxes/getInboxes',
      activeInbox: 'getSelectedInbox',
      currentUser: 'getCurrentUser',
      accountId: 'getCurrentAccountId',
      getTicketCriticalityByProject: 'inboxes/getTicketCriticality',
      flags: 'getAppFlags'
    }),

    sla() {
      const medium = this.chat.sla_timestamp && this.differenceInMinutes(this.chat.sla_timestamp) >= 5
      const bad = this.chat.sla_timestamp && this.differenceInMinutes(this.chat.sla_timestamp) >= 15

      return {
        good: !medium && !bad,
        medium: medium && !bad,
        bad
      }
    },
    flagTicketDelayEarly() {
      return this.flags.ticket_delay_early[this.inbox.project] || {}
    },
    ticketDelayEarlyEmail() {
      return this.flagTicketDelayEarly.emails || []
    },
    ticketDelayEarlyTime() {
      return this.flagTicketDelayEarly.delay || 0
    },
    isflagTicketDelayEarlyOn() {
      return this.ticketDelayEarlyEmail.includes(this.currentUser.email) || this.isAdmin
    },
    shallAddDelay() {
      return !this.isflagTicketDelayEarlyOn && this.isNewTicket && !this.chatMetadata.assignee
    },
    isNewTicket() {
      if (!this.chat.created_at) return true

      const createdAt = new Date(toMilliseconds(this.chat.created_at))
      const now = new Date()
      const timeDiffInSeconds = this.differenceInSecondsBetween(now.getTime(), createdAt.getTime())

      return timeDiffInSeconds <= this.ticketDelayEarlyTime
    },

    getCriticality() {    
      const medium = this.chat.created_at && this.projectCriticality && this.differenceInMinutes(this.chat.created_at) >= this.projectCriticality.level_1_timer
      const bad = this.chat.created_at && this.projectCriticality && this.differenceInMinutes(this.chat.created_at) >= this.projectCriticality.level_2_timer

      return {
        good: !medium && !bad,
        medium: medium && !bad,
        bad
      }
    },

    getTooltip() {
      const medium = this.chat.created_at && this.projectCriticality && this.differenceInMinutes(this.chat.created_at) >= this.projectCriticality.level_1_timer
      const bad = this.chat.created_at && this.projectCriticality && this.differenceInMinutes(this.chat.created_at) >= this.projectCriticality.level_2_timer
      
      if(medium && !bad)
        return this.projectCriticality.level_1_tooltip
      else if(medium && bad)
        return this.projectCriticality.level_2_tooltip
      else
        return null
    },

    isSLAShowlable() {
      return this.inbox.tickets_content_visible || this.isAdmin || this.itsMyTab;
    },

    chatExtraAttributes() {
      return this.chat.additional_attributes;
    },

    chatMetadata() {
      return this.chat.meta || {};
    },

    assignee() {
      return this.chatMetadata.assignee || {};
    },

    chatBadge() {
      if(this.chatExtraAttributes['type']){
        return this.chatExtraAttributes['type']
      } else {
        return this.chatMetadata.channel
      }
    },

    currentContact() {
      return this.$store.getters['contacts/getContact'](
        this.chatMetadata.sender.id
      );
    },

    attachmentIconKey() {
      const lastMessage = this.lastMessageInChat;
      const [{ file_type: fileType } = {}] = lastMessage.attachments;
      return `CHAT_LIST.ATTACHMENTS.${fileType}`;
    },

    isActiveChat() {
      return this.currentChat.id === this.chat.id;
    },

    unreadCount() {
      return this.unreadMessagesCount(this.chat);
    },

    hasUnread() {
      return this.unreadCount > 0;
    },

    isInboxNameVisible() {
      return !this.activeInbox;
    },

    lastMessageInChat() {
      return this.lastNonSystemMessage(this.chat);
    },

    messageByAgent() {
      const lastMessage = this.lastMessageInChat;
      const { message_type: messageType } = lastMessage;
      return messageType === MESSAGE_TYPE.OUTGOING;
    },

    isMessageAnActivity() {
      const lastMessage = this.lastMessageInChat;
      const { message_type: messageType } = lastMessage;
      return messageType === MESSAGE_TYPE.ACTIVITY;
    },

    isMessagePrivate() {
      const lastMessage = this.lastMessageInChat;
      const { private: isPrivate } = lastMessage;
      return isPrivate;
    },

    parsedLastMessage() {
      const { content_attributes: contentAttributes } = this.lastMessageInChat;
      const { email: { subject } = {} } = contentAttributes || {};
      return this.getPlainText(subject || this.lastMessageInChat.content);
    },

    inbox() {
      const { inbox_id: inboxId } = this.chat;
      const stateInbox = this.$store.getters['inboxes/getInbox'](inboxId);
      return stateInbox;
    },

    showInboxName() {
      return (
        !this.flags.hide_project_information &&
        !this.hideInboxName &&
        this.isInboxNameVisible &&
        this.inboxesList.length > 1
      );
    },
    inboxName() {
      const stateInbox = this.inbox;
      return stateInbox.name || '';
    },
    projectCriticality() {
      return this.getTicketCriticalityByProject(this.inbox.project) || {}
    },
    isConsultaDeCadastro () {
      const filteredMessages = this.chat.messages.filter(m => m?.message_type < 2)

      if(!filteredMessages.length) return false

      const botMenuMessageIndex = filteredMessages.findIndex(m => m?.content?.includes('7 - Consulta de cadastro'))
      const userMenuMessageResponse = filteredMessages[botMenuMessageIndex + 1]

      return userMenuMessageResponse?.content === '7'
    }
  },
  mounted() {
    this.handleVisibility()
  },
  destroyed() {
    clearTimeout(this.delayTimer)
  },
  methods: {
    cardClick(chat) {
      const { activeInbox } = this;
      const path = conversationUrl({
        accountId: this.accountId,
        activeInbox,
        id: chat.id,
        label: this.activeLabel,
        teamId: this.teamId,
      });
      router.push({ path: frontendURL(path) });
    },
    handleVisibility() {
      const isCarguero = this.chat.project === 'carguero'

      if(this.flags.test_carguero_tickets && isCarguero && !this.isConsultaDeCadastro) {
        this.shouldShowDiv = false

        return
      } 

      if (this.shallAddDelay) {
        this.shouldShowDiv = false
        this.delayTimer = setTimeout(() => {
          this.shouldShowDiv = true
        }, toMilliseconds(this.ticketDelayEarlyTime))
      } else {
        this.shouldShowDiv = true
      }
    },
  },
  watch: {
    isConsultaDeCadastro(current, old) {
      if(current !== old) {
        this.handleVisibility()
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.conversation {
  align-items: center;

  &:hover {
    background: var(--color-background-light);
  }

  &--bad {
    background: rgba(#f00, 0.1);

    .timestamp {
      color: #ff6a6a;
    }

    &:hover {
      background: rgba(#f00, 0.15);
    }
  }

  &--medium {
    background: rgba(#ffb000, 0.2);

    &:hover {
      background: rgba(#ffb000, 0.25);
    }
  }
}

.has-inbox-name {
  &::v-deep .user-thumbnail-box {
    margin-top: var(--space-normal);
    align-items: flex-start;
  }
  .conversation--meta {
    margin-top: var(--space-normal);
  }
}

.conversation--details {
  .conversation--user {
    padding-top: var(--space-micro);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 50%;
  }
  .ion-earth {
    font-size: var(--font-size-mini);
  }
}

.last-message-icon {
  color: var(--s-600);
  font-size: var(--font-size-mini);
}

.conversation--metadata {
  display: flex;
  justify-content: space-between;
  padding-right: var(--space-normal);

  .label {
    padding: var(--space-micro) 0 var(--space-micro) 0;
    line-height: var(--space-slab);
    font-weight: var(--font-weight-medium);
    background: none;
    color: var(--s-500);
    font-size: var(--font-size-mini);
  }

  .assignee-label {
    max-width: 50%;
  }
}
</style>
