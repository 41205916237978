import { helpers } from 'vuelidate/lib/validators';

const customValidations = {
  deniedValues(validation) {
    return helpers.withParams(
      { message: validation.message },
      value => {
        return validation.deniedValues.every(deniedValue => {
          if(typeof deniedValue === 'object' && deniedValue.regex) {
            return !(new RegExp(deniedValue.value).test(value))
          }

          return deniedValue !== value
        })
      }
    )
  }
}

export const mountCustomValidations = (validations) => {
  const validationsKeys = Object.keys(customValidations)

  return validations.reduce((ac, c) => {
    const validationKey = validationsKeys.find(vk => c.hasOwnProperty(vk))
    const validatorFn = customValidations[validationKey]

    return validatorFn ? {
      ...ac,
      [validationKey]: validatorFn(c)
    } : ac
  }, {})
}