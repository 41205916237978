<template>
  <woot-button
    v-if="isConversationOpen || isAdmin"
    variant="link"
    class="label--add"
    @click="addLabel"
  >
    <woot-label
      color-scheme="secondary"
      :title="$t('CONTACT_PANEL.LABELS.CONVERSATION.ADD_BUTTON')"
      icon="ion-plus-round"
    />
  </woot-button>
</template>

<script>
import { mapGetters } from 'vuex';
import adminMixin from '../../../../dashboard/mixins/isAdmin';
import wootConstants from '../../../../dashboard/constants';

export default {
  mixins: [adminMixin],
  computed: {
    ...mapGetters({
      currentChat: 'getSelectedChat',
      currentUser: 'getCurrentUser',
    }),
    isConversationOpen() {
      return this.currentChat.status === wootConstants.STATUS_TYPE.OPEN;
    },
  },
  methods: {
    addLabel() {
      this.$emit('add');
    },
  },
};
</script>

<style lang="scss" scoped>
.label--add {
  &::v-deep .label {
    cursor: pointer;
    background: transparent;
    border-color: var(--s-600);
    margin: 0;

    &:hover {
      background: var(--s-50);
    }
  }
}
</style>
