import { actions } from "./actions"
import { getters } from "./getters"
import { mutations } from "./mutations"

const state = {
    initialized: false,
    connection: null,
    flags: {
        automated_actions_in_trainees: false,
        clean_up_interface: false,
        change_availability_status: false,
        notify_available_conversations_message: { projects: [] },
        bot_response_suggestion: { projects:[] },
        tutorial_meet_url: { projects: {} },
        tutorial_ticket: { projects: [] },
        audit_macro: false,
        feedback_category: { projects: [] },
        sso_login: false,
        internet_connection_error: false,
        stonly_widget: false,
        hide_project_information: false,
        ticket_delay_early: {},
        test_carguero_tickets: false,
        stonly_knowledge_base: false,
        change_inbox: { projects:[] },
        backlog_alert_cloudchat: false,
    }
}

export default {
    actions,
    getters,
    mutations,
    state,
}
