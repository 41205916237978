<template>
  <modal :close-on-backdrop-click="false" :show.sync="showModal" :on-close="() => $emit('close-audit-comments-not-read-modal')">
    <woot-modal-header
      header-title="Aviso Importante!"
      header-content="Você possui devolutivas não lidas em tickets que foram respondidos por você! Como você sabe, aqui na cloud humans, um de nossos compromissos é entregar o melhor atendimento possível, então leia as devolutivas dos tickets listados abaixo atentamente antes de prosseguir o atendimento de tickets. Caso você tenha atingido o limite de tickets e esteja com acesso restrito a novos tickets, você deve ler às devolutivas para que seu acesso a novos tickets seja reestabelecido." />

    <div class="modal-content">
      <p class= "links" v-for="c in conversationsNotRead">
        <span class="link-content">
          Conversa - {{new Date(c.created_at).toLocaleString()}}
          </span>
        <button class="button small button-audit"
        @click="nextAudit(c)"
        >
          Ler devolutiva
        </button>
      </p>
    </div>
  </modal>
</template>

<script>
import Modal from '../../Modal';
import { frontendURL, conversationUrl } from '../../../helper/URLHelper';

export default {
  components: {
    Modal,
  },
  props: {
    show: Boolean,
    onClose: Function,
    conversationsNotRead: Array,
  },
  computed: {
    showModal() {
      return this.show;
    },
  },
  methods: {
    handleClose() {
      this.$emit('close-audit-comments-not-read-modal');
    },
    async nextAudit(conversation) {
      await this.$store.dispatch('clearSelectedState');
      const path = conversationUrl({
        accountId: conversation.account_id,
        id: conversation.display_id
      });
      this.$router.push({ path: frontendURL(path).concat('?audit=true') })
      .catch(error => {
        if (error.name != "NavigationDuplicated") {
          throw error;
        } else {
          window.location.reload()
        }
      });
      this.$emit('close-audit-comments-not-read-modal');
    }
  }
};
</script>

<style lang="scss" scoped>
.button-audit {
  float: right;
  position: absolute;
  right: 100px;

  /* flex para alinhar conteúdo*/
  background-color: #ff843c;
  &:hover{
    background-color: #ff843c;
    opacity: 0.8;
    }
  }

.links{
  display: flex;
  align-items: center;
  padding: 15px 150px 15px 0;
}

.link-content {
  padding-right: 100px;
  position: relative;
}
</style>
