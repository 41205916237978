<template>
  <div class="alert-messages" v-if="shouldShowAlertOpenConversations">
    <i v-tooltip="tooltipAlertBacklog()" class="ion-android-mail" />
    <span
      class="alert-circle"
      :class="{
        'alert-circle--good': urgencyAlertClass.good,
        'alert-circle--medium': urgencyAlertClass.medium,
        'alert-circle--bad': urgencyAlertClass.bad
      }"
    />
  </div>
</template>

<script>
export default {

  props: {
    project: String,
    openConversations: Array
  },
  data() {
    return {
      timer: null,
      filteredConversations: [],
      urgencyAlertClass: {}
    }
  },
  computed: {
    shouldShowAlertOpenConversations() {
      return !!this.filteredConversations.length
    }
  },
  watch: {
    openConversations(newConversation) {
      this.filteredConversations = newConversation.filter(d => d.project === this.project && !d.assignee_id);
      this.urgencyAlert();
    },

  },
  methods: {
    tooltipAlertBacklog() {
      return "Novas conversas!"
    },
    startTimer() {
      this.timer = setInterval(() => {
        this.urgencyAlert()
      }, 15000);
    },
    stopTimer() {
      clearInterval(this.timer);
      this.timer = null;
    },
    urgencyAlertMinutes() {
      const conversations = this.openConversations.filter(d => d.project === this.project && !d.assignee_id);
      const currentTime = Math.floor(Date.now() / 1000);

      const conversationWithMaxTimestamp = conversations.reduce((currentConversation, conversation) => {
        const timestamp = conversation.assignee_last_seen_at || conversation.created_at;
        const timeSinceLastSeen = currentTime - timestamp;

        if (timeSinceLastSeen > currentConversation.timeSinceLastSeen) {
          return {
            conversation: conversation,
            timeSinceLastSeen: timeSinceLastSeen
          };
        } else {
          return currentConversation;
        }
      }, { conversation: null, timeSinceLastSeen: 0 });

      if (conversationWithMaxTimestamp) {
        var minutes = conversationWithMaxTimestamp.timeSinceLastSeen / 60
        return minutes
      }
    },
    urgencyAlert() {
      const minutes = this.urgencyAlertMinutes()

      this.urgencyAlertClass = {
          good: minutes <= 5,
          medium: minutes > 5 && minutes <= 10,
          bad: minutes > 10
        }
    }
  },
  created() {
    this.startTimer();
  },
  destroyed() {
    this.stopTimer();
  },

}
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';


.alert-messages {
    position: relative;
    display: inline-block;
    padding-left: 5px;
}

.alert-messages .ion-android-mail {
    font-size: 20px;
}

.alert-messages .alert-circle {
    position: absolute;
    top: 2px;
    right: -5px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 12px;
    font-weight: bold;

    &--good {
      background-color: rgb(58, 231, 58);
    }
    &--medium {
      background-color: rgb(238, 158, 52);
    }
    &--bad {
      background-color: rgb(245, 86, 86);
    }
}

</style>
