export const getItem = (key, defaultValue) => {
  try {
    return JSON.parse(localStorage.getItem(key) || defaultValue)
  } catch(e) {
    return localStorage.getItem(key) || defaultValue
  }
}

export const setItem = (key, value) => {
  try {
    localStorage.setItem(key, JSON.stringify(value))
  } catch(e) {
    localStorage.setItem(key, value)
  }
}