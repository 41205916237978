<template>
  <transition-group name="toast-fade" tag="div" class="ui-snackbar-container">
    <woot-snackbar
      v-for="project in projects"
      v-if="flagContainsProject(project) && conversationsAvailable[project]"
      :message="getMessage(project)"
      :key="project"
      type="info"
    />
  </transition-group>
</template>

<script>
import WootSnackbar from './Snackbar';
import { mapGetters } from 'vuex';

export default {
  components: {
    WootSnackbar,
  },
  computed: {
    ...mapGetters({
      conversationsAvailable: 'getAvailableConversationsCount',
      flags: 'getAppFlags',
    }),
    projects(){
     return Object.keys(this.conversationsAvailable)
    }
  },
  methods: {
    getMessage(project){
      const count = this.conversationsAvailable[project]
      const message = count === 1 ? 'nova conversa' : 'novas conversas'
      return `${count} ${message} no projeto ${project}`
    },
    flagContainsProject(project){
      return this.flags.notify_available_conversations_message.projects.includes(project)
    }
  },
};
</script>
