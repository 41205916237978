/* eslint no-console: 0 */
/* eslint no-param-reassign: 0 */
import Vue from 'vue';
import * as types from '../../mutation-types';
import getters, { getSelectedChatConversation } from './getters';
import actions from './actions';
import { findPendingMessageIndex } from './helpers';
import wootConstants from '../../../constants';

const state = {
  allConversations: [],
  listLoadingStatus: true,
  chatStatusFilter: wootConstants.STATUS_TYPE.OPEN,
  currentInbox: null,
  selectedChatId: null,
  unreadAudits: [],
  availableConversationsCount: {},
  allOpenConversations: []
};

// mutations
export const mutations = {
  [types.default.SET_ALL_CONVERSATION](_state, conversationList) {
    const newAllConversations = [..._state.allConversations];
    conversationList
      .filter(c => !_state.currentInbox || c.project === _state.currentInbox)
      .forEach(conversation => {
        const indexInCurrentList = newAllConversations.findIndex(
          c => c.id === conversation.id
        );
        if (indexInCurrentList < 0) {
          newAllConversations.push(conversation);
        }
      });
    _state.allConversations = newAllConversations;
  },
  [types.default.SET_ALL_OPEN_CONVERSATION](_state, conversationList) {
    _state.allOpenConversations = conversationList;
  },
  [types.default.EMPTY_ALL_CONVERSATION](_state) {
    _state.allConversations = [];
    _state.selectedChatId = null;
  },
  [types.default.SET_ALL_MESSAGES_LOADED](_state) {
    const [chat] = getSelectedChatConversation(_state);
    Vue.set(chat, 'allMessagesLoaded', true);
  },

  [types.default.CLEAR_ALL_MESSAGES_LOADED](_state) {
    const [chat] = getSelectedChatConversation(_state);
    Vue.set(chat, 'allMessagesLoaded', false);
  },
  [types.default.CLEAR_CURRENT_CHAT_WINDOW](_state) {
    _state.selectedChatId = null;
  },

  [types.default.SET_PREVIOUS_CONVERSATIONS](_state, { id, data }) {
    if (data.length) {
      const [chat] = _state.allConversations.filter(c => c.id === id);
      chat.messages.unshift(...data);
    }
  },

  [types.default.SET_CURRENT_CHAT_WINDOW](_state, activeChat) {
    if (activeChat) {
      _state.selectedChatId = activeChat.id;
    }
  },

  [types.default.ASSIGN_AGENT](_state, assignee) {
    const [chat] = getSelectedChatConversation(_state);
    Vue.set(chat.meta, 'assignee', assignee);
  },

  [types.default.ASSIGN_TEAM](_state, team) {
    const [chat] = getSelectedChatConversation(_state);
    Vue.set(chat.meta, 'team', team);
  },

  [types.default.CHANGE_CONVERSATION_N2](
    _state,
    { conversationId }
  ) {
    const conversation =
      getters.getConversationById(_state)(conversationId) || {};
    Vue.set(conversation, 'is_n2', true);
  },

  [types.default.CHANGE_CONVERSATION_STATUS](
    _state,
    { conversationId, status, snoozedUntil }
  ) {
    const conversation =
      getters.getConversationById(_state)(conversationId) || {};
    Vue.set(conversation, 'snoozed_until', snoozedUntil);
    Vue.set(conversation, 'status', status);
  },

  [types.default.MUTE_CONVERSATION](_state) {
    const [chat] = getSelectedChatConversation(_state);
    chat.muted = true;
  },

  [types.default.UNMUTE_CONVERSATION](_state) {
    const [chat] = getSelectedChatConversation(_state);
    chat.muted = false;
  },

  [types.default.ADD_MESSAGE]({ allConversations, selectedChatId }, message) {
    const { conversation_id: conversationId } = message;
    const [chat] = getSelectedChatConversation({
      allConversations,
      selectedChatId: conversationId,
    });
    if (!chat) return;

    const pendingMessageIndex = findPendingMessageIndex(chat, message);
    if (pendingMessageIndex !== -1) {
      Vue.set(chat.messages, pendingMessageIndex, message);
    } else {
      chat.messages.push(message);
      if (message.message_type === 0 && !chat.sla_timestamp) {
        chat.sla_timestamp = message.created_at;
      } else if (message.message_type === 1 && !message.private) {
        chat.sla_timestamp = null;
      }
      chat.timestamp = message.created_at;
      if (selectedChatId === conversationId) {
        window.bus.$emit('scrollToMessage');
      }
    }
  },

  [types.default.ADD_CONVERSATION](_state, conversation) {
    _state.allConversations.push(conversation);
  },

  [types.default.UPDATE_CONVERSATION](_state, conversation) {
    const { allConversations } = _state;
    const currentConversationIndex = allConversations.findIndex(
      c => c.id === conversation.id
    );
    if (currentConversationIndex > -1) {
      const { messages, ...conversationAttributes } = conversation;
      const currentConversation = {
        ...allConversations[currentConversationIndex],
        ...conversationAttributes,
      };
      Vue.set(allConversations, currentConversationIndex, currentConversation);
      if (_state.selectedChatId === conversation.id) {
        window.bus.$emit('scrollToMessage');
      }
    } else {
      _state.allConversations.push(conversation);
    }
  },

  [types.default.UPDATE_OPEN_CONVERSATION](_state, conversation) {
    const index = _state.allOpenConversations.findIndex(c => c.id === conversation.id);

    if (index === -1) {
      _state.allOpenConversations.push(conversation);
    } else {
      _state.allOpenConversations.splice(index, 1, conversation);
    }
  },

  [types.default.SET_LIST_LOADING_STATUS](_state) {
    _state.listLoadingStatus = true;
  },

  [types.default.CLEAR_LIST_LOADING_STATUS](_state) {
    _state.listLoadingStatus = false;
  },

  [types.default.MARK_MESSAGE_READ](_state, { id, lastSeen }) {
    const [chat] = _state.allConversations.filter(c => c.id === id);
    if (chat) {
      chat.agent_last_seen_at = lastSeen;
    }
  },

  [types.default.CHANGE_CHAT_STATUS_FILTER](_state, data) {
    _state.chatStatusFilter = data;
  },

  // Update assignee on action cable message
  [types.default.UPDATE_ASSIGNEE](_state, payload) {
    const [chat] = _state.allConversations.filter(c => c.id === payload.id);
    Vue.set(chat.meta, 'assignee', payload.assignee);
  },

  [types.default.UPDATE_CONVERSATION_CONTACT](
    _state,
    { conversationId, ...payload }
  ) {
    const [chat] = _state.allConversations.filter(c => c.id === conversationId);
    if (chat) {
      Vue.set(chat.meta, 'sender', payload);
    }
  },

  [types.default.SET_ACTIVE_INBOX](_state, project) {
    _state.currentInbox = project ? project : null;
  },

  [types.default.SET_CONVERSATION_CAN_REPLY](
    _state,
    { conversationId, canReply }
  ) {
    const [chat] = _state.allConversations.filter(c => c.id === conversationId);
    if (chat) {
      Vue.set(chat, 'can_reply', canReply);
    }
  },

  [types.default.CLEAR_CONTACT_CONVERSATIONS](_state, contactId) {
    const chats = _state.allConversations.filter(
      c => c.meta.sender.id !== contactId
    );
    Vue.set(_state, 'allConversations', chats);
  },

  [types.default.INCREMENT_AVAILABLE_CONVERSATIONS_COUNT](_state, project) {
    const currentValue = _state.availableConversationsCount[project] || 0
    Vue.set(_state.availableConversationsCount, project, currentValue + 1)
  },

  [types.default.CLEAR_AVAILABLE_CONVERSATIONS_COUNT](_state, project) {
    Vue.set(_state.availableConversationsCount, project, 0)
  },

  refreshUnreadAudits(_state, unreadAudits) {
    _state.unreadAudits = unreadAudits;
  },
  setUnreadAuditsEmpty(_state) {
    _state.unreadAudits = [];
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
