export const MESSAGE_STATUS = {
  FAILED: 'failed',
  SENT: 'sent',
  PROGRESS: 'progress',
};

export const MESSAGE_TYPE = {
  INCOMING: 0,
  OUTGOING: 1,
  ACTIVITY: 2,
  TEMPLATE: 3,
  AUTOMATED: 4,
  ADD_LABEL: 5,
  PRESENCE_VERIFICATION: 6,
  PRESENCE_VERIFICATION_COMPLETED: 7
};

export const MESSAGE_TYPE_LABEL = {
  0: 'incoming',
  1: 'outgoing',
  2: 'activity',
  3: 'template',
  4: 'automated',
  5: 'add_label',
  6: 'presence_verification',
  7: 'presence_verification_completed'
}

// Size in mega bytes
export const MAXIMUM_FILE_UPLOAD_SIZE = 40;

export const CSAT_RATINGS = [
  {
    key: 'disappointed',
    emoji: '😞',
    value: 1,
    color: '#FDAD2A',
  },
  {
    key: 'expressionless',
    emoji: '😑',
    value: 2,
    color: '#FFC532',
  },
  {
    key: 'neutral',
    emoji: '😐',
    value: 3,
    color: '#FCEC56',
  },
  {
    key: 'grinning',
    emoji: '😀',
    value: 4,
    color: '#6FD86F',
  },
  {
    key: 'smiling',
    emoji: '😍',
    value: 5,
    color: '#44CE4B',
  },
];
