/* global axios */

import ApiClient from './ApiClient';

class CannedResponse extends ApiClient {
  constructor() {
    super('canned_responses', { accountScoped: true });
  }

  get({ searchKey, inbox }) {
    const params = new URLSearchParams()
    
    searchKey && params.append('search', searchKey)
    inbox && params.append('inbox', inbox)

    const url = searchKey ? `${this.url}?${params.toString()}` : this.url;
    return axios.get(url);
  }
}

export default new CannedResponse();
