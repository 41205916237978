import { 
    ADD_STEP, 
    ADD_STEPS, 
    ADD_WALKTHROUGH, 
    SET_WALKTHROUGH,
    SET_STATE,
    SET_STEP,
    SET_STEPS
 } from './type'


export const mutations = {

    [ADD_WALKTHROUGH]($state, {name, walkthrough}){ 
        $state.walkthroughs.set(name, walkthrough)
    },

    [ADD_STEP]($state,{name, step}){
        $state.walkthroughs.get(name).setps_queue.push(step)
    },

    [SET_STEP]($state, {name, step}){
        const index = $state.walkthroughs.get(name).setps_queue.findIndex(item => item.title === step.title)
        $state.walkthroughs.get(name).setps_queue[index] = step
    },

    [SET_STEPS]($state, {name, steps}){
        $state.walkthroughs.get(name).setps_queue = steps
    },

    [ADD_STEPS]($state, {name, steps}){
        $state.walkthroughs.get(name).setps_queue.concat(steps)
    },

    [SET_WALKTHROUGH]($state, {name, walkthrough}){
        $state.walkthroughs.set(name, walkthrough)
    },

}