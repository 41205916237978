<template>
  <form
    class="login-box medium-4 column align-self-middle"
    @submit.prevent="submit()"
  >
    <h4>{{ $t('RESET_PASSWORD.TITLE') }}</h4>
    <div class="column log-in-form">
      <label :class="{ error: $v.credentials.email.$error }">
        {{ $t('RESET_PASSWORD.EMAIL.LABEL') }}
        <input
          :data-step="`${TUTORIAL_FIRST_LOGIN}-email-reset`"
          v-model.trim="credentials.email"
          type="text"
          :placeholder="$t('RESET_PASSWORD.EMAIL.PLACEHOLDER')"
          @input="$v.credentials.email.$touch"
        />
        <span v-if="$v.credentials.email.$error" class="message">
          {{ $t('RESET_PASSWORD.EMAIL.ERROR') }}
        </span>
      </label>

      <div class="reset-password__actions">
        <woot-submit-button
          :data-step="`${TUTORIAL_FIRST_LOGIN}-button-reset`"
          :disabled="$v.credentials.email.$invalid || resetPassword.showLoading"
          :button-text="$t('RESET_PASSWORD.SUBMIT')"
          :loading="resetPassword.showLoading"
          button-class="expanded"
        />

        <woot-button
          type="button"
          variant="hollow"
          size="expanded"
          @click="initTutorial()"
        >
          Clique aqui para o primeiro acesso
        </woot-button>
      </div>
    </div>
  </form>
</template>

<script>
import { required, minLength, email } from 'vuelidate/lib/validators';
import Auth from '../../api/auth';
import { mapGetters } from 'vuex';
import { FIRST_LOGIN_TUTORIAL, RESET_PASS_TUTORIAL, Step, Walkthrough } from '../../store/modules/walktrough/walkthroughs';

export default {
  data() {
    return {
      // We need to initialize the component with any
      // properties that will be used in it
      credentials: {
        email: '',
      },
      resetPassword: {
        message: '',
        showLoading: false,
      },
      error: '',
      TUTORIAL_FIRST_LOGIN: FIRST_LOGIN_TUTORIAL,
    };
  },
  validations: {
    credentials: {
      email: {
        required,
        email,
        minLength: minLength(4),
      },
    },
  },

  computed: {
    ...mapGetters({
      getSteps: 'getSteps',
    })
  },

  methods: {
    setupFirstLoginTutorial(){
      this.$store.dispatch('addWalktrhough', {
        name: RESET_PASS_TUTORIAL,
        steps: [
          Step(
            {
              step_id:"Olá! :)", 
              step_desc: "vamos fazer uma senha pra você?",
            }
          ),
          Step(
            {
              step_id:"Digite aqui o seu email", 
              step_desc: "É nele que será enviado um pedido para você criar uma nova senha",
              target: `${this.TUTORIAL_FIRST_LOGIN}-email-reset`
            }
          ),
          Step(
            {
              step_id:"Clique nesse botão!", 
              step_desc: "Após isso, em alguns minutos será enviado um email para você! Lembre-se de procurar bem, se não achar, olhe na caixa de spam e na lixeira ;)",
              target: `${this.TUTORIAL_FIRST_LOGIN}-button-reset`
            }
          ),
        ]
      })
    },

    showAlert(message) {
      // Reset loading, current selected agent
      this.resetPassword.showLoading = false;
      bus.$emit('newToastMessage', message);
    },
    submit() {
      this.resetPassword.showLoading = true;
      Auth.resetPassword(this.credentials)
        .then(res => {
          let successMessage = this.$t('RESET_PASSWORD.API.SUCCESS_MESSAGE');
          if (res.data && res.data.message) {
            successMessage = res.data.message;
          }
          this.showAlert(successMessage);
        })
        .catch(error => {
          let errorMessage = this.$t('RESET_PASSWORD.API.ERROR_MESSAGE');
          if (error?.response?.data?.message) {
            errorMessage = error.response.data.message;
          }
          this.showAlert(errorMessage);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
  .reset-password__actions {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
</style>
