<template>
  <li class="presence-confirmation">
    <div class="presence-confirmation__container">
      <div class="presence-confirmation__wrapper">
        <div class="presence-confirmation__title">Confirmação de Presença</div>
        <div class="presence-confirmation__subtitle">PRO você ainda está ai?</div>

        <div class="presence-confirmation__hint">
          <b>Atenção: </b>Mesmo que você esteja esperando alguma resposta ou efetuando algum processo, confirme sua presença aqui para não perder o ticket.
        </div>

        <button class="presence-confirmation__button" @click="handleClick()">
          Sim
        </button>
      </div>
    </div>
  </li>
</template>
<script>
import { mapGetters } from 'vuex';
import { MESSAGE_TYPE } from '../../../../shared/constants/messages';

export default {
  computed: {
    ...mapGetters({
      currentChat: 'getSelectedChat'
    }),
  },
  mounted() {
  },
  methods: {
    async handleClick(){
      await this.$store.dispatch('sendMessage', {
        conversationId: this.currentChat.id,
        message: null,
        private: false,
        message_type: MESSAGE_TYPE.PRESENCE_VERIFICATION_COMPLETED
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  .presence-confirmation {
    margin: 1.4rem 1.6rem;
    display: flex;
    justify-content: flex-end;

    &__container {
      background: #bae1ab;
      border: 1px solid #9cc58c;
      border-radius: 10px 4px 4px 10px;
      padding: 1.4rem 1.4rem 2rem 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      transform: scale(0);
      animation: initContainer .5s cubic-bezier(0.49, 1.7, 0.47, 0.64) forwards;

      @keyframes initContainer {
        from { transform: scale(0) }
        to { transform: scale(1) }
      }
    }

    &__wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      opacity: 0;
      animation: initWrapper 1s .5s ease-out forwards;

      @keyframes outWrapper {
        from { opacity: 1 }
        to {  opacity: 0}
      }

      @keyframes initWrapper {
        from { opacity: 0 }
        to { opacity: 1 }
      }
    }

    &__title {
      font-weight: bold;
      color: #2e4e22;
      font-size: 21px;
      margin-bottom: 1rem;
    }

    &__subtitle,
    &__hint {
      color: #2e4e22;
      font-size: 15px;
      font-weight: 400;
    }

    &__hint {
      font-style: italic;
      text-align: right;
      font-size: 11px;
      margin-top: 3px;
      color: #618952;
    }

    &__button {
      border: none;
      background: #fff;
      box-shadow: 0px 4px 10px -5px rgba(0, 0, 0, 0.25);
      margin-top: 1.4rem;
      font-size: 12px;
      font-weight: 500;
      border-radius: 4px;
      padding: 1.4rem;
      cursor: pointer;
      text-align: left;
      line-height: 16px;

      &:hover {
        background: #eee;
      }
    }
  }
</style>
