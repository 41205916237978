<template>
  <blocked-container>
    <p>Você deve rever o conteúdo da base de conhecimento e responder novamente às perguntas antes de voltar a responder tickets do projeto {{inboxName}}.</p>
    <a class="button" href="https://pro.cloudhumans.com" target="_blank">Acessar o Portal</a>
    <a v-if="itsMyTicket" class="button" @click="$emit('on-set-read-only');">Visualizar Conversa</a>
  </blocked-container>
</template>

<script>
import BlockedContainer from './BlockedContainer'

export default {
  components: {
    BlockedContainer
  },
  props: {
    inboxName: {
      type: String,
      default: ''
    },
    itsMyTicket: {
      type: Boolean,
      default: false,
    }
  },
}
</script>
