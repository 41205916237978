<template>
  <div class="row app-wrapper">
    <div class="internet-connection-info" v-if="mustShowInternetConnectionError">
      <h1 class="internet-connection-info__title">Você perdeu a conexão</h1>
      <span class="internet-connection-info__subtitle">Atualize a página para se conectar novamente</span>
    </div>

    <sidebar :route="currentRoute" :class="sidebarClassName" v-show="hideMenus" />
    <section class="app-content columns" :class="contentClassName">
      <router-view></router-view>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Sidebar from '../../components/layout/Sidebar';
import { INTERNET_CONNECTION } from 'shared/constants/internetConnection'

export default {
  components: {
    Sidebar,
  },
  data() {
    return {
      isSidebarOpen: false,
      isOnDesktop: true,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      internetConnectionStatus: 'getInternetConnectionStatus',
      flags: 'getAppFlags'
    }),
    currentRoute() {
      return ' ';
    },
    mustShowInternetConnectionError() {
      return this.flags.internet_connection_error && this.internetConnectionStatus === INTERNET_CONNECTION.OFFLINE
    },
    sidebarClassName() {
      if (this.isOnDesktop) {
        return '';
      }
      if (this.isSidebarOpen) {
        return 'off-canvas is-open ';
      }
      return 'off-canvas position-left is-transition-push is-closed';
    },
    contentClassName() {
      if (this.isOnDesktop) {
        return '';
      }
      if (this.isSidebarOpen) {
        return 'off-canvas-content is-open-left has-transition-push has-position-left';
      }
      return 'off-canvas-content';
    },
    hideMenus() {
      return !window.chatwootConfig.hideMenus
    }
  },
  mounted() {
    this.$store.dispatch('setCurrentAccountId', this.$route.params.accountId);
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    bus.$on('sidemenu_icon_click', () => {
      this.isSidebarOpen = !this.isSidebarOpen;
    });

    this.$watch('currentUser', () => {
      if(this.currentUser && this.currentUser.id) {
        heap.identify(this.currentUser.id);
        heap.addUserProperties({ email: this.currentUser.email });

        analytics.identify(this.currentUser.email, {...this.currentUser});

        this.$store.dispatch('syncFlags',(this.currentUser))
      }
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      if (window.innerWidth > 1200) {
        this.isOnDesktop = true;
      } else {
        this.isOnDesktop = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .internet-connection-info {
    position: fixed;
    background: rgb(169 64 64 / 93%);
    height: 100vh;
    width: 100vw;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #fff;

    &__title {
      font-size: 42px;
      font-weight: bold;
      color: #fff;
    }

    &__subtitle {
      font-size: 20px;
      font-weight: 300;
    }

    & ~ * {
      filter: blur(2px);
    }
  }
</style>