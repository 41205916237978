<template>
  <div class="medium-12 column login">
    <div class="text-center medium-12 login__hero align-self-top">
      <img
        :src="globalConfig.logo"
        :alt="globalConfig.installationName"
        class="hero__logo"
      />
      <h2 v-if="flagsInitialized && !isSsoEnabled" class="hero__title">
        {{useInstallationName($t('LOGIN.TITLE'), globalConfig.installationName)}}
      </h2>
      <h2 v-if="flagsInitialized && isSsoEnabled" class="hero__title">
        {{!token ? 'Redirecionando para autenticação' : 'Efetuando autenticação'}}
      </h2>
    </div>
    <div v-if="flagsInitialized && !isSsoEnabled" class="row align-center">
      <div v-if="!email" class="small-12 medium-4 column">
        <form class="login-box column align-self-top" @submit.prevent="login()">
          <div class="column log-in-form">
            <label :class="{ error: $v.credentials.email.$error }">
              {{ $t('LOGIN.EMAIL.LABEL') }}
              <input
                v-model.trim="credentials.email"
                type="text"
                data-testid="email_input"
                :placeholder="$t('LOGIN.EMAIL.PLACEHOLDER')"
                @input="$v.credentials.email.$touch"
              />
            </label>
            <label :class="{ error: $v.credentials.password.$error }">
              {{ $t('LOGIN.PASSWORD.LABEL') }}
              <input
                v-model.trim="credentials.password"
                type="password"
                data-testid="password_input"
                :placeholder="$t('LOGIN.PASSWORD.PLACEHOLDER')"
                @input="$v.credentials.password.$touch"
              />
            </label>
            <div class="login__actions">
              <woot-submit-button
                :disabled="
                  $v.credentials.email.$invalid ||
                    $v.credentials.password.$invalid ||
                    loginApi.showLoading
                "
                :button-text="$t('LOGIN.SUBMIT')"
                :loading="loginApi.showLoading"
                button-class="large expanded"
              >
              </woot-submit-button>

              <woot-button
                type="button"
                variant="hollow"
                size="expanded"
                @click="initTutorial()"
              >
                Clique aqui para o primeiro acesso
              </woot-button>
            </div>
          </div>
        </form>
        <div class="column text-center sigin__footer">
          <p :data-step="`${TUTORIAL_FIRST_LOGIN}-reset-pass`" >
            <router-link to="auth/reset/password">
              {{ $t('LOGIN.FORGOT_PASSWORD') }}
            </router-link>
          </p>
          <p v-if="showSignupLink()">
            <router-link to="auth/signup">
              {{ $t('LOGIN.CREATE_NEW_ACCOUNT') }}
            </router-link>
          </p>
        </div>
      </div>
      <woot-spinner v-else size="" />
    </div>
  </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators';
import globalConfigMixin from 'shared/mixins/globalConfigMixin';
import WootSubmitButton from '../../components/buttons/FormSubmitButton';
import {FIRST_LOGIN_TUTORIAL, Walkthrough, Step} from '../../store/modules/walktrough/walkthroughs'
import { mapGetters } from 'vuex';

export default {
  components: {
    WootSubmitButton,
  },
  mixins: [globalConfigMixin],
  props: {
    token: { type: String, default: '' },
    ssoAuthToken: { type: String, default: '' },
    redirectUrl: { type: String, default: '' },
    config: { type: String, default: '' },
    email: { type: String, default: '' },
  },
  data() {
    return {
      // We need to initialize the component with any
      // properties that will be used in it
      credentials: {
        email: '',
        password: '',
      },
      loginApi: {
        message: '',
        showLoading: false,
      },
      TUTORIAL_FIRST_LOGIN: FIRST_LOGIN_TUTORIAL,
      error: '',
    };
  },
  validations: {
    credentials: {
      password: {
        required,
      },
      email: {
        required,
        email,
      },
    },
  },
  computed: {
    ...mapGetters({
      globalConfig: 'globalConfig/get',
      getSteps: 'getSteps',
      flags: 'getAppFlags',
      flagsInitialized: 'gatAppFlagsInitialized'
    }),
    authUrl() {
      return window.chatwootConfig.authUrl
    },
    isSsoEnabled() {
      return this.flags.sso_login && this.authUrl
    }
  },
  mounted() {
    this.$store.dispatch('syncFlags', null)
  },
  created() {
    if (this.ssoAuthToken || this.token) {
      this.login();
    }
  },
  watch: {
    flags() {
      if(this.isSsoEnabled && !this.token) {
        window.location.replace(`${this.authUrl}?redirectTo=${window.location.href}`)
      }
    }
  },
  methods: {
    initTutorial(){
      this.$store.dispatch('addWalktrhough', {
        name: this.TUTORIAL_FIRST_LOGIN,
        steps: [
          {
            title:"Olá! Chegou a hora de criarmos sua conta no Cloud Chat :D", 
            intro: "A conta do Cloud Chat é diferente do Portal do Pro! Siga os passos seguintes:",
          },
          Step(
            {
              step_id:"Nova senha", 
              step_desc: "Precisamos criar uma senha pra você! Pode ser a mesma do Portal se preferir, aperte neste link para isso ;)",
              target: `${this.TUTORIAL_FIRST_LOGIN}-reset-pass`
            },
          )
        ]
      })

      Walkthrough(this.TUTORIAL_FIRST_LOGIN).start()
    },

    showAlert(message) {
      // Reset loading, current selected agent
      this.loginApi.showLoading = false;
      this.loginApi.message = message;
      bus.$emit('newToastMessage', this.loginApi.message);
    },
    showSignupLink() {
      return window.chatwootConfig.signupEnabled === 'true';
    },
    login() {
      this.loginApi.showLoading = true;
      const credentials = {
        email: this.email ? this.email : this.credentials.email,
        password: this.credentials.password,
        sso_auth_token: this.ssoAuthToken,
        token: this.token
      };
      this.$store
        .dispatch('login', credentials)
        .then(() => {
          this.showAlert(this.$t('LOGIN.API.SUCCESS_MESSAGE'));
        })
        .catch(response => {
          // Reset URL Params if the authentication is invalid
          if (this.email) {
            window.location = '/app/login';
          }

          if (response && response.status === 401) {
						const { errors } = response.data;
						const hasAuthErrorMsg = errors && errors.length && errors[0] && typeof errors[0] === 'string';
            if (hasAuthErrorMsg) {
              this.showAlert(errors[0]);
            } else {
							this.showAlert(this.$t('LOGIN.API.UNAUTH'));
						}
            return;
          }
          this.showAlert(this.$t('LOGIN.API.ERROR_MESSAGE'));
        });
    },
  },
};
</script>

<style lang="scss" scoped>
  .login__actions {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
</style>
