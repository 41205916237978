import { SET_FLAGS, SET_CONNECTION, SET_LD_INITIALIZED } from "./type"

export const mutations = {
    [SET_FLAGS]($state, flags){
        $state.flags = {
            ...$state.flags,
            ...flags,
        }
    },

    [SET_LD_INITIALIZED]($state){ $state.initialized = true },
    [SET_CONNECTION]($state, connection) {$state.connection = connection},
}