export default {
  GRAVATAR_URL: 'https://www.gravatar.com/avatar/',
  ASSIGNEE_TYPE: {
    ME: 'me',
    UNASSIGNED: 'unassigned',
    ALL: 'all',
  },
  STATUS_TYPE: {
    OPEN: 'open',
    RESOLVED: 'resolved',
    PENDING: 'pending',
    SNOOZED: 'snoozed',
    ALL: 'all',
  },
};
export const DEFAULT_REDIRECT_URL = '/app/';

export const LOCAL_STORAGE_KEYS = {
  ALL_MESSAGES_READ: (email, chatId) =>  `ALL_MESSAGES_READ_${email}_${chatId}`
}
