<template>
  <div id="app" class="app-wrapper app-root">
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
    <add-account-modal
      :show="showAddAccountModal"
      :has-accounts="hasAccounts"
    />
    <audit-comments-not-read-modal
      :show="showAuditCommentsNotReadModal"
      :has-accounts="hasAccounts"
      :conversationsNotRead="unreadAuditsConversations"
      @close-audit-comments-not-read-modal="closeAuditCommentsNotReadModal"
    />

    <alert-pro-new-when-tickets-renew-modal
      v-if="this.flags.automated_actions_in_trainees"
      :show="showAlertProNewWhenTicketsRenewModal"
      :notificationsNotRead="getNotifications"
      @close-alert-pro-new-when-tickets-renew-modal="closeAlertProNewWhenTicketsRenewModal"
    />

    <announcements-content-default-modal/>

    <announcements-content-updated-modal
      :openKnowledgeBaseModal="this.openKnowledgeBaseModal"
    />

    <woot-snackbar-box />

    <available-conversations-snackbar-container/>

    <knowledge-base-modal
      :show="this.isKnowledgeBaseModalVisible"
      :project-slug="this.project_slug"
      @on-close="this.hideKnowledgeBaseModal"
    />

    <stonly-widget/>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import introJS from  'intro.js'
import { FIRST_LOGIN_TUTORIAL, Step} from './store/modules/walktrough/walkthroughs';


import AddAccountModal from '../dashboard/components/layout/sidebarComponents/AddAccountModal';
import AuditCommentsNotReadModal from '../dashboard/components/layout/sidebarComponents/AuditCommentsNotReadModal';
import AlertProNewWhenTicketsRenewModal from '../dashboard/components/layout/sidebarComponents/AlertProNewWhenTicketsRenewModal';
import AnnouncementsContentDefaultModal from '../dashboard/components/AnnouncementsContentDefaultModal';
import AnnouncementsContentUpdatedModal from '../dashboard/components/AnnouncementsContentUpdatedModal';
import WootSnackbarBox from './components/SnackbarContainer';
import { accountIdFromPathname } from './helper/URLHelper';
import KnowledgeBaseModal from './components/widgets/modal/KnowledgeBaseModal.vue';
import AvailableConversationsSnackbarContainer from './components/AvailableConversationsSnackbarContainer.vue';
import StonlyWidget from './components/StonlyWidget.vue';
import wootConstants from '../../javascript/dashboard/constants';

export default {
  name: 'App',

  components: {
    WootSnackbarBox,
    AddAccountModal,
    AnnouncementsContentDefaultModal,
    AnnouncementsContentUpdatedModal,
    AuditCommentsNotReadModal,
    AlertProNewWhenTicketsRenewModal,
    KnowledgeBaseModal,
    AvailableConversationsSnackbarContainer,
    StonlyWidget
},

  data() {
    return {
      showAddAccountModal: false,
      showAuditCommentsNotReadModal: false,
      showAlertProNewWhenTicketsRenewModal: false,
      project_slug: '',
      isKnowledgeBaseModalVisible: false,
      TUTORIAL_FIRST_LOGIN: FIRST_LOGIN_TUTORIAL
    };
  },

  computed: {
    ...mapGetters({
      getAccount: 'accounts/getAccount',
      currentUser: 'getCurrentUser',
      unreadAuditsConversations: 'getAgentUnreadAudits',
      getNotifications: 'projectMemberRestrictions/getNotifications',
      flags:  'getAppFlags',
      getSteps: 'getSteps',
    }),

    hasAccounts() {
      return (
        this.currentUser &&
        this.currentUser.accounts &&
        this.currentUser.accounts.length !== 0
      );
    },
    hasUnreadAudits() {
      this.getUnreadAudits();
    },
    hasUnreadNotifications() {
      this.getUnreadNotifications();
    }
  },

  watch: {
    currentUser() {
      if (!this.hasAccounts) {
        this.showAddAccountModal = true;
      }
    },
    hasUnreadAudits() {
      this.hasUnreadAudits()
    },
    hasUnreadNotifications() {
      this.hasUnreadNotifications();
    }
  },
  mounted() {
    this.$store.dispatch('setUser');
    this.$store.dispatch('getAgentHasUnreadAudit');
    this.$store.dispatch('projectMemberRestrictions/setNotifications');
    this.$store.dispatch('fetchAllOpenConversations')
    this.setLocale(window.chatwootConfig.selectedLocale);
    this.initializeAccount();
  },

  methods: {
    closeAuditCommentsNotReadModal() {
      this.showAuditCommentsNotReadModal = false
    },
    closeAlertProNewWhenTicketsRenewModal() {
      this.showAlertProNewWhenTicketsRenewModal = false
    },
    setLocale(locale) {
      this.$root.$i18n.locale = locale;
    },

    async initializeAccount() {
      const { pathname } = window.location;
      const accountId = accountIdFromPathname(pathname);

      if (accountId) {
        await this.$store.dispatch('accounts/get');
        const { locale } = this.getAccount(accountId);
        this.setLocale(locale);
      }
    },
    async getUnreadAudits() {
      this.showAuditCommentsNotReadModal = !!this.unreadAuditsConversations.length
    },
    async getUnreadNotifications() {
      this.showAlertProNewWhenTicketsRenewModal = !!this.getNotifications.length
    },
    hideKnowledgeBaseModal(){
      this.isKnowledgeBaseModalVisible = false;
    },
    openKnowledgeBaseModal(project_slug){
      this.project_slug = project_slug;
      this.isKnowledgeBaseModalVisible = true;
    }
  },
};
</script>

<style lang="scss">
@import './assets/scss/app';
@import 'intro.js/introjs.css';

.icon{
    max-height: 18pt;
  }

.introjs-tooltiptext {
  font-size: 14px;
}

.introjs-skipbutton {
  display: none;
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
