import { actions } from "./actions"
import { getters } from "./getters"
import { mutations } from "./mutations"
import { INTERNET_CONNECTION } from 'shared/constants/internetConnection'

const state = {
  internetConnection: INTERNET_CONNECTION.ONLINE,
}

export default {
    actions,
    getters,
    mutations,
    state,
}