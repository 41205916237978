<template>
  <meet-block
    v-if="isAwatingMeet"
  />
  <div v-else-if="!isBlocked || isReadOnly" class="conversation-details-wrap">
    <conversation-header
      v-if="currentChat.id && showTicket"
      :chat="currentChat"
      :is-contact-panel-open="isContactPanelOpen"
      @contact-panel-toggle="onToggleContactPanel"
    />
    <div class="messages-and-sidebar">
      <messages-view
        v-if="currentChat.id"
        :show-reply="!isBlocked && showTicket"
        :is-blocked="isBlocked"
        :show-ticket-content="isContentShowlable()"
        :inbox-id="currentChat.inbox_id"
        :is-contact-panel-open="isContactPanelOpen"
        @contact-panel-toggle="onToggleContactPanel"
        @onAssign = "onAssignTicket"
      />
      <empty-state v-else />

      <div v-show="showContactPanel" class="conversation-sidebar-wrap">
        <contact-panel
          v-if="showContactPanel"
          :conversation-id="currentChat.id"
          :inbox-id="currentChat.inbox_id"
          :on-toggle="onToggleContactPanel"
          :is-blocked="isBlocked"
        />
      </div>
    </div>
  </div>
  <blocked
    v-else
    :its-my-ticket="itsMyTicket"
    :inbox-name="inboxName"
    @on-set-read-only="setReadOnly()"
  />
</template>
<script>
import { mapGetters } from 'vuex';
import ContactPanel from 'dashboard/routes/dashboard/conversation/ContactPanel';
import ConversationHeader from './ConversationHeader';
import EmptyState from './EmptyState';
import MessagesView from './MessagesView';
import Blocked from './Blocked'
import MeetBlock from './MeetBlock'
import Assignment from './Assignment'
import adminMixin from '../../../mixins/isAdmin';

export default {
  components: {
    Blocked,
    Assignment,
    EmptyState,
    MessagesView,
    ContactPanel,
    ConversationHeader,
    MeetBlock
  },

  mixins: [adminMixin],
  props: {
    isContactPanelOpen: {
      type: Boolean,
      default: true,
    },
  },
  data(){
    return {
      isReadOnly:false,
      showTicket: false
    }
  },
  computed: {
    ...mapGetters({
      currentChat: 'getSelectedChat',
      currentUserStatus: 'getUserStatus',
      currentUser: 'getCurrentUser',
      getInbox: 'inboxes/getInbox',
      inboxes: 'inboxes/getInboxes',
      flags: 'getAppFlags'
    }),
    isAwatingMeet() {
      const inbox = this.inboxes[0]
      const isProjectActive = !!this.flags.tutorial_meet_url.projects[inbox?.project]
      const isAwating = this.currentUserStatus === 'awaiting_meet'
      
      return isProjectActive && isAwating
    },
    itsMyTicket() {
      if(this.currentChat.meta.assignee){
        return this.currentChat.meta.assignee.id === this.currentUser.id
      } else {
        return false;
      }
    },

    showContactPanel() {
      return this.isContactPanelOpen && this.currentChat.id && this.showTicket;
    },
    isBlocked() {
      return this.currentUser.inboxes_blocked?.includes(this.currentChat.inbox_id)
    },
    inboxName() {
      const inbox = this.getInbox(this.currentChat.inbox_id)
      return inbox.name
    },
  },
  watch: {
    'currentChat.inbox_id'(inboxId) {
      if (inboxId) {
        if(this.isAdmin) {
          this.$store.dispatch('inboxAssignableAgents/fetch', { inboxId });
        }
      }
    }
  },
  methods: {

    isContentShowlable() {
      if(this.currentChat.status === "open"){
        const inbox = this.getInbox(this.currentChat.inbox_id)
        this.showTicket = inbox.tickets_content_visible || this.isAdmin || ( this.currentChat.id && this.itsMyTicket) ;
        return this.showTicket;
      }
      this.showTicket = true;
      return this.showTicket;
    },

    setReadOnly() {
      this.isReadOnly = true
    },
    onAssignTicket(){
      this.showTicket = true
    },
    onToggleContactPanel() {
      this.$emit('contact-panel-toggle');
    },
  }
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/woot';

.conversation-details-wrap {
  display: flex;
  flex-direction: column;
  min-width: 0;
  width: 100%;
  border-left: 1px solid var(--color-border);
  background: var(--color-background-light);
}

.messages-and-sidebar {
  display: flex;
  background: var(--color-background-light);
  margin: 0;
  height: calc(100vh - var(--space-jumbo));
}

.conversation-sidebar-wrap {
  height: auto;
  flex: 0 0;
  overflow: hidden;
  overflow: auto;
  background: white;
  flex-basis: 28rem;

  @include breakpoint(large up) {
    flex-basis: 30em;
  }

  @include breakpoint(xlarge up) {
    flex-basis: 31em;
  }

  @include breakpoint(xxlarge up) {
    flex-basis: 33rem;
  }

  @include breakpoint(xxxlarge up) {
    flex-basis: 40rem;
  }

  &::v-deep .contact--panel {
    width: 100%;
    height: 100%;
    max-width: 100%;
  }
}
</style>
