<template>
  <a
    class="sub-menu-title"
    :class="getMenuItemClass"
    data-tooltip
    aria-haspopup="true"
    :title="menuItem.toolTip"
    @click="handleClick()"
    :disabled="menuItem.counter === 0"
  >
    <div class="wrap">
      <div>
        <i :class="menuItem.icon" />
        {{ $t(`SIDEBAR.${menuItem.label}`) }}
      </div>
      <span v-if="menuItem.counter" class="menu-item-counter">{{ menuItem.counter }}</span>
    </div>
    <span
      v-if="showItem(menuItem)"
      class="child-icon ion-android-add-circle"
      @click.prevent="newLinkClick(menuItem)"
    />
  </a>
</template>

<script>
import router from '../../routes';
import adminMixin from '../../mixins/isAdmin';

export default {
  mixins: [adminMixin],
  props: {
    menuItem: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    getMenuItemClass() {
      return this.menuItem.cssClass
        ? `side-menu ${this.menuItem.cssClass}`
        : 'side-menu';
    },
  },
  methods: {
    newLinkClick(item) {
      if (item.newLinkRouteName) {
        router.push({ name: item.newLinkRouteName, params: { page: 'new' } });
      } else if (item.showModalForNewItem) {
        if (item.modalName === 'AddLabel') {
          this.$emit('add-label');
        }
      }
    },
    showItem(item) {
      return this.isAdmin && item.newLink !== undefined;
    },
    handleClick() {
      this.menuItem.action && this.menuItem.action()
    }
  },
};
</script>
<style lang="scss" scoped>

.sub-menu-title {
  display: flex;
  justify-content: space-between;

  &[disabled]{
    opacity: .5;
    pointer-events: none;
  }
}

.wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
}

.menu-item-counter {
  display: inline-block;

  background: #ef6c6c;
  font-weight: 100;
  font-size: 12px;
  text-align: center;
  color: white;
  min-width: 20px;

  padding: 4px 4px;
  border-radius: 100px;
}



</style>
