<template>
  <section class="conversation-page">
    <chat-list
      :project="project"
      :label="label"
      :team-id="teamId"
      @conversation-load="onConversationLoad"
      v-show="hideMenus" 
    >
      <pop-over-search />
    </chat-list>
    <conversation-box
      :is-contact-panel-open="isContactPanelOpen"
      @contact-panel-toggle="onToggleContactPanel"
    >
    </conversation-box>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import ChatList from '../../../components/ChatList';
import ConversationBox from '../../../components/widgets/conversation/ConversationBox';
import PopOverSearch from './search/PopOverSearch';
import uiSettingsMixin from 'dashboard/mixins/uiSettings';
import adminMixin from '../../../mixins/isAdmin';

export default {
  components: {
    ChatList,
    ConversationBox,
    PopOverSearch,
  },
  mixins: [uiSettingsMixin, adminMixin],
  props: {
    project: {
      type: [String, Number],
      default: 0,
    },
    conversationId: {
      type: [String, Number],
      default: 0,
    },
    label: {
      type: String,
      default: '',
    },
    teamId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showSearchModal: false,
    };
  },
  computed: {
    ...mapGetters({
      chatList: 'getAllConversations',
      currentChat: 'getSelectedChat',
      currentUserID: 'getCurrentUserID',
    }),
    isContactPanelOpen() {
      if (this.currentChat.id) {
        const { is_contact_sidebar_open: isContactSidebarOpen } =
          this.uiSettings;
        return isContactSidebarOpen;
      }
      return false;
    },
    hideMenus() {
      return !window.chatwootConfig.hideMenus
    }
  },

  mounted() {
    this.$watch('currentChat', () => this.chatGuard());

    this.$store.dispatch('agents/get');
    this.initialize();
    this.$watch('$store.state.route', () => this.initialize());
    this.$watch('chatList.length', () => {
      this.setActiveChat();
    });
  },
  beforeRouteLeave(to, from, next) {
    if(this.isReplyBoxHasContent() && !window.confirm("As alterações feitas não serão salvas, deseja sair da página?")) {
      return
    }

    next()
  },
  beforeMount() {
    window.addEventListener("beforeunload", this.preventNav)
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.preventNav);
  },

  methods: {
    isReplyBoxHasContent() {
      const replyBox = document.getElementById("replyBoxTextArea")

      return replyBox && !!replyBox.value.trim()
    },
    preventNav(event) {
      if(this.isReplyBoxHasContent()) {
        event.preventDefault()
        event.returnValue = ""
      }
    },
    onConversationLoad() {
      this.fetchConversationIfUnavailable();
    },
    chatGuard() {
      const redirectCondition = (
        this.currentChat &&
        !/closed|resolved/.test(this.currentChat.status) &&
        this.currentChat.meta &&
        this.currentChat.meta.assignee &&
        this.currentChat.meta.assignee.id !== this.currentUserID &&
        !this.isAdmin
      )

      if (redirectCondition) {
        this.$router.back();
      }
    },
    initialize() {
      this.$store.dispatch('setActiveInbox', this.project);
      this.setActiveChat(true);
    },
    fetchConversationIfUnavailable() {
      if (!this.conversationId) {
        return;
      }
      const chat = this.findConversation();
      if (!chat) {
        this.$store.dispatch('getConversation', this.conversationId);
      }
    },
    findConversation() {
      const conversationId = parseInt(this.conversationId, 10);
      const [chat] = this.chatList.filter(c => c.id === conversationId);
      return chat;
    },
    setActiveChat(forceScroll) {
      if (this.conversationId) {
        const chat = this.findConversation();
        if (!chat) {
          return;
        }
        this.$store.dispatch('setActiveChat', chat).then(() => {
          bus.$emit('scrollToMessage', forceScroll);
        });
      } else {
        this.$store.dispatch('clearSelectedState');
      }
    },
    onToggleContactPanel() {
      this.updateUISettings({
        is_contact_sidebar_open: !this.isContactPanelOpen,
      });
    },
    onSearch() {
      this.showSearchModal = true;
    },
    closeSearch() {
      this.showSearchModal = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.conversation-page {
  display: flex;
  width: 100%;
  height: 100%;
}
</style>
