<template>
    <modal :show.sync="show" :on-close="handleClose">
        <woot-modal-header :header-title="'Base de conhecimento'"  />
        <iframe :src="hyperlink || previewLink()" width="100%" height="600px" style="border:none"/>
    </modal>
</template>

<script>
import {mapGetters} from 'vuex'
import Modal from '../../Modal'

export default{
    components: {
        Modal,
    },
    props: {
        show: Boolean,
        projectSlug: String,
        hyperlink: String
    },
    methods:{
        previewLink(){
            if(!!this.projectSlug){
                return `https://pro.cloudhumans.com/preview/${this.projectSlug}`
            }
                return null
        },
        handleClose(){
            this.$emit("on-close")
        }
    }
}

</script>

<style lang="'scss" scoped>
</style>
