<template>
  <div>
    <div class="ui-snackbar" :class="{'ui-snackbar--info': type === 'info'}">
      <div class="ui-snackbar-text">{{ message }}</div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    message: String,
    showButton: Boolean,
    type: {
      type: String,
      default: 'default'
    },
    duration: {
      type: [String, Number],
      default: 3000,
    },
  },
  data() {
    return {
      toggleAfterTimeout: false,
    };
  }
};
</script>
