/* global axios */
import ApiClient from './ApiClient';

class TicketCriticalities extends ApiClient {
  constructor() {
    super('ticket_criticalities', { accountScoped: true });
  }
  getTicketCriticality() {
    return axios.get(`${this.url}`);
  }
}

export default new TicketCriticalities();