/* global axios */
import ApiClient from './ApiClient';

class AnnouncementsAPI extends ApiClient {
  constructor() {
    super('announcements', { accountScoped: true });
  }

  getUnreads() {
    return axios.get(`${this.url}/unreads`);
  }

  read(ids) {
    return axios.post(`${this.url}/mark_as_read`, {
      ids
    });
  }
}

export default new AnnouncementsAPI();
