<template>
  <blocked-container>
    <p>
      Parabéns! Você está a um passo de responder seu primeiro ticket!<br /><br />
      
      Para garantir que você estará apto a efetuar o atendimento da melhor forma possível, temos uma pessoa esperando por você para te apresentar nosso processo e tirar suas dúvidas no seu primeiro ticket!<br /><br />

      <b>Atenção!</b> É de extrema importância a sua participação para garantir que nada de errado ocorra no seu primeiro ticket.
    </p>
    <button class="button" @click="goToMeet">Acessar</button>
  </blocked-container>
</template>

<script>
import BlockedContainer from './BlockedContainer'
import { mapGetters } from 'vuex';

export default {
  components: {
    BlockedContainer
  },
  computed: {
    ...mapGetters({
      inboxes: 'inboxes/getInboxes',
      currentUser: 'getCurrentUser',
      flags: 'getAppFlags'
    }),
    meetLink() {
      const inbox = this.inboxes[0]
      return this.flags.tutorial_meet_url.projects[inbox?.project]
    }
  },
  methods: {
    goToMeet() {
      window.open(this.meetLink, "_blank")
      this.$store.dispatch("setUserActive")
    }
  }
}
</script>