import * as types from '../../mutation-types';
import SuggestionApi from '../../../api/suggestion'
import ConversationApi from '../../../api/conversations'

const BLOCKED_INTENTS = [
  'common.fallback',
  'common.handoff'
]

const actions = {
  fetchSuggestion: async ({ commit }, { message, conversationId, inboxId, projectName }) => {
    commit(types.default.SET_SUGGESTION_LOADING_STATUS, true);

    try {
      const response = await SuggestionApi.getSuggestion(message, inboxId, conversationId, projectName);
      await ConversationApi.createSuggestionUsage(conversationId, response.data.id, response.data.intent, response.data.response[0], message, response.data.confidence)

      const showSuggestion = response.data.confidence > 0.3 && !BLOCKED_INTENTS.includes(response.data.intent)
      commit(types.default.SET_SUGGESTION_VISIBLE, showSuggestion);
      commit(types.default.SET_SUGGESTION_DATA, response.data);
    } catch (error) {
      commit(types.default.SET_SUGGESTION_VISIBLE, false);
    } finally {
      commit(types.default.SET_SUGGESTION_LOADING_STATUS, false);
    }
  },
  setVisible: ({ commit }, value) => commit(types.default.SET_SUGGESTION_VISIBLE, value),
  sendSuggestionUsage: async (_, {conversationId, suggestionIntentId, finalResponse}) => {
    await ConversationApi.updateResponseSended(conversationId, suggestionIntentId, finalResponse)
  },
  setSuggestionClicked: async({ commit }, {conversationId, suggestionIntentId, clickedBy}) => {
    await ConversationApi.setSuggestionClicked(conversationId, suggestionIntentId, clickedBy)
  }
};

export default actions;
