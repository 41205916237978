<template>
  <div class="view-box fill-height">
    <div class="project-information" v-if="showProjectInformation">
      <div class="project-information__warning">Atenção:</div>
      <div class="project-information__content">Você está atendendo um ticket do projeto</div>
      <div class="project-information__project">{{inboxProject}}</div> 
    </div>
    <div
      v-if="!currentChat.can_reply && !isATwilioWhatsappChannel"
      class="banner messenger-policy--banner"
    >
      <span>
        {{ $t('CONVERSATION.CANNOT_REPLY') }}
        <a
          :href="facebookReplyPolicy"
          rel="noopener noreferrer nofollow"
          target="_blank"
        >
          {{ $t('CONVERSATION.24_HOURS_WINDOW') }}
        </a>
      </span>
    </div>
    <div
      v-if="!currentChat.can_reply && isATwilioWhatsappChannel"
      class="banner messenger-policy--banner"
    >
      <span>
        {{ $t('CONVERSATION.TWILIO_WHATSAPP_CAN_REPLY') }}
        <a
          :href="twilioWhatsAppReplyPolicy"
          rel="noopener noreferrer nofollow"
          target="_blank"
        >
          {{ $t('CONVERSATION.TWILIO_WHATSAPP_24_HOURS_WINDOW') }}
        </a>
      </span>
    </div>

    <div v-if="isATweet" class="banner">
      <span v-if="!selectedTweetId">
        {{ $t('CONVERSATION.SELECT_A_TWEET_TO_REPLY') }}
      </span>
      <span v-else>
        {{ $t('CONVERSATION.REPLYING_TO') }}
        {{ selectedTweet.content || '' }}
      </span>
      <button
        v-if="selectedTweetId"
        class="banner-close-button"
        @click="removeTweetSelection"
      >
        <i v-tooltip="$t('CONVERSATION.REMOVE_SELECTION')" class="ion-close" />
      </button>
    </div>
    <ul v-if="showTicketContent" class="conversation-panel">
      <transition name="slide-up">
        <li class="spinner--container">
          <span v-if="shouldShowSpinner" class="spinner message" />
        </li>
      </transition>
      <message
        v-for="message in getReadMessages"
        :key="message.id"
        class="message--read"
        :data="message"
        :is-a-tweet="isATweet"
        :is-audit-timeout="verifyAuditTimeout"
      />

      <li v-show="getUnreadCount != 0" class="unread--toast">
        <span class="text-uppercase">
          {{ getUnreadCount }}
          {{
            getUnreadCount > 1
              ? $t('CONVERSATION.UNREAD_MESSAGES')
              : $t('CONVERSATION.UNREAD_MESSAGE')
          }}
        </span>
      </li>
      <message
        v-for="message in getUnReadMessages"
        :key="message.id"
        class="message--unread"
        :data="message"
        :is-a-tweet="isATweet"
      />

      <message-suggestion v-if="shouldShowMessageSuggestion" :message-content="getMessageToSuggestion" />
      <presence-confirmation v-if="shouldShowPresenceConfirmation" />
    </ul>

    <assignment
      v-else
      @on-assign="$emit('onAssign')"
      @onProUser="setIsRestritected(false)"
      @onTraineeUser="setIsRestritected(true)"
      @onRestrictedUser="setIsRestritected(true)"
    />

    <div
      class="conversation-footer"
      :class="{ 'modal-mask': isPopoutReplyBox }"
    >
      <button
        class="go-to-bottom"
        v-if="getUnReadMessages.length && !autoScroll"
        @click="scrollToBottom"
      >
        {{getUnReadMessages.length}}
        {{
          getUnReadMessages.length > 1
            ? 'Novas mensagens'
            : 'Nova mensagem'
        }}
      </button>

      <reply-box
        v-if="isReplyBoxShowlable"
        v-on-clickaway="closePopoutReplyBox"
        :conversation-id="currentChat.id"
        :is-a-tweet="isATweet"
        :selected-tweet="selectedTweet"
        :popout-reply-box="isPopoutReplyBox"
        :underlying-channel="underlyingChannel"
        :has-unread-messages="pendentMessages"
        @onSelfAssign="$emit('onAssign')"
        @click="showPopoutReplyBox"
        @scrollToMessage="scrollToBottom"
        @onReadAll="updateUnreadMessagesStatus(true)"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import ReplyBox from './ReplyBox';
import Message from './Message';
import MessageSuggestion from './MessageSuggestion';
import PresenceConfirmation from './PresenceConfirmation';
import conversationMixin from '../../../mixins/conversations';
import timeMixin from '../../../mixins/time';
import { getTypingUsersText } from '../../../helper/commons';
import { BUS_EVENTS } from 'shared/constants/busEvents';
import { REPLY_POLICY } from 'shared/constants/links';
import inboxMixin from 'shared/mixins/inboxMixin';
import { calculateScrollTop } from './helpers/scrollTopCalculationHelper';
import { isEscape } from 'shared/helpers/KeyboardHelpers';
import eventListenerMixins from 'shared/mixins/eventListenerMixins';
import { mixin as clickaway } from 'vue-clickaway';
import fromUnixTime from 'date-fns/fp/fromUnixTime/index';
import { MESSAGE_TYPE } from 'shared/constants/messages';
import wootConstants from '../../../constants';
import adminMixin from '../../../mixins/isAdmin';
import introJs from 'intro.js'
import Assignment from './Assignment.vue'
import {LOCAL_STORAGE_KEYS} from '../../../constants'


export default {
  components: {
    Message,
    MessageSuggestion,
    PresenceConfirmation,
    ReplyBox,
    Assignment
  },
  mixins: [conversationMixin, inboxMixin, eventListenerMixins, clickaway, timeMixin, adminMixin],
  props: {
    showReply: {
      type: Boolean,
      default: true,
    },
    showTicketContent:{
      type: Boolean,
      default: false
    },
    isContactPanelOpen: {
      type: Boolean,
      default: false,
    },
    isBlocked: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isLoadingPrevious: true,
      heightBeforeLoad: null,
      conversationPanel: null,
      selectedTweetId: null,
      isPopoutReplyBox: false,
      autoScroll: true,
      auditCommentsLoaded: false,
      isUserRestricted:false,
      pendentMessages: false,
      isInFirstMessage: false,
      reloadProjectInformation: false
    };
  },

  computed: {
    ...mapGetters({
      currentChat: 'getSelectedChat',
      allConversations: 'getAllConversations',
      inboxesList: 'inboxes/getInboxes',
      listLoadingStatus: 'getAllMessagesLoaded',
      getUnreadCount: 'getUnreadCount',
      loadingChatList: 'getChatListLoadingStatus',
      currentUser: 'getCurrentUser',
      flags:  'getAppFlags'
    }),
    shouldShowPresenceConfirmation() {
      if(!this.getMessages) {
        return false
      }

      const lastMessage = this.getMessages.messages.at(-1)
      return this.isMyTicket && lastMessage.message_type === MESSAGE_TYPE.PRESENCE_VERIFICATION
    },
    isMyTicket(){
      return this.currentChat?.meta?.assignee?.id === this.currentUser.id
    },
    allMessageReadKey(){
      return LOCAL_STORAGE_KEYS.ALL_MESSAGES_READ(this.currentUser.email, this.currentChat.id);
    },
    isReplyBoxShowlable(){
      if(!this.isBlocked && !this.isAdmin && !this.showTicketContent){
        return this.isUserRestricted
      } else {
        return this.showReply;
      }
    },
    inboxId() {
      return this.currentChat.inbox_id;
    },
    inbox() {
      return this.$store.getters['inboxes/getInbox'](this.inboxId);
    },
    typingUsersList() {
      const userList = this.$store.getters[
        'conversationTypingStatus/getUserList'
      ](this.currentChat.id);
      return userList;
    },
    isAnyoneTyping() {
      const userList = this.typingUsersList;
      return userList.length !== 0;
    },
    typingUserNames() {
      const userList = this.typingUsersList;

      if (this.isAnyoneTyping) {
        const userListAsName = getTypingUsersText(userList);
        return userListAsName;
      }

      return '';
    },
    verifyAuditTimeout() {
      const timeout = this.auditTimeLimit;
      return timeout && new Date() >= timeout
    },
    auditTimeLimit() {
      const timeLimit = this.inbox.max_wait_limit_for_client_response;

      if(!timeLimit) return null

      const messages = this.getMessages.messages.filter((message) => message.sender_type !== null);
      const lastMessage = messages[messages.length - 1]

      if(!lastMessage || lastMessage.sender_type !== "User") return null

      const hoursMinutes = timeLimit.toFixed(2).split('.');
      const minutes = (parseInt(hoursMinutes[1])*60)/100

      const limitDate = new Date(lastMessage.created_at * 1000);

      limitDate.setHours(
         limitDate.getHours() + parseInt(hoursMinutes[0]),
         limitDate.getMinutes() + minutes
        )

      return limitDate;
    },
    getMessages() {
      const [chat] = this.allConversations.filter(
        c => c.id === this.currentChat.id
      );
      return chat;
    },
    getReadMessages() {
      const chat = this.getMessages;
      return chat === undefined ? null : this.readMessages(chat);
    },
    getUnReadMessages() {
      const chat = this.getMessages;
      return chat === undefined ? null : this.unReadMessages(chat);
    },
    getMessageToSuggestion() {
      const chat = this.getMessages;

      if(!chat) {
        return null
      }

      const messages = chat.messages.filter(({message_type}) => message_type === MESSAGE_TYPE.INCOMING)
      const lastMessage = messages[messages.length - 1]

      return messages.filter(({created_at}) => {
        const lastMessageTime = fromUnixTime(lastMessage.created_at)
        const messageTime = fromUnixTime(created_at);


        const diff = this.differenceInSecondsBetween(lastMessageTime, messageTime)

        return diff < 10
      }).map(m => m.content).join(' ')
    },
    shouldShowMessageSuggestion() {
      const chat = this.getMessages
      const isOpen = this.currentChat.status === wootConstants.STATUS_TYPE.OPEN
      const isActiveProjetc = this.flags.bot_response_suggestion.projects.includes(this.inboxProject)

      if(!chat || chat.messages.length === 0 || !isActiveProjetc || !isOpen) return false

      const messages = chat.messages.filter(message => message.message_type !== null && message.message_type !== undefined)

      const is_last_message_from_client =  messages[messages.length - 1].message_type !== MESSAGE_TYPE.OUTGOING
      return is_last_message_from_client
    },
    shouldShowSpinner() {
      return (
        (this.getMessages && this.getMessages.dataFetched === undefined) ||
        (!this.listLoadingStatus && this.isLoadingPrevious)
      );
    },

    shouldLoadMoreChats() {
      return !this.listLoadingStatus && !this.isLoadingPrevious;
    },

    conversationType() {
      const { additional_attributes: additionalAttributes } = this.currentChat;
      const type = additionalAttributes ? additionalAttributes.type : '';
      return type || '';
    },

    underlyingChannel() {
      return this.currentChat.underlying_channel;
    },

    isATweet() {
      return this.conversationType === 'tweet';
    },

    selectedTweet() {
      if (this.selectedTweetId) {
        const { messages = [] } = this.getMessages;
        const [selectedMessage] = messages.filter(
          message => message.id === this.selectedTweetId
        );
        return selectedMessage || {};
      }
      return '';
    },
    facebookReplyPolicy() {
      return REPLY_POLICY.FACEBOOK;
    },
    twilioWhatsAppReplyPolicy() {
      return REPLY_POLICY.TWILIO_WHATSAPP;
    },
    scrollToUser() {
      return this.searchParam('scrollToUser');
    },
    inboxProject() {
      return this.getInbox().project
    },
    showProjectInformation() {
      return !this.reloadProjectInformation && this.flags.hide_project_information && this.isMyTicket
    }
  },

  watch: {
    currentChat(newChat, oldChat) {
      if (newChat.id === oldChat.id) {
        return;
      }

      this.selectedTweetId = null;

      this.reloadProjectInformation = true
      
      setTimeout(() => {
        this.reloadProjectInformation = false
      }, 100)
    },
    getReadMessages(newState, oldState) {
      if(oldState.length > 1 && !this.auditCommentsLoaded) {
        this.initIntroJs()
        this.scrollTop()
      }
    },
    isLoadingPrevious(newState, oldState) {
      this.scrollTop()
      this.goToFirstUserMessage()
    }
  },

  created() {
    bus.$off('scrollToMessage');

    bus.$on('scrollToMessage', (force) => {
      if(this.autoScroll || force) {
        this.makeMessagesRead()
        this.$nextTick(() => this.scrollToBottom());
      }
    });

    bus.$on(BUS_EVENTS.SET_TWEET_REPLY, selectedTweetId => {
      this.selectedTweetId = selectedTweetId;
    });
  },

  mounted() {
    if(this.getReadMessages.length > 1) {
      this.initIntroJs()
    }

    this.addScrollListener();
  },

  unmounted() {
    this.removeScrollListener();
  },

  updated(){
    this.setupUnreadMessages();
  },

  methods: {
    getInbox(){
      if(this.currentChat) {
        return this.$store.getters['inboxes/getInbox'](this.currentChat.inbox_id);
      }
    },
    setupUnreadMessages() {
      this.pendentMessages = this.hasUnreadMessages()
    },
    hasOtherMessages(){
      return this.currentChat.messages.some(message => (message.sender_type === "User") && (message.sender?.id != this.currentUser.id))
    },
    hasUnreadMessages(){
      if(!this.isMyTicket) return false;

      const allMessagesRead = this.syncAllMessageReadStorage()

      return allMessagesRead.pro === this.currentUser.email && allMessagesRead.chat_id === this.currentChat.id?
        this.hasOtherMessages() && !allMessagesRead.understand_button_click
       : false
    },

    getIsAllMessagesReadStorage(){
      const data = localStorage.getItem(this.allMessageReadKey);
      try {
        return JSON.parse(data)
      } catch (Exception) {
        return null;
      }
      },

    syncAllMessageReadStorage(){
      const allMessagesRead = this.getIsAllMessagesReadStorage()
      if(allMessagesRead) return allMessagesRead
      this.updateUnreadMessagesStatus(false);
      return this.getIsAllMessagesReadStorage()
    },

    updateUnreadMessagesStatus(clicked) {
      const data = {
        pro: this.currentUser.email,
        chat_id: this.currentChat.id,
        understand_button_click: clicked,
      }
      localStorage.setItem(this.allMessageReadKey, JSON.stringify(data))
      this.setupUnreadMessages()
    },

    setIsRestritected(isRestricted){
      this.isUserRestricted = isRestricted;
      this.isReplyBoxShowlable;
    },

    initIntroJs() {
      const audit = Boolean(this.searchParam('audit'))
      const canViewAudit = this.isAdmin || this.currentChat?.meta?.assignee?.id === this.currentUser.id
      if (audit && canViewAudit && this.currentChat.audit_comments) {
        this.$store.dispatch('setUnreadAuditsEmpty')
        const steps = this.currentChat.audit_comments
          .sort((a, b) => a.messageId - b.messageId)
          .map(comment => ({
            element: document.querySelector(`#message-${comment.messageId}`),
            intro: comment.content
          }))
          .filter(({element}) => Boolean(element))

        if(steps.length && this.currentChat.allMessagesLoaded) {
          introJs().setOptions({
            steps: [
              {
                title: 'Atenção',
                intro: 'Esse ticket apresenta pontos de melhoria que devem observados. Leia atentamente aos comentários antes de prosseguir.'
              },
              ...steps
            ]
          }).oncomplete(()=>{
            this.setAuditRead()
          }).start()
          this.auditCommentsLoaded = true
        }
        else {
          this.$store
            .dispatch('fetchPreviousMessages', {
              conversationId: this.currentChat.id,
              before: this.getMessages.messages[0].id,
            })
        }
      }
    },
    showPopoutReplyBox() {
      this.isPopoutReplyBox = !this.isPopoutReplyBox;
    },
    closePopoutReplyBox() {
      this.isPopoutReplyBox = false;
    },
    handleKeyEvents(e) {
      if (isEscape(e)) {
        this.closePopoutReplyBox();
      }
    },
    addScrollListener() {
      this.conversationPanel = this.$el.querySelector('.conversation-panel');
      this.setScrollParams();
      this.conversationPanel.addEventListener('scroll', this.handleScroll);
      this.$nextTick(() => this.scrollToBottom());
      this.isLoadingPrevious = false;
    },
    removeScrollListener() {
      this.conversationPanel.removeEventListener('scroll', this.handleScroll);
    },
    scrollToBottom() {
      let relevantMessages = [];
      if (this.getUnreadCount > 0) {
        // capturing only the unread messages
        relevantMessages =
          this.conversationPanel.querySelectorAll('.message--unread');
      } else {
        // capturing last message from the messages list
        relevantMessages = Array.from(
          this.conversationPanel.querySelectorAll('.message--read')
        ).slice(-1);
      }
      this.conversationPanel.scrollTop = calculateScrollTop(
        this.conversationPanel.scrollHeight,
        this.$el.scrollHeight,
        relevantMessages
      );
    },
    onToggleContactPanel() {
      this.$emit('contact-panel-toggle');
    },
    setScrollParams() {
      this.heightBeforeLoad = this.conversationPanel.scrollHeight;
      this.scrollTopBeforeLoad = this.conversationPanel.scrollTop;
    },

    handleScroll(e) {
      this.setScrollParams();
      const isAtBottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight
      this.autoScroll = isAtBottom

      if(isAtBottom && this.getUnReadMessages.length) {
        this.makeMessagesRead();
      }

      const dataFetchCheck =
        this.getMessages.dataFetched === true && this.shouldLoadMoreChats;
      if (
        e.target.scrollTop < 100 &&
        !this.isLoadingPrevious &&
        dataFetchCheck
      ) {
        this.isLoadingPrevious = true;
        this.$store
          .dispatch('fetchPreviousMessages', {
            conversationId: this.currentChat.id,
            before: this.getMessages.messages[0].id,
          })
          .then(() => {
            const heightDifference =
              this.conversationPanel.scrollHeight - this.heightBeforeLoad;
            this.conversationPanel.scrollTop =
              this.scrollTopBeforeLoad + heightDifference;
            this.isLoadingPrevious = false;
            this.setScrollParams();
          });
      }
    },

    makeMessagesRead() {
      this.$store.dispatch('markMessagesRead', { id: this.currentChat.id });
    },
    removeTweetSelection() {
      this.selectedTweetId = null;
    },
    async setAuditRead() {
      await this.$store.dispatch('setAuditRead', { conversationId: this.currentChat.id });
    },
    getPreviousMessages() {
      this.$store
            .dispatch('fetchPreviousMessages', {
              conversationId: this.currentChat.id,
              before: this.getMessages.messages[0].id,
            })
      
    },
    setScrollToUser() {
      this.scrollToUser = this.searchParam('scrollToUser');
    },
    getFirstMessageIdFromUser(userId) {
      if (!userId) {
        return
      }
      const filteredMessages = this.allConversations.find( conversation => conversation.id === this.currentChat.id).messages
      const firstUserMessage = filteredMessages?.find(message => (message?.sender_type === "User" && message?.sender?.id === parseInt(userId)))
      
      return firstUserMessage?.id
    },
    scrollTop(isLoadingPrevious = this.isLoadingPrevious) {
     
      if (!this.scrollToUser || this.isInFirstMessage) {
        return
      }

      if(!this.currentChat.allMessagesLoaded && !isLoadingPrevious){
          var el = this.$el.querySelector('.conversation-panel');
          el.scrollTop = 0;
        }
    },
    goToFirstUserMessage() {
      if(this.currentChat.allMessagesLoaded && this.scrollToUser && !this.isInFirstMessage) {
        const messageId = this.getFirstMessageIdFromUser(this.scrollToUser)
  
        introJs().setOptions({
            showBullets: false,
            doneLabel:'Entendi',
            steps: [
              {
                title: 'Atenção',
                intro: 'Essa é a primeira mensagem do usuário',
                element: document.getElementById(`message-${messageId}`)
              }
            ]
          }).start()

          this.isInFirstMessage = true
      }
    },
    searchParam(param) {
      const search = new URLSearchParams(location.search)
      const result = search.get(param)
      return result
    }
  }
};
</script>

<style scoped lang="scss">
.project-information {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(#ff8638dd, #952a2add);
  color: #fff;
  font-size: 42px;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 999999;
  padding: 60px;
  transform-origin: top center;
  animation: project-information-hide .5s 4s ease-out forwards;
  flex-direction: column;
  gap: 30px;
  backdrop-filter: blur(2px);
  pointer-events: none;

  &::after {
    content: '';
    display: block;
    font-size: 24px;
    animation: project-information-countdown 4s linear forwards;
    font-weight: bold;
    will-change: opacity;
  }

  &__warning {
    -webkit-text-stroke: 1px #fff;
    color: transparent;
    animation: project-information-warning 2s linear infinite;
    font-weight: bold;
  }

  &__content {
    line-height: 40px;
  }

  &__project {
    font-weight: bold;
    font-size: 80px;
  }
}

@keyframes project-information-countdown {
  0% {
    opacity: 1;
    content: '(3)'
  }

  32% {
    opacity: 0;
    content: '(3)'
  }
  33% {
    opacity: 1;
    content: '(2)'
  }

  65% {
    opacity: 0;
    content: '(2)'
  }
  66% {
    opacity: 1;
    content: '(1)'
  }

  99% {
    opacity: 0;
    content: '(1)'
  }
  100% {
    opacity: 1;
    content: '(0)'
  }
}


@keyframes project-information-warning {
  0%, 100% {
    color: transparent
  }

  50% {
    color: #fff
  }
}

@keyframes project-information-hide {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.go-to-bottom {
  position: absolute;
  top: -53px;
  left: 50%;
  transform: translateX(-50%);
  background: #ff8638;
  padding: 12px 20px;
  border-radius: 100px;
  color: #fff;
  font-size: 12px;
  cursor: pointer;
  opacity: 0.5;
  will-change: opacity;
  transition: opacity .1s linear;

  &:hover {
    opacity: 1;
  }
}

.banner {
  background: var(--b-500);
  color: var(--white);
  font-size: var(--font-size-mini);
  padding: var(--space-slab) var(--space-normal);
  text-align: center;
  position: relative;

  a {
    text-decoration: underline;
    color: var(--white);
    font-size: var(--font-size-mini);
  }

  &.messenger-policy--banner {
    background: var(--r-400);
  }

  .banner-close-button {
    cursor: pointer;
    margin-left: var(--space--two);
    color: var(--white);
  }
}

.spinner--container {
  min-height: var(--space-jumbo);
}

.view-box.fill-height {
  height: auto;
  flex-grow: 1;
  min-width: 0;
  position: relative;
}

.modal-mask {
  &::v-deep {
    .ProseMirror-woot-style {
      max-height: 40rem;
    }

    .reply-box {
      border: 1px solid var(--color-border);
      max-width: 120rem;
      width: 70%;
    }

    .reply-box .reply-box__top {
      position: relative;
      min-height: 44rem;
    }

    .reply-box__top .input {
      min-height: 44rem;
    }

    .emoji-dialog {
      position: fixed;
      left: unset;
      position: absolute;
    }

    .emoji-dialog::before {
      transform: rotate(0deg);
      left: 5px;
      bottom: var(--space-minus-slab);
    }
  }
}
</style>
