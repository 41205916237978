/* global axios */
import ApiClient from '../ApiClient';

class ConversationApi extends ApiClient {
  constructor() {
    super('conversations', { accountScoped: true });
  }

  get({ project, inboxId, status, assigneeType, page, labels, teamId }) {
    return axios.get(this.url, {
      params: {
        project,
        inbox_id: inboxId,
        team_id: teamId,
        status,
        assignee_type: assigneeType,
        page,
        labels,
      },
    });
  }

  search({ q }) {
    return axios.get(`${this.url}/search`, {
      params: {
        q,
        page: 1,
      },
    });
  }

  toggleStatus({ conversationId, status, resolvedType = null, resolutionFormData, isN2 }) {
    return axios.post(`${this.url}/${conversationId}/toggle_status`, {
      status,
      resolved_type: resolvedType,
      resolution_form_data: resolutionFormData,
      is_n2: isN2
    });
  }

  setN2({ conversationId }) {
    return axios.post(`${this.url}/${conversationId}/set_n2`);
  }

  assignAgent({ conversationId, agentId }) {
    return axios.post(
      `${this.url}/${conversationId}/assignments?assignee_id=${agentId}`,
      {}
    );
  }

  assignTeam({ conversationId, teamId }) {
    const params = { team_id: teamId };
    return axios.post(`${this.url}/${conversationId}/assignments`, params);
  }

  markMessageRead({ id }) {
    return axios.post(`${this.url}/${id}/update_last_seen`);
  }

  toggleTyping({ conversationId, status }) {
    // ######## Removed to improve performance of cloudchat ########
    // return axios.post(`${this.url}/${conversationId}/toggle_typing_status`, {
    //   typing_status: status,
    // });
  }

  mute(conversationId) {
    return axios.post(`${this.url}/${conversationId}/mute`);
  }

  unmute(conversationId) {
    return axios.post(`${this.url}/${conversationId}/unmute`);
  }

  meta({ project, status, assigneeType, labels, teamId }) {
    return axios.get(`${this.url}/meta`, {
      params: {
        project,
        status,
        assignee_type: assigneeType,
        labels,
        team_id: teamId,
      },
    });
  }

  sendEmailTranscript({ conversationId, email }) {
    return axios.post(`${this.url}/${conversationId}/transcript`, { email });
  }

  addAuditComment({ conversationId, auditComment: audit_comment }) {
    return axios.post(`${this.url}/${conversationId}/add_audit_comment`, { audit_comment });
  }

  removeAuditComment({ conversationId, auditComment: audit_comment }) {
    return axios.post(`${this.url}/${conversationId}/remove_audit_comment`, { audit_comment });
  }

  addAuditRead({ conversationId}) {
    return axios.post(`${this.url}/${conversationId}/set_audit_read`);
  }

  agentHasUnreadAudit() {
    return axios.get(`${this.url}/agent_has_unread_audit`);
  }

  changeInbox({conversationId, inboxId}) {
    return axios.post(`${this.url}/${conversationId}/change_inbox`, { inbox_id: inboxId });
  }

  getConversationsForAlertBacklog() {
    return axios.get(`${this.url}/get_conversations_for_alert_backlog`);
  }
}

export default new ConversationApi();
