<template>
  <modal :close-on-backdrop-click="false" :show.sync="showModal" :on-close="confirmationRead">
    <woot-modal-header
      header-title="🎉Boas notícias🎉"
      header-content="" />

    <div class="modal-content">
      <p v-for="n in notificationsNotRead">
        <span>
          <div v-if="n.user_level == 0">
            <p>Olá👋, boas-vindas ao Cloud Chat.</p><br />
            <p>Você está no período inicial do projeto <b>{{n.project}}</b> e, por isso, terá um limite
              de tickets a serem respondidos. Ao atingir o limite de tickets a serem respondidos,
              você não conseguirá responder a novos tickets até que tenhamos avaliado a aderência
              das mensagens enviadas nos tickets respondidos. Na sequência, você receberá devolutivas
              com as respectivas oportunidades de melhoria nos tickets respondidos!.
            </p>
            <br />
            <p>Caso tenha dúvidas, não deixe de entrar em contato.</p>
            <br />
            <p>Abraços, <br />Equipe Cloud Humans</p>
          </div>
          <div v-else-if="n.user_level == 1">
            <p>Olá, Pro!</p>
            <br />
            <p>A aderência das mensagens enviadas foram analisada de acordo com às diretrizes esperadas pela empresa
              cliente nos tickets respondidos e você está apto a responder novos tickets.</p>
            <p>Ao atingir novamente o limite de tickets a serem respondidos no período inicial, você não conseguirá
              responder a novos tickets até que tenhamos avaliado a aderência das mensagens enviadas nos tickets mais
              recentes. Caso não seja identificada aderência, a parceria para esse projeto poderá ser suspensa.</p>
            <br />
            <p>Caso tenha dúvidas, não deixe de entrar em contato.</p>
            <br />
            <p>Abraços,<br />Equipe Cloud Humans</p>
          </div>
          <div v-else-if="n.user_level == 2">
            <p>Olá, Pro!</p>
            <br />
            <p>A aderência das mensagens enviadas foram analisada de acordo com às diretrizes esperadas pela empresa
              cliente nos tickets respondidos e você está apto a responder novos tickets.</p>
            <p>Ao atingir novamente o limite de tickets a serem respondidos no período inicial, você não conseguirá
              responder a novos tickets até que tenhamos avaliado a aderência das mensagens enviadas nos tickets mais
              recentes. Caso não seja identificada aderência, a parceria para esse projeto poderá ser suspensa.
            </p>
            <br />
            <p>Caso tenha dúvidas, não deixe de entrar em contato.</p>
            <br />
            <p>Abraços,<br />Equipe Cloud Humans</p>
          </div>
          <div v-else-if='n.user_level == 3 && n.member_status =="pro"'>
            <p>Olá, Pro!</p>
            <br />
            <p>A aderência das mensagens enviadas por você foram avaliadas de acordo com às diretrizes esperadas pela
              empresa nos últimos tickets respondidos e, a partir de agora, você não terá mais um limite de tickets
              a serem respondidos. Apesar disso, como você sabe, aqui na cloud humans, um de nossos compromissos é
              entregar o melhor atendimento possível e, por isso, seguiremos acompanhando oportunidades de melhoria
              nos tickets respondidos e a aderência às diretrizes esperadas pela empresa.
            </p>
            <br />
            <p>Lembramos ainda que você só conseguirá responder a novos tickets caso leia atentamente às
              oportunidades de melhoria destacadas nos tickets por você respondidos.
            </p>
            <br />
            <p>Caso tenha dúvidas, não deixe de entrar em contato.</p>
            <br />
            <p>Abraços,<br />Equipe Cloud Humans</p>
          </div>
          <div v-else>
            <p></p>
          </div>
        </span>
      </p>
      <woot-button
        class="button small button-read"
        @click.prevent="confirmationRead"
      >
         Entendi
      </woot-button>
    </div>
  </modal>
</template>

<script>
import { mapGetters } from 'vuex';
import Modal from '../../Modal';

export default {
  components: {
    Modal,
  },
  props: {
    show: Boolean,
    onClose: Function,
    notificationsNotRead: Array,
  },
  computed: {
    showModal() {
      return this.show;
    },
  },
  methods: {
    handleClose() {
      this.$emit('close-alert-pro-new-when-tickets-renew-modal');
    },
    confirmationRead() {
      this.$store.dispatch('projectMemberRestrictions/setNotificationsRead');
      this.$emit('close-alert-pro-new-when-tickets-renew-modal');
    }
  }
};
</script>

<style lang="scss" scoped>
.button-read {
  float: right;
  position: absolute;
  right: 50px;

  margin-bottom: 10px;
  }

.modal-content {
  margin-bottom: 20px;

  text-align: justify;
  text-justify: inter-word;

  margin-right: 100px;
}
</style>
