<template>
  <div class="blocked">
    <div class="blocked__wrapper">
      <img :src="globalConfig.logo" :alt="globalConfig.installationName" />
      <h1>Atenção</h1>
      
      <slot />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      globalConfig: 'globalConfig/get',
    })
  }
}
</script>

<style lang="scss" scoped>
  .blocked {
    background: #f3f3f3;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: #fff;
      padding: 40px;
      border-radius: 6px;
      box-shadow: 0px 20px 20px -15px rgba(#000, 0.1)
    }

    img {
      width: 200px;
      margin-bottom: 40px;
    }

    h1 {
      font-size: 24px;
      font-weight: bold;
      max-width: 700px;
      text-align: center;
      line-height: 26px;
    }

    p {
      font-size: 1.4rem;
      max-width: 610px;
      text-align: center;
      margin-top: 20px;
    }

    .button {
      margin-top: 20px;
      background: #ff8638;
    }
  }
</style>