<template>
  <woot-modal
    :show="showModal"
    :on-close="closeModal"
    :close-on-backdrop-click="false"
    class="account-selector--modal"
  >
    <div class="column content-box">
      <woot-modal-header
        header-title="Atenção"
        :header-content="subtitle"
      />

      <div class="announcements">
        <div v-if="loading" class="announcements__loader">
          <woot-spinner size="" />
        </div>

        <div v-if="!loading" class="announcements__content">
          <div
            class="announcements__item"
            v-for="announcement in this.announcements_default()"
            :key="announcement.id"
          >
            <b>#{{ announcement.id }}</b> - {{ announcement.content }}
          </div>
        </div>
      </div>

      <div v-if="!loading" class="modal-footer delete-item">
        <button class="alert button nice" @click="closeModal">
          Fechar
        </button>
      </div>
    </div>
  </woot-modal>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      loading: false,
    }
  },
  mounted() {
    this.$store.dispatch('announcements/getUnreads');
  },
  computed: {
    ...mapGetters({
      announcements_unreads: 'announcements/unreads',
    }),

    showModal() {
      return this.announcements_default().length > 0
    },

    subtitle() {
      const { length } = this.announcements_default() || {}
      const singleNotification = length === 1
      const notificationStr = singleNotification ? 'anúncio' : 'anúncios'
      const readStr = singleNotification ? 'lido' : 'lidos'

      return `Você tem ${length} ${notificationStr} não ${readStr}`
    }
  },
  methods: {
    async closeModal() {
      this.loading = true
      await this.$store.dispatch('announcements/markAsRead', this.announcements_default().map(a => a.id));
      this.loading = false
    },
    announcements_default(){
      return this.announcements_unreads.filter(announcement => announcement.announcement_type == 'default' );
    },
  },
};
</script>

<style lang="scss">
  .announcements {
    margin-top: 30px;
    padding: 0 3.2rem;

    &__loader {
      display: flex;
      justify-content: center;
      padding: 0 0 20px;
    }

    &__item {
      font-size: 12px;
      padding: 10px 0;
      border-bottom: 1px solid #e3e3e3;

      &:first-child {
        border-top: 1px solid #e3e3e3;
      }
    }
  }
</style>
