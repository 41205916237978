<template>
  <div class="resolve-actions">
    <div class="button-group">
      <woot-button
        v-if="showN2Button"
        v-tooltip="!!disableResolveButtonReason ? disableResolveButtonReason : null"
        :class-names="!!disableResolveButtonReason ? 'disabled' : 'resolve'"
        color-scheme="secondary"
        :is-loading="isLoading"
        @click="() => openN2Alert()"
        :data-step="`${TICKET_TUTORIAL}-resolve-n2`"
      >
        Enviar para N2
      </woot-button>

      <woot-modal :close-on-backdrop-click="false" :show.sync="showN2ConfirmationPopup" :on-close="closeN2Alert">
        <div class="column content-box">
          <woot-modal-header
            :header-title="'Deseja realmente enviar para N2?'"
            :header-content="'Preencha as informações a baixo para encaminhar o ticket para N2'"
          />
          <resolve-action-form
            :labels="conversationLabels(currentChat.id)"
            :formFields="this.inbox.n2_form"
            :in-progress="false"
            :on-submit="(note, data) => setN2(note, data)"
            :on-cancel="() => closeN2Alert()"
          />
        </div>
      </woot-modal>

      <woot-modal :close-on-backdrop-click="false" :show.sync="showN1ConfirmationPopup" :on-close="closeN1Alert">
        <div class="column content-box">
          <woot-modal-header
            :header-title="'Formulário de fechamento de ticket'"
            :header-content="'Preencha as informações a baixo para fechar o ticket.'"
          />
          <resolve-action-form
            :labels="conversationLabels(currentChat.id)"
            :formFields="this.inbox.n1_form"
            :in-progress="false"
            :on-submit="(note, data) => setN1(note, data)"
            :on-cancel="() => closeN1Alert()"
          />
        </div>
      </woot-modal>

      <woot-button
        v-if="isOpen"
        v-tooltip="!!disableResolveButtonReason ? disableResolveButtonReason : null"
        :class-names="!!disableResolveButtonReason ? 'disabled' : 'resolve'"
        color-scheme="success"
        icon="ion-checkmark"
        emoji="✅"
        :is-loading="isLoading"
        @click="() => resolvedAction('')"
        :data-step="`${TICKET_TUTORIAL}-resolve-n1`"
      >
        {{ this.$t('CONVERSATION.HEADER.RESOLVE_ACTION') }}
      </woot-button>

      <woot-button
        :disabled="false"
        v-else-if="isResolved && !isAdmin"
        class-names="resolve"
        color-scheme="primary"
        icon="ion-checkmark"
        emoji="👀"
        :is-loading="isLoading"
      >
        {{ this.$t('CONVERSATION.HEADER.RESOLVED') }}
      </woot-button>

      <woot-button
        :disabled="!!disableResolveButtonReason"
        v-else-if="isResolved && isAdmin"
        class-names="resolve"
        color-scheme="warning"
        icon="ion-refresh"
        emoji="👀"
        :is-loading="isLoading"
        @click="() => toggleStatus(STATUS_TYPE.OPEN)"
      >
        {{ this.$t('CONVERSATION.HEADER.REOPEN_ACTION') }}
      </woot-button>
      <woot-button
        :disabled="!!disableResolveButtonReason"
        v-else-if="showOpenButton && isAdmin"
        class-names="resolve"
        color-scheme="primary"
        icon="ion-person"
        :is-loading="isLoading"
        @click="() => toggleStatus(STATUS_TYPE.OPEN)"
      >
        {{ this.$t('CONVERSATION.HEADER.OPEN_ACTION') }}
      </woot-button>
    </div>
  </div>
</template>

<script>
import ResolveActionForm from './ResolveActionForm';
import { mapGetters } from 'vuex';
import { mixin as clickaway } from 'vue-clickaway';
import alertMixin from 'shared/mixins/alertMixin';
import adminMixin from '../../mixins/isAdmin';
import eventListenerMixins from 'shared/mixins/eventListenerMixins';
import {
  hasPressedAltAndEKey,
  hasPressedCommandPlusAltAndEKey,
  hasPressedAltAndMKey,
} from 'shared/helpers/KeyboardHelpers';

import WootDropdownItem from 'shared/components/ui/dropdown/DropdownItem.vue';
import WootDropdownSubMenu from 'shared/components/ui/dropdown/DropdownSubMenu.vue';
import WootDropdownMenu from 'shared/components/ui/dropdown/DropdownMenu.vue';
import wootConstants from '../../constants';
import {
  getUnixTime,
  addHours,
  addWeeks,
  startOfTomorrow,
  startOfWeek,
} from 'date-fns';

import { TICKET_TUTORIAL } from '../../store/modules/walktrough/walkthroughs';
import { finalSteps } from '../widgets/conversation/steps/ReplyBoxSteps';

export default {
  components: {
    ResolveActionForm,
    WootDropdownItem,
    WootDropdownMenu,
    WootDropdownSubMenu,
  },
  mixins: [clickaway, alertMixin, eventListenerMixins, adminMixin],
  props: {
    conversationId: { type: [String, Number], required: true }
  },
  data() {
    return {
      isLoading: false,
      resolvedType: null,
      showActionsDropdown: false,
      STATUS_TYPE: wootConstants.STATUS_TYPE,
      showN1ConfirmationPopup: false,
      showN2ConfirmationPopup: false,
      inboxConversation: {},
      resolveTypes: [
        { type: 'conversation_resolved_happy', title: '😀 Feliz' },
        { type: 'conversation_resolved_sad', title: '😞 Triste' },
        { type: 'conversation_resolved_no_response', title: '⌛ Sem resposta' }
      ],
      TICKET_TUTORIAL: TICKET_TUTORIAL,
    };
  },
  computed: {
    ...mapGetters({
      currentChat: 'getSelectedChat',
      conversationLabels: 'conversationLabels/getConversationLabels',
      currentUserID: 'getCurrentUserID',
      accountLabels: 'labels/getLabels',
      memberStatus: 'projectMemberRestrictions/getState',
      userLevel: 'projectMemberRestrictions/getUserLevel',
      flags: 'getAppFlags',
    }),
    inbox() {
      const { inbox_id: inboxId } = this.currentChat;
      const stateInbox = this.$store.getters['inboxes/getInbox'](inboxId);
      this.inboxConversation = stateInbox;
      return stateInbox;
    },
    isSelfAssigned() {
      return this.currentChat?.meta?.assignee?.id === this.currentUserID
    },
    disableResolveButtonReason() {
      const [lastMessageWithouActivity] = this.currentChat.messages.filter(m => m.message_type !== 2).splice(-1)
      const isLastMessageFromClient = lastMessageWithouActivity?.message_type === 0

      return (
        !this.isSelfAssigned ? 'Você deve assinar a conversa para resolver esta conversa' :
        !this.conversationLabels(this.currentChat.id).length ? this.$t('CONVERSATION.HEADER.RESOLVE_DISABLED_REASON') :
        isLastMessageFromClient ? 'Você deve responder o cliente ou adicionar uma nota interna para resolver esta conversa' :
        false
      )
    },
    hasN2Label() {
      const savedLabels = this.conversationLabels(this.currentChat.id).map(l => l.toLowerCase())
      const n2AccountLabels = this.accountLabels.filter(({ is_n2 }) => is_n2)

      return savedLabels.some(l => n2AccountLabels.some(({ title }) => title === l))
    },
    showN2Button() {
      return this.hasN2Label && this.isOpen;
    },
    isOpen() {
      return this.currentChat.status === wootConstants.STATUS_TYPE.OPEN;
    },
    isPending() {
      return this.currentChat.status === wootConstants.STATUS_TYPE.PENDING;
    },
    isResolved() {
      return this.currentChat.status === wootConstants.STATUS_TYPE.RESOLVED;
    },
    isSnoozed() {
      return this.currentChat.status === wootConstants.STATUS_TYPE.SNOOZED;
    },
    buttonClass() {
      if (this.isPending) return 'primary';
      if (this.isOpen) return 'success';
      if (this.isResolved) return 'warning';
      return '';
    },
    showAdditionalActions() {
      return !this.isPending && !this.isSnoozed;
    },
    snoozeTimes() {
      return {
        // tomorrow  = 9AM next day
        tomorrow: getUnixTime(addHours(startOfTomorrow(), 9)),
        // next week = 9AM Monday, next week
        nextWeek: getUnixTime(
          addHours(startOfWeek(addWeeks(new Date(), 1), { weekStartsOn: 1 }), 9)
        ),
      };
    },
  },
  methods: {
    openN2Alert() {
      this.showN2ConfirmationPopup = true
    },
    closeN2Alert() {
      this.showN2ConfirmationPopup = false
    },
    openN1Alert() {
      this.showN1ConfirmationPopup = true
    },
    closeN1Alert() {
      this.showN1ConfirmationPopup = false
    },
    async handleKeyEvents(e) {
      const allConversations = document.querySelectorAll(
        '.conversations-list .conversation'
      );
      if (hasPressedAltAndMKey(e)) {
        if (this.$refs.arrowDownButton) {
          this.$refs.arrowDownButton.$el.click();
        }
      }
      if (hasPressedAltAndEKey(e)) {
        const activeConversation = document.querySelector(
          'div.conversations-list div.conversation.active'
        );
        const activeConversationIndex = [...allConversations].indexOf(
          activeConversation
        );
        const lastConversationIndex = allConversations.length - 1;
        try {
          await this.toggleStatus(wootConstants.STATUS_TYPE.RESOLVED);
        } catch (error) {
          // error
        }
        if (hasPressedCommandPlusAltAndEKey(e)) {
          if (activeConversationIndex < lastConversationIndex) {
            allConversations[activeConversationIndex + 1].click();
          } else if (allConversations.length > 1) {
            allConversations[0].click();
            document.querySelector('.conversations-list').scrollTop = 0;
          }
          e.preventDefault();
        }
      }
    },
    showOpenButton() {
      return this.isResolved || this.isSnoozed;
    },
    closeDropdown() {
      this.showActionsDropdown = false;
    },
    openDropdown() {
      this.showActionsDropdown = true;
    },
    setN2(n2note, data) {
      const conversationId = this.currentChat.id

      this.closeN2Alert()
      this.isLoading = true;
       this.$store.dispatch('sendMessage', { conversationId, private:true, message:n2note }).then(()=>{
        this.toggleStatus(wootConstants.STATUS_TYPE.RESOLVED, conversationId, data, true).then(()=>{
                this.showAlert("Conversa enviada para N2");
                this.isLoading = false;
          })
       })
    },
    setN1(note, data) {
      const conversationId = this.currentChat.id

      this.closeN1Alert()
      this.isLoading = true;

      this.$store.dispatch('sendMessage', {conversationId, private: true, message: note})
        .then(() => {
          this.toggleStatus(wootConstants.STATUS_TYPE.RESOLVED, conversationId, data);
          this.isLoading = false;
        });
    },
    resolvedAction(resolvedType) {
      this.resolvedType = resolvedType

      if (this.inboxConversation.n1_form.length > 0) {
        this.openN1Alert()
      } else {
        this.toggleStatus(wootConstants.STATUS_TYPE.RESOLVED)
        this.initFinalTutorial()
      }
    },
    toggleStatus(status, conversationId, resolutionFormData, isN2 = false) {
      conversationId = conversationId || this.currentChat.id

      this.closeDropdown();
      this.isLoading = true;
      this.$store
        .dispatch('toggleStatus', {
          conversationId,
          status,
          resolvedType: this.resolvedType,
          resolutionFormData,
          isN2,
        })
        .then((response) => {
          this.isLoading = false;
          if (response[0] == false){
              this.showAlert(response[1]);
            }else{
              this.showAlert(this.$t('CONVERSATION.CHANGE_STATUS'));
            }
        });
    },
    initFinalTutorial() {
      finalSteps(this.currentChat.id, this.memberStatus, this.userLevel, this.flags);
    },
  }
};
</script>
<style lang="scss" scoped>
.resolve-actions {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .disabled:active {
    pointer-events: none;
  }
}

.dropdown-pane {
  left: unset;
  top: 4.2rem;
  margin-top: var(--space-micro);
  right: 0;
  max-width: 20rem;
}
</style>
