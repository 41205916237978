import introJs from 'intro.js'
import { Step } from '../../../../store/modules/walktrough/walkthroughs'
import { getItem, setItem } from '../../../../helper/localStorageHelper'

export const TICKET_TUTORIAL = 'TICKET_TUTORIAL'

const stepGuard = (memberStatus, userLevel, project, flags) => {
  const hasLevelToSee = memberStatus === 'trainee' && userLevel === 0
  const hasFlag = !!flags.tutorial_ticket.projects.includes(project)
  
  return !hasLevelToSee || !hasFlag
}

const startStep = (step, ticketId, memberStatus, userLevel, project, flags, callback) => {
  const checkpoints = getItem('walkthrough-checkpoints', {})
  const checkpointsStep = checkpoints[step] || {}

  if(stepGuard(memberStatus, userLevel, project, flags)) return null
  
  if(!checkpointsStep || !checkpointsStep[ticketId]) {
    setItem('walkthrough-checkpoints', {
      ...checkpoints,
      [step]: {
        ...checkpointsStep,
        [ticketId]: true
      } 
    })

    return callback()
  }
}

export const assignSteps = (ticketId, memberStatus, userLevel, project, flags) => startStep('assign', ticketId, memberStatus, userLevel, project, flags, () => {
  return introJs().addStep(
    Step({
      step_id: "Clique aqui para atribuir um ticket!",
      step_desc: 'Após a atribuição você poderá responder.',
      target: `${TICKET_TUTORIAL}-assign_button`
    })
  ).start()
})

export const channelSteps = (ticketId, memberStatus, userLevel, project, flags) => startStep('channel', ticketId, memberStatus, userLevel, project, flags, () => {
  return introJs().addSteps([
    Step({
      step_id: "Tipo de canal",
      step_desc:"O canal é o meio que o cliente usa para falar com o supporte (você)",
      target: `${TICKET_TUTORIAL}-channel`
    }),
    Step({
      step_id: "Canais de CHAT",
      step_desc:"Se o canal for CHAT o usuário estará usando algo como o Whatssap para se comunicar com você",
      target: `${TICKET_TUTORIAL}-channel`
    }),
    Step({
      step_id: "Canais de CHAT",
      step_desc:"Canais assim se caracterizam pela dinamicidade, tente ultilizar mensagens curtas e diretas quebrando as macros quando possivel ;)",
      target: `${TICKET_TUTORIAL}-channel`
    }),
    Step({
      step_id: "Canais de EMAIL",
      step_desc:"Se o canal for EMAIL o usuário estará usando algo como o Gmail, Hotmail ou Outlook para se comunicar com você",
      target: `${TICKET_TUTORIAL}-channel`
    }),
    Step({
      step_id: "Canais de EMAIL",
      step_desc:"Canais assim se caracterizam pela dinâmica mais lenta, tente se possivel enviar todas as informações que o cliente precisa em 1 mensagem, se necessário, não exite em combinar macros",
      target: `${TICKET_TUTORIAL}-channel`
    })
  ]).start()
})

export const openDetailsSteps = (ticketId, memberStatus, userLevel, project, flags) => startStep('open-details', ticketId, memberStatus, userLevel, project, flags, () => {
  return introJs().addSteps([
    Step({
      step_id: "Aperte aqui",
      step_desc:"para abrir/fechar os detalhes da conversa",
      target: `${TICKET_TUTORIAL}-detail_button`
    })
  ]).start()
})

export const detailsSteps = (ticketId, memberStatus, userLevel, project, flags) => startStep('details', ticketId, memberStatus, userLevel, project, flags, () => {
  return introJs().addSteps([
    Step({
      step_id: "Nome",
      step_desc:"Este é o nome que o cliente registrou",
      target: `${TICKET_TUTORIAL}-contact-name`
    }),
    Step({
      step_id: "Email",
      step_desc:"Este é o email que o cliente registrou",
      target: `${TICKET_TUTORIAL}-contact-email`
    }),
    Step({
      step_id: "Telefone",
      step_desc:"Este é o número de celular que o cliente registrou",
      target: `${TICKET_TUTORIAL}-contact-phone`
    }),
    Step({
      step_id: "Dica!",
      step_desc:"Você pode clicar nesse botão para copiar o conteudo ;)",
      target: `${TICKET_TUTORIAL}-contact-copy`
    })
  ]).start()
})

export const greetingSteps = (ticketId, memberStatus, userLevel, project, flags) => startStep('greeting', ticketId, memberStatus, userLevel, project, flags, () => {
  return introJs().addSteps([
    Step({
      step_id: "Saudação",
      step_desc:"Geralmente começamos o atendimento com uma saudação, lembre-se de conferir na Base de Conhecimento se este é o caso e qual saudação usar. \n\n Dá uma olhada na seção \"Conteúdo | Macros de Saudação\" ",
      target: `${TICKET_TUTORIAL}-kb-button`
    }),
    Step({
      step_id: "Macro",
      step_desc:"Pesquise sobre o tema nas seções e depois que decidir qual ação tomar comece pegando a macro que será usada",
      target: `${TICKET_TUTORIAL}-kb-button`
    }),
    Step({
      step_id: "Macro",
      step_desc:"Copie da base e cole na caixa de Texto \n\n Lembre-se de adaptar a macro e de quebrar ela em pedaços menores ;)",
      target: `${TICKET_TUTORIAL}-kb-button`
    }),
    Step({
      step_id: "Guia essencial",
      step_desc:"Para dicas essenciais confira a seção <b>Guia Essencial</b>",
      target: `${TICKET_TUTORIAL}-kb-button`
    }),
    Step({
      step_id: "Base de Conhecimento",
      step_desc:"Você pode clicar nesse atalho sempre que precisar ter uma visualização rápida da Base de conhecmineto",
      target: `${TICKET_TUTORIAL}-kb-button`
    }),
    Step({
      step_id: "Perguntas Frequentes",
      step_desc:"Utilize este botão para acessar as dúvidas mais frequentes",
      target: "faq-modal-button"
    })
  ]).start()
})

export const historySteps = (ticketId, memberStatus, userLevel, project, flags) => startStep('history', ticketId, memberStatus, userLevel, project, flags, () => {
  return introJs().addSteps([
    Step({
      step_id: "Histórico",
      step_desc:"Esse aqui é o histórico de atendimento do ticket, estará listado as outras vezes que esse cliente foi atendido",
      target: `${TICKET_TUTORIAL}-history`
    }),
    Step({
      step_id: "Histórico",
      step_desc:"Use-o para conseguir mais contexto do ticket e escolher a forma como você vai lidar com ele :D",
      target: `${TICKET_TUTORIAL}-history`
    })
  ]).start()
})

export const supportSteps = (ticketId, memberStatus, userLevel, project, flags) => startStep('support', ticketId, memberStatus, userLevel, project, flags, () => {
  return introJs().addSteps([
    Step({
      step_id: "Atendimento",
      step_desc:"Agora que começamos o atendimento, vamos identificar o problema do cliente e após identificado, podemos achar a macro correspondente na - Introdução Conteúdo e Conteúdos N1/N2 "
    }),
    Step({
      step_id: "Atendimento",
      step_desc:"Lembre-se de voltar a aba minha, seu ticket ficará aqui e você poderá acompanhar o andamento dele :D",
      target: "FIRST_LOGIN_TUTORIAL-tab_chat-me"
    })
  ]).start()
})

export const resolveSteps = (_, memberStatus, userLevel, project, flags) => {
  if(stepGuard(memberStatus, userLevel, project, flags)) return null

  return introJs().addSteps([
    Step({
      step_id: "Encerramento",
      step_desc:"Agora que você resolveu os problemas do cliente está na hora de começar o encerramento"
    }),
    Step({
      step_id: "Macro de Encerramento",
      step_desc:"Verifique na base a Macro de encerramento adequada para o seu canal e mais apropiada para o contexto do seu ticket, veja na seção - Conteúdo | Macros de Encerramento",
      target: `${TICKET_TUTORIAL}-kb-button`
    }),
    Step({
      step_id: "Tags / marcador",
      step_desc:"Com o ticket solucionado, é necessário adicionar uma tag que vai indicar do que se trata esse ticket",
      target: `${TICKET_TUTORIAL}-resolve-label`
    }),
    Step({
      step_id: "Tags / marcador",
      step_desc:"Você pode alterar a tag a qualquer momento durante o atendimento do ticket caso queira",
      target: `${TICKET_TUTORIAL}-resolve-label`
    }),
  ]).start()
}

export const resolveN2Steps = (_, memberStatus, userLevel, project, flags) => {
  if(stepGuard(memberStatus, userLevel, project, flags)) return null

  return introJs().addSteps([
    Step({
      step_id: "N2",
      step_desc:"Ops! Identificou que seu ticket se trata de uma tag N2?"
    }),
    Step({
      step_id: "Ticket N2",
      step_desc:"Procure na Base de Conhecimento a seção - Conteúdo | Como passar um ticket para N2 \n\n Para entender como enviar um ticket a N2 no seu projeto",
      target: `${TICKET_TUTORIAL}-kb-button`
    }),
    Step({
      step_id: "Ticket N2",
      step_desc:"Lembre-se de clicar em Enviar para N2 para escalar o ticket após finalizar o atendimento!",
      target: `${TICKET_TUTORIAL}-resolve-n2`
    })
  ]).start()
}

export const resolveN1Steps = (_, memberStatus, userLevel, project, flags) => {
  if(stepGuard(memberStatus, userLevel, project, flags)) return null

  return introJs().addSteps([
    Step({
      step_id: "Ticket N1",
      step_desc:"No caso de ser N1, já pode clicar no botão de resolver o seu Ticket :D",
      target: `${TICKET_TUTORIAL}-resolve-n1`
    })
  ]).start()
}

export const finalSteps = (ticketId, memberStatus, userLevel, flags) => startStep('final', ticketId, memberStatus, userLevel, flags, () => {
  return introJs().addSteps([
    Step({
      step_id: "Parabéns :D",
      step_desc:"Parabéns você atendeu seu primerio de muitos tickets, continue melhorando :D"
    })
  ]).start()
})
