import AuthAPI from '../api/auth';
import BaseActionCableConnector from '../../shared/helpers/BaseActionCableConnector';
import { newMessageNotification } from 'shared/helpers/AudioNotificationHelper';
import { differenceInSeconds } from 'date-fns';

const toMilliseconds = (x) => x * 1000

class ActionCableConnector extends BaseActionCableConnector {
  constructor(app, pubsubToken) {
    const { websocketURL = '' } = window.chatwootConfig || {};
    super(app, pubsubToken, websocketURL);
    this.CancelTyping = [];
    this.AlertTimer = [];

    this.events = {
      'message.created': this.onMessageCreated,
      'message.updated': this.onMessageUpdated,
      'conversation.created': this.onConversationCreated,
      'conversation.status_changed': this.onStatusChange,
      'user:logout': this.onLogout,
      'page:reload': this.onReload,
      'assignee.changed': this.onAssigneeChanged,
      'conversation.typing_on': this.onTypingOn,
      'conversation.typing_off': this.onTypingOff,
      'conversation.contact_changed': this.onConversationContactChange,
      'presence.update': this.onPresenceUpdate,
      'contact.deleted': this.onContactDelete,
      'user.soft_blocked': this.onSoftBlocked,
      'user.unblocked': this.onUnblocked,
      'conversation.inbox_changed': this.onInboxChanged
    };

    window.addEventListener('offline', () => this.onConnectionOffline());
  }

  onConnectionOffline() {
    this.disconnect()
    this.app.$store.dispatch('internetConnectionOffline')
  }

  onSoftBlocked = data => {
    const currentUser = this.app.$store.getters.getCurrentUser

    if (currentUser.id === data.user.id) {
      this.app.$store.dispatch('validityCheck');
    }
  };

  onUnblocked = data => {
    const currentUser = this.app.$store.getters.getCurrentUser

    if (currentUser.id === data.user.id) {
      this.app.$store.dispatch('validityCheck');
    }
  };

  isAValidEvent = data => {
    return this.app.$store.getters.getCurrentAccountId === data.account_id;
  };

  onMessageUpdated = data => {
    this.app.$store.dispatch('updateMessage', data);
  };

  onPresenceUpdate = data => {
    this.app.$store.dispatch('contacts/updatePresence', data.contacts);
    this.app.$store.dispatch('agents/updatePresence', data.users);
    this.app.$store.dispatch('setCurrentUserAvailabilityStatus', data.users);
  };

  onConversationContactChange = payload => {
    const { meta = {}, id: conversationId } = payload;
    const { sender } = meta || {};
    if (conversationId) {
      this.app.$store.dispatch('updateConversationContact', {
        conversationId,
        ...sender,
      });
    }
  };

  onAssigneeChanged = conversation => {
    const { id } = conversation;
    const conversationInbox = this.app.$store.getters['inboxes/getInbox'](conversation.inbox_id);

    if (id) {
      this.app.$store.dispatch('updateConversation', { conversation, conversationInbox });
      
      const openConversation = this.convertToOpenConversation(conversation, conversationInbox)
      this.app.$store.dispatch('updateOpenConversation', { openConversation, conversationInbox });
    }

    this.fetchConversationStats();
    this.clearAlertTimer(id)
    this.notifyAvailableConversationMessage(conversation, conversationInbox);
  };

  onInboxChanged = conversation => {
    const { id } = conversation;
    const conversationInbox = this.app.$store.getters['inboxes/getInbox'](conversation.inbox_id);

    if (id) {
      this.app.$store.dispatch('updateConversation', { conversation, conversationInbox });
    }

    this.fetchConversationStats();
    this.notifyAvailableConversationMessage(conversation, conversationInbox);
  };

  onConversationCreated = conversation => {
    const conversationInbox = this.app.$store.getters['inboxes/getInbox'](conversation.inbox_id);
    const openConversation = this.convertToOpenConversation(conversation, conversationInbox)

    this.app.$store.dispatch('updateOpenConversation', { openConversation, conversationInbox });
    this.app.$store.dispatch('addConversation', { conversation: { ...conversation, isSocket: true }, conversationInbox });
    this.fetchConversationStats();
    this.notifyAvailableConversationMessage(conversation, conversationInbox);
  };

  onLogout = () => AuthAPI.logout();

  onMessageCreated = data => {
    newMessageNotification(data);
    this.app.$store.dispatch('addMessage', data);
  };

  onReload = () => window.location.reload();

  onStatusChange = conversation => {
    const conversationInbox = this.app.$store.getters['inboxes/getInbox'](conversation.inbox_id);
    const openConversation = this.convertToOpenConversation(conversation, conversationInbox)

    this.app.$store.dispatch('updateConversation', { conversation, conversationInbox });
    this.app.$store.dispatch('updateOpenConversation', { openConversation, conversationInbox });

    this.fetchConversationStats();
  };

  onTypingOn = ({ conversation, user }) => {
    const conversationId = conversation.id;

    this.clearTimer(conversationId);
    this.app.$store.dispatch('conversationTypingStatus/create', {
      conversationId,
      user,
    });
    this.initTimer({ conversation, user });
  };

  onTypingOff = ({ conversation, user }) => {
    const conversationId = conversation.id;

    this.clearTimer(conversationId);
    this.app.$store.dispatch('conversationTypingStatus/destroy', {
      conversationId,
      user,
    });
  };

  clearTimer = conversationId => {
    const timerEvent = this.CancelTyping[conversationId];

    if (timerEvent) {
      clearTimeout(timerEvent);
      this.CancelTyping[conversationId] = null;
    }
  };

  clearAlertTimer = conversationId => {
    const timerEvent = this.AlertTimer[conversationId];

    if (timerEvent) {
      clearTimeout(timerEvent);
      this.CancelTyping[conversationId] = null;
    }
  };

  initTimer = ({ conversation, user }) => {
    const conversationId = conversation.id;
    // Turn off typing automatically after 30 seconds
    this.CancelTyping[conversationId] = setTimeout(() => {
      this.onTypingOff({ conversation, user });
    }, 30000);
  };

  fetchConversationStats = () => {
    bus.$emit('fetch_conversation_stats');
  };

  onContactDelete = data => {
    this.app.$store.dispatch(
      'contacts/deleteContactThroughConversations',
      data.id
    );
    this.fetchConversationStats();
  };

  isflagTicketDelayEarlyOn(conversationInbox) {
    const currentUser = this.app.$store.getters.getCurrentUser
    const flag = this.app.$store.getters.getAppFlags.ticket_delay_early[conversationInbox.project]

    if (!flag || !flag.emails) return false

    return flag.emails.includes(currentUser.email)
  }

  ticketDelayEarlyTime(conversationInbox) {
    const flag = this.app.$store.getters.getAppFlags.ticket_delay_early[conversationInbox.project]

    if (!flag) return 0

    return flag.delay
  }

  isNewTicket(conversation, conversationInbox) {
    if (!conversation.created_at) return true

    const createdAt = new Date(toMilliseconds(conversation.created_at))
    const now = new Date()
    const timeDiffInSeconds = differenceInSeconds(now.getTime(), createdAt.getTime())

    return timeDiffInSeconds <= this.ticketDelayEarlyTime(conversationInbox)
  }

  shallAddDelay(conversation, conversationInbox) {
    return !this.isflagTicketDelayEarlyOn(conversationInbox) && this.isNewTicket(conversation, conversationInbox)
  }

  notifyAvailableConversationMessage(conversation, conversationInbox) {
    if (!conversation.meta.assignee && conversation.status == "open") {
      if (this.shallAddDelay(conversation, conversationInbox)) {
        this.AlertTimer[conversation.id] = setTimeout(() => {
          this.app.$store.dispatch('newAvailableConversation', conversationInbox.project);
        }, toMilliseconds(this.ticketDelayEarlyTime(conversationInbox)))
      } else {
        this.app.$store.dispatch('newAvailableConversation', conversationInbox.project);
      }
    }
  }

  convertToOpenConversation(conversation, conversationInbox) {
    return {
      id: conversation.id,
      inbox_id: conversation.inbox_id,
      assignee_id: conversation?.meta?.assignee?.id,
      project: conversationInbox.project,
      created_at: conversation?.created_at,
      assignee_last_seen_at: conversation?.assignee_last_seen_at
    }
  }
}


export default {
  init() {
    if (AuthAPI.isLoggedIn()) {
      const actionCable = new ActionCableConnector(
        window.WOOT,
        AuthAPI.getPubSubToken()
      );
      return actionCable;
    }
    return null;
  },
};
