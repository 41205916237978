import { step, buildIntroJS } from "./walkthroughs"
import { ADD_STEP, ADD_STEPS, ADD_WALKTHROUGH, SET_STATE, SET_WALKTHROUGH } from "./type"

export const actions = {
    addWalktrhough: (
        {commit, state},  
    {
        name, 
        step_id, 
        step_desc,
        target,
        steps,
    } 
    ) => {

        if (state.walkthroughs.get(name)) return false;

        const setps_queue = steps? steps 
        : [{title: step_id, element: target, intro: step_desc}]

        const walkthrough = {
            setps_queue,
            state: {
                it: buildIntroJS(steps=setps_queue),
                is_over: false,
                last_see: step_id
            }
        }

        commit(ADD_WALKTHROUGH, {name, walkthrough})
    },

    addStep: ({commit}, {name, title, intro, element}) => {
        commit(ADD_STEP, {name, step: step(title, intro, element)})
    },

    addSteps: ({commit}, {name, steps}) => {
        commit(ADD_STEPS, {name, steps})
    },

    setWalkthrough: ({commit}, {name, steps, is_over, last_see}) => {
        const save = {
            setps_queue: steps,
            states: {
                is_over,
                last_see
            }
        }

        commit(SET_WALKTHROUGH, {name, save})
    },

    setState: ({commit, state}, {name, last_see, is_over}) => {
        const data = state.walkthroughs.get(name).state
        const save = {
            last_see: last_see? last_see : data.last_see,
            is_over: is_over === undefined? data.is_over : is_over,
        }
        commit(SET_STATE, save)
    }
}