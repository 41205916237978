<template>
    <div v-if="isAddLabel" class="label-select">
        <woot-label
            v-if="selectedLabel"
            :title="selectedLabel.title"
            :description="selectedLabel.description"
            :show-close="true"
            :bg-color="selectedLabel.color"
            @click="onRemoveLabel"
        />
        <label-dropdown
            v-else
            :account-labels="inboxLabels"
            @add="onAddLabel"
        />
    </div>
    <select v-else @change="onSelect()" v-model="selectedFeedbackCategory">
        <option value="" hidden>
            Selecione uma categoria de Feedback 
        </option>
        <option v-for="category in feedbackCategories" :key="category.id" :value="category" >
            {{category.category}}
        </option>
    </select>
</template>
<script>

import {mapGetters} from "vuex"
import feedbackCategories from "../../../api/feedbackCategories"
import LabelDropdown from 'shared/components/ui/label/LabelDropdown';

export default {
    components: {
        LabelDropdown
    },
    data() {
        return{
            selectedFeedbackCategory: "",
            feedbackCategories: [],
            selectedLabel: null
        }
    },

    props: {
        isAddLabel: Boolean,
        comment: Object
    },

    async mounted() {
       const {data:categories} = await feedbackCategories.get()
       this.feedbackCategories = categories
       this.selectedLabel = this.comment?.metadata?.label
    },

    methods: {
        onSelect() {
            const metadata = {
                categoryId: this.selectedFeedbackCategory.id
            }
            this.$emit("feedback-category-selected", {content: this.selectedFeedbackCategory.content, metadata: metadata})
        },
        onAddLabel(value) {
            this.selectedLabel = value
            const text = `📍 Utilização da tag incorreta para o atendimento, neste caso a tag correta seria: ${value.title}`
            const metadata = {
                label: value
            }
            this.$emit("feedback-category-selected",  {content: text, metadata: metadata})
        },
        onRemoveLabel() {
            this.selectedLabel = null
        }
    },

    computed: {
        ...mapGetters({
            flags: "getAppFlags",
            accountLabels: 'labels/getLabels',
            getInbox: 'inboxes/getInbox',
            currentChat: 'getSelectedChat',
        }),
        // TODO: Colocar essa função numa store adequada
        inboxLabels() {
            const inbox = this.getInbox(this.currentChat.inbox_id);

            const globalLabels = this.accountLabels.filter(
                al => al.kind === 'global'
            );

            const companyLabels = this.accountLabels
                .filter(al => al.kind === 'company')
                .filter(al => al.identifier === inbox.company);

            const projectLabels = this.accountLabels
                .filter(al => al.kind === 'project')
                .filter(al => al.identifier === inbox.project);

            const inboxLabels = this.accountLabels
                .filter(al => al.kind === 'inbox')
                .filter(al => al.identifier === inbox.name.toLowerCase());

            return [
                ...globalLabels,
                ...companyLabels,
                ...projectLabels,
                ...inboxLabels,
            ].filter((label, key, self) => self.map(l => l.title).indexOf(label.title) === key);
        }
    }
}
</script>
<style lang="scss" scoped>
    .label-select {
        margin-bottom: 20px;
    }
</style>