import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import * as types from '../mutation-types';
import AnnouncementsAPI from '../../api/announcements';

export const state = {
  records: [],
};

export const getters = {
  unreads($state) {
    return $state.records;
  },
};


export const actions = {
  getUnreads: async ({ commit }) => {
    try {
      const { data: payload } = await AnnouncementsAPI.getUnreads();
      commit(types.default.SET_UNREAD_ANNOUNCEMENTS, payload);
    } catch (error) {
      console.log(error)
      // silent
    }
  },

  markAsRead: async ({ commit }, ids) => {
    try {
      await AnnouncementsAPI.read(ids);
      commit(types.default.SET_UNREAD_ANNOUNCEMENTS, []);
    } catch (error) {
      console.log(error)
      // silent
    }
  },
};

export const mutations = {
  [types.default.SET_UNREAD_ANNOUNCEMENTS]: MutationHelpers.set,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
