import { actions } from "./actions"
import { getters } from "./getters"
import { mutations } from "./mutations"

const state = {
    walkthroughs: new Map([
        [
            "example", //primary_key
            {
                setps_queue: [
                    {
                        title: "", //primary_key
                        intro:"desc",
                        element: "identifier"
                    }
                ],
                state:{
                    it: null,
                    is_over: false,
                    last_see:"step.title"
                }
        }]
    ])
} 

export default {
    state,
    actions,
    getters,
    mutations,
}