<template>
    <span>
        <button
        class="button"
        @click="openModal()"
        data-step="faq-modal-button"
        >
            FAQ
        </button>
    
        <modal :show.sync="show" :on-close="handleClose">
            <woot-modal-header :header-title="'FAQ'"  />
            <iframe :src="faqLink" width="100%" height="600px" style="border:none"/>
        </modal>
    </span>
</template>

<script>
import {mapGetters} from 'vuex'
import Modal from '../../Modal'

export default{
    components: {
        Modal,
    },
    data(){
        return {
            show: false
        }
    },
    computed:{
        faqLink(){
            return `https://pro.cloudhumans.com/faq?hideSidebar=true`
        },
    },
    methods:{

        handleClose(){
            this.show = false
        },
        openModal(){
            this.show = true
        }
    }
}

</script>

<style lang="'scss" scoped>
</style>
