import * as LDClient from 'launchdarkly-js-client-sdk'
import { SET_CONNECTION, SET_FLAGS, SET_LD_INITIALIZED } from './type'

export const actions = {
  syncFlags: ({ commit, state }, currentUser) => {
    const { flagsClientKey } = window.chatwootConfig

    const user = currentUser ? {
      name: currentUser.name,
      email: currentUser.email,
      key: `pro-key_${currentUser.email}`,
      custom: {
        status: currentUser.status,
        projects: currentUser.projects
      }
    } : {
      kind: 'user',
      anonymous: true
    }

    const ldClient = state.connection || LDClient.initialize(flagsClientKey, user)
    commit(SET_CONNECTION, ldClient);

    if(ldClient) {
      ldClient.identify(user)
    }

    const sync = () => {
      !state.initialized && commit(SET_LD_INITIALIZED)
      
      commit(SET_FLAGS, {
        ...state.flags,
        ...ldClient.allFlags()
      })
    }

    ldClient.on('ready', sync)
    ldClient.on('change', sync)
  }
}
