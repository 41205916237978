import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import types from '../mutation-types';
import prmAPI from '../../api/projectMemberRestrictions';
import inboxAPI from '../../api/inboxes';
import store from '../index'

export const state = {
  memberStatus: null,
  userId: null,
  ticketsRemaining: null,
  projectMemberRestrictionInboxId: null,
  userLevel: null,
  notifications: [],
};

export const getters = {
  getState(_state) {
    return _state.memberStatus;
  },
  getUserLevel(_state) {
    return _state.userLevel;
  },
  getTicketsRemanding(_state) {
    return _state.ticketsRemaining;
  },
  isAbleToAssign(_state) {
    switch (_state.memberStatus) {
      case 'restricted_pro':
        return false;
        break;

      case 'pro':
        return true;
        break;

      case 'trainee':
        return _state.ticketsRemaining > 0 && _state.ticketsRemaining != null
        break;
    }
  },
  isPro(_state) {
    if (_state.memberStatus == "pro") {
      return true;
    } else {
      return false;
    }
  },
  getProjectMemberRestrictionInboxId(_state) {
    return _state.projectMemberRestrictionInboxId;
  },
  getWarningRestrictedProMessage(_state) {
    if (_state.memberStatus == "trainee" || _state.memberStatus == "awaiting_cycle" || _state.memberStatus == "cycled") {

      if (!store.getters.getAppFlags.automated_actions_in_trainees || _state.userLevel == null) {
        return "Você atingiu o limite de tickets do período de testes, aguarde até que a checagem dos tickets seja feita e você possa pegar mais tickets. <br /> Se precisar de ajuda procure no discord.";
      } else {
        return "Você atingiu o limite de tickets a serem inicialmente respondidos na operação! Nesse momento, a aderência das mensagens enviadas às diretrizes esperadas pela empresa cliente estão sendo analisadas. Assim que possível, você receberá devolutivas com as respectivas oportunidades de melhoria nos tickets respondidos!<br />Lembrando que você só conseguirá responder a novos tickets caso leia atentamente às oportunidades de melhoria destacadas nos tickets respondidos.<br />Caso tenha dúvidas, não deixe de entrar em contato."
      }


    }
    else {
      return "<p>Você está temporariamente bloqueado pela métrica de Problem Solved ou Pro Love. Para ser desbloqueado é necessário comparecer a devolutiva marcada diariamente (de segunda a sexta), das 10h:00 às 10h:15, pelo link abaixo: </p><p><a href='https://meet.google.com/hsz-jwwm-eeg' target='_blank'> https://meet.google.com/hsz-jwwm-eeg</a></p><p>Caso você não compareça em uma sessão nos próximos 7 dias, entendemos quem você não tem mais interesse em participar do projeto.</p>"
    }
  },
  getNotifications(_state) {
    return _state.notifications
  },
};

export const actions = {
  async get({ commit }, { userId, inboxId }) {
    try {
      const inbox = await inboxAPI.show(inboxId)
      const data = await prmAPI.getRestriction(userId, inbox.data.project).then(response => {
        response.data.inbox_id = inboxId;
        return response;
      });
      commit('refreshRestrictions', data.data)
    } catch (error) {
      // Log your error
    }
  },
  async setNotifications({ commit }) {
    const data = await prmAPI.getNotifications()
    commit('refreshNotifications', data.data)
    return data
  },
  async setNotificationsRead({ commit }) {
    await prmAPI.setNotificationsRead()
    commit('refreshNotifications', [])
  },
};

export const mutations = {
  refreshRestrictions(_state, data) {
    _state.memberStatus = data.member_status;
    _state.userId = data.user_id;
    _state.ticketsRemaining = data.tickets_remaining;
    _state.projectMemberRestrictionInboxId = data.inbox_id
    _state.userLevel = data.user_level;
  },
  refreshNotifications(_state, data) {
    _state.notifications = data;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
