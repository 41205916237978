/* eslint no-console: 0 */
/* eslint no-param-reassign: 0 */
import * as types from '../../mutation-types';
import getters from './getters';
import actions from './actions';

const state = {
  data: null,
  loading: false,
  visible: false
};

// mutations
export const mutations = {
  [types.default.SET_SUGGESTION_LOADING_STATUS](_state, status) {
    _state.loading = status;
  },
  [types.default.SET_SUGGESTION_VISIBLE](_state, value) {
    _state.visible = value
  },
  [types.default.SET_SUGGESTION_DATA](_state, data) {
    _state.data = data
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};
