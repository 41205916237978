<template>
  <div class="conversations-list-wrap">
    <slot></slot>
    <div class="chat-list__top">
      <h1 v-if="showPageTitle" class="page-title text-truncate" :title="pageTitle">
        {{ pageTitle.replace(/.*__/, '') }}
      </h1>
      <chat-filter v-if="shouldShowChatFilter" @statusFilterChange="updateStatusType" />
    </div>

    <chat-type-tabs
      :data-step="`${TUTORIAL_FIRST_LOGIN}-tab_chat`"
      :items="assigneeTabItems"
      :active-tab="activeAssigneeTab"
      class="tab--chat-type"
      @chatTabChange="updateAssigneeTab"
    />

    <p v-if="!chatListLoading && !conversationList.length" class="content-box">
      {{ $t('CHAT_LIST.LIST.404') }}
    </p>

    <div ref="activeConversation" class="conversations-list" :data-step="`${TUTORIAL_FIRST_LOGIN}-chat-list`">
      <conversation-card
        v-for="chat in conversationList"
        :key="chat.id"
        :active-label="label"
        :team-id="teamId"
        :chat="chat"
        :show-assignee="showAssigneeInConversationCard"
        :its-my-tab="itsMyTab"
      />

      <div v-if="chatListLoading" class="text-center">
        <span class="spinner"></span>
      </div>

      <woot-button
        v-if="!hasCurrentPageEndReached && !chatListLoading"
        variant="clear"
        size="expanded"
        @click="fetchConversations"
      >
        {{ $t('CHAT_LIST.LOAD_MORE_CONVERSATIONS') }}
      </woot-button>

      <p
        v-if="
          conversationList.length &&
            hasCurrentPageEndReached &&
            !chatListLoading
        "
        class="text-center text-muted end-of-list-text"
      >
        {{ $t('CHAT_LIST.EOF') }}
      </p>
    </div>
    <div class="knowledge-base-preview" v-if="showKnowledgeBaseButton">
      <knowledge-base-modal
        :show="isKnowledgeBaseModalVisible"
        :project-slug="currentProjectSlug"
        :hyperlink="currentProjectLink"
        @on-close="hideKnowledgeBaseModal"
      />

      <woot-button :data-step="`${TUTORIAL_TICKET}-kb-button`" @click="showKnowledgeBaseModal()">Base de Conhecimento</woot-button>
    </div>
    <div class="faq-modal"><faq-modal></faq-modal></div>

  </div>
</template>

<script>
import { frontendURL, accountIdFromPathname } from '../helper/URLHelper';
import { mapGetters } from 'vuex';

import {
   FIRST_LOGIN_TUTORIAL,
   CLOUDCHAT_OVERVIEW,
   TICKET_TUTORIAL,
   PRE_TICKET_TUTORIAL,
   Walkthrough,
   Step
} from '../store/modules/walktrough/walkthroughs';

import ChatFilter from './widgets/conversation/ChatFilter';
import ChatTypeTabs from './widgets/ChatTypeTabs';
import ConversationCard from './widgets/conversation/ConversationCard';
import timeMixin from '../mixins/time';
import adminMixin from '../mixins/isAdmin'
import eventListenerMixins from 'shared/mixins/eventListenerMixins';
import conversationMixin from '../mixins/conversations';
import wootConstants from '../constants';
import {
  hasPressedAltAndJKey,
  hasPressedAltAndKKey,
} from 'shared/helpers/KeyboardHelpers';
import WootButton from './ui/WootButton.vue';
import KnowledgeBaseModal from './widgets/modal/KnowledgeBaseModal.vue';
import FaqModal from './widgets/modal/FAQModal.vue';

const ASSIGNEE_TYPE_TAB_ALL = 'all'
const ADMINISTRATOR_ROLE = 'administrator'



export default {
  components: {
    ChatTypeTabs,
    ConversationCard,
    ChatFilter,
    WootButton,
    KnowledgeBaseModal,
    FaqModal
},
  mixins: [adminMixin, timeMixin, conversationMixin, eventListenerMixins],
  props: {
    project: {
      type: [String, Number],
      default: 0,
    },
    teamId: {
      type: [String, Number],
      default: 0,
    },
    label: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      TUTORIAL_FIRST_LOGIN: FIRST_LOGIN_TUTORIAL,
      TUTORIAL_OVERVIEW: CLOUDCHAT_OVERVIEW,
      TUTORIAL_TICKET: TICKET_TUTORIAL,
      TUTORIAL_PRE_TICKET: PRE_TICKET_TUTORIAL,
      activeAssigneeTab: wootConstants.ASSIGNEE_TYPE.ME,
      activeStatus: wootConstants.STATUS_TYPE.OPEN,
      isKnowledgeBaseModalVisible: false,
      tutorialStarted: false,
      walkthrough: Walkthrough
    };
  },
  computed: {
    ...mapGetters({
      currentChat: 'getSelectedChat',
      chatLists: 'getAllConversations',
      mineChatsList: 'getMineChats',
      allChatList: 'getAllStatusChats',
      unAssignedChatsList: 'getUnAssignedChats',
      chatListLoading: 'getChatListLoadingStatus',
      currentUserID: 'getCurrentUserID',
      activeInbox: 'getSelectedInbox',
      conversationStats: 'conversationStats/getStats',
      currentRole: 'getCurrentRole',
      flags: 'getAppFlags',
      getSteps: 'getSteps',
      currentUserStatus: 'getUserStatus',
      inboxes: 'inboxes/getInboxes',
    }),
    shouldShowChatFilter() {
      return this.isAdmin || !this.flags.clean_up_interface
    },
    assigneeTabItems() {
      return this.$t('CHAT_LIST.ASSIGNEE_TYPE_TABS')
        .filter(item => this.currentRole === ADMINISTRATOR_ROLE || item.KEY !== ASSIGNEE_TYPE_TAB_ALL)
        .map(item => {
          const count = this.conversationStats[item.COUNT_KEY] || 0;
          return {
            key: item.KEY,
            name: item.NAME,
            count,
          };
        });
    },
    showAssigneeInConversationCard() {
      return this.activeAssigneeTab === wootConstants.ASSIGNEE_TYPE.ALL;
    },
    itsMyTab() {
      return this.activeAssigneeTab === wootConstants.ASSIGNEE_TYPE.ME
    },
    inbox() {
      return this.currentInbox || {}
    },
    currentPage() {
      return this.$store.getters['conversationPage/getCurrentPageFilter'](
        this.activeAssigneeTab
      );
    },
    hasCurrentPageEndReached() {
      return this.$store.getters['conversationPage/getHasEndReached'](
        this.activeAssigneeTab
      );
    },
    conversationFilters() {
      return {
        project: this.project ? this.project : undefined,
        assigneeType: this.activeAssigneeTab,
        status: this.activeStatus,
        page: this.currentPage + 1,
        labels: this.label ? [this.label] : undefined,
        teamId: this.teamId ? this.teamId : undefined,
      };
    },
    pageTitle() {
      if (this.inbox.project) {
        return this.inbox.project;
      }
      if (this.activeTeam.name) {
        return this.activeTeam.name;
      }
      if (this.label) {
        return `#${this.label}`;
      }
      return this.$t('CHAT_LIST.TAB_HEADING');
    },
    conversationList() {
      let conversationList = [];
      const filters = this.conversationFilters;
      if (this.activeAssigneeTab === 'me') {
        conversationList = [...this.mineChatsList(filters)];
      } else if (this.activeAssigneeTab === 'unassigned') {
        conversationList = [...this.unAssignedChatsList(filters)]
      } else {
        conversationList = [...this.allChatList(filters)];
      }

      return conversationList.sort(
        (a, b) => (a.sla_timestamp || Infinity) - (b.sla_timestamp || Infinity)
      );
    },
    activeTeam() {
      if (this.teamId) {
        return this.$store.getters['teams/getTeam'](this.teamId);
      }
      return {};
    },
    showPageTitle() {
      return !this.flags.hide_project_information
    },
    currentInbox() {
      if(!!this.currentChat) {
        return this.$store.getters['inboxes/getInbox'](this.currentChat.inbox_id);
      }

      return null
    },
    currentProjectSlug() {
      if(!!this.currentInbox && !!Object.keys(this.currentInbox).length){
        if(this.flags.stonly_knowledge_base && this.currentInbox.project == 'mobly'){
          return null
        }
        return this.currentInbox.project_slug
      } else if(!!this.project) {
        if(this.flags.stonly_knowledge_base && this.project == 'mobly'){
          return null
        }
        const foundInbox = this.inboxes.find(
          inbox => inbox.project === this.project
        )

        return foundInbox.project_slug
      }

      return null
    },
    itsMyTicket() {
      return this.currentChat?.meta?.assignee?.id === this.currentUserID
    },
    showKnowledgeBaseButton() {
      return this.currentProjectSlug || this.currentProjectLink
    },
    currentProjectLink() {
      if(this.flags.stonly_knowledge_base && this.project == 'mobly'){
        return 'https://cloudhumans-ops.stonly.com/'
      } else {
        return null
      }
    }
  },
  watch: {
    activeTeam() {
      this.resetAndFetchData();
    },
    project(current) {
      this.resetAndFetchData();

      if(this.flags.hide_project_information && current) {
        this.goToDashboard()
      }
    },
    label() {
      this.resetAndFetchData();
    },
    currentUserStatus(current) {
      !this.tutorialStarted && current && this.initTutorial()
    },
    flags(current) {
      !this.tutorialStarted && current && this.initTutorial()

      if(current.hide_project_information && this.project) {
        this.goToDashboard()
      }
    }
  },
  mounted() {
    this.$store.dispatch('setChatFilter', this.activeStatus);
    this.$store.dispatch('inboxes/getTicketCriticality')
    this.resetAndFetchData();

    bus.$on('fetch_conversation_stats', () => {
      this.$store.dispatch('conversationStats/get', this.conversationFilters);
    });
  },
  methods: {
    goToDashboard() {
      const { pathname } = window.location;
      const accountId = accountIdFromPathname(pathname);

      this.$router.push({ path: frontendURL(`accounts/${accountId}/dashboard`) })
    },
    setupOverviewTutorial(){
      this.$store.dispatch('addWalktrhough', {
          name: this.TUTORIAL_OVERVIEW,
          steps: [
            Step(
              {
                step_id:"Bem vindo ao Cloud Chat! :D",
                step_desc: "Vamos fazer um tour pela ferramenta?",
              }
            ),
            Step(
              {
                step_id:"Meus Projetos",
                step_desc: "Aqui ficarão listados todos os projetos que você estiver participando",
                target: `${this.TUTORIAL_FIRST_LOGIN}-projects`
              }
            ),
            Step(
              {
                step_id:"Aba Projetos",
                step_desc: "Para entrar em algum deles é só clicar ;)",
                target: `${this.TUTORIAL_FIRST_LOGIN}-projects`
              }
            ),
            Step(
              {
                step_id:"Aqui é onde ficarão os tickets",
                step_desc: "Você poderá navegar por eles. \n\n estarão divididos entre 'Meus Tickets' e 'Não atribuídos'",
                target: `${this.TUTORIAL_FIRST_LOGIN}-tab_chat`
              }
            ),
            Step(
              {
                step_id:"Tickets não atribuídos",
                step_desc: "Aqui estão os tickets livres para que você possa pegar",
                target: `${this.TUTORIAL_FIRST_LOGIN}-tab_chat-unassigned`
              }
            ),
            Step(
              {
                step_id:"Meus Tickets",
                step_desc: "Quando se atribuir à um ticket é aqui que ficarão todos os seus tickets que você estará atendendo",
                target: `${this.TUTORIAL_FIRST_LOGIN}-tab_chat-me`
              }
            ),
            Step(
              {
                step_id:"Clique aqui!",
                step_desc: "clique aqui para buscar por tickets não atribuídos :D",
                target: `${this.TUTORIAL_FIRST_LOGIN}-tab_chat-unassigned`
              }
            ),
            Step(
              {
                step_id:"Tickets não atribuídos",
                step_desc: "E mais uma coisinha, fica traquilo se essa sessão estiver vazia é por que todos os tickets já estão sendo atendidos ;)",
                target: `${this.TUTORIAL_FIRST_LOGIN}-tab_chat-unassigned`
              }
            ),
            Step(
              {
                step_id:"Lista de tickets",
                step_desc: "Agora é só ficar atento até que um ticket apareça, mas lembre de ser rápido! Outros Pros também podem atribuir esse ticket para si ;)",
                target: `${this.TUTORIAL_FIRST_LOGIN}-chat-list`
              }
            ),
            Step(
              {
                step_id: "Lembre-se de pegar o primeiro ticket da fila",
                step_desc: "Ele é o ticket mais urgente ;)",
                target: `${this.TUTORIAL_FIRST_LOGIN}-chat-list`
              }
            )
          ]
        })
    },

    setupPreTicketTutorial(){
      this.$store.dispatch("addWalktrhough", {
        name: this.TUTORIAL_PRE_TICKET,
        steps: [
          Step({
            step_id: "Preparado começarmos a atender seu primeiro Ticket?",
            step_desc: "Não se preocupa, nós vamos estar aqui pra te ajudar 😉"
          }),
          Step({
            step_id: "Ciclo de vida",
            step_desc: "Antes de tudo, precisamos entender que todo o ticket tem um ciclo de vida em comun (com excessão dos não_tickets)"
          }),
          Step({
            step_id: "Ciclo de vida - Saudação",
            step_desc: "Antes de agir no ticket, precisamos realizar o procedimento de saudação! \n\n Lembre-se de ser bastante empático com o clinete durante todo o atendimento a começar daqui 😊"
          }),
          Step({
            step_id: "Ciclo de vida - atendimento",
            step_desc: "Após saudar o cliente (quando necessário) \n\n se inicia o atendimento onde você terá que descobrir qual é o problema do cliente e como resolver"
          }),
          Step({
            step_id: "Ciclo de vida - atendimento",
            step_desc: "Use de maneiras humanizadas para pedir dados e informações necessárias para chegar a uma conclusão acertiva 😉"
          }),
          Step({
            step_id: "Ciclo de vida - Encerramento - Problema Resolvido",
            step_desc: "Nesta fase você se certificou que o problema foi resolvido e que o cliente não tem mais dúvidas ou necessidades em que você possa ajudar"
          }),
          Step({
            step_id: "Ciclo de vida - Encerramento - Problema Resolvido",
            step_desc: "Sabendo disso, é preciso iniciar o procedimento de encerramento do ticket"
          }),
          Step({
            step_id: "Ciclo de vida - Encerramento - Caso de N2",
            step_desc: "Nesta fase você identificou que o ticket se trata de um caso que deve ser resolvido em N2 \n\n ou seja, é o Time interno do Projeto quem deve cuidar desse atendimento! \n\n Então deverá iniciar o processo para enviá-lo para N2!"
          }),
          Step({
            step_id: "Ciclo de Vida do Ticket",
            step_desc: "Lembre-se que você sempre pode e deve ultilizar a Base de Conhecimento para identificar o problema e a forma correta de agir nesse casos 😉",
          }),
        ]
      })
    },

    setupTutorials(){
      this.setupOverviewTutorial()
      this.setupPreTicketTutorial()
    },

    initTutorial() {
      const inbox = this.inboxes[0]
      const isProjectFlagActive = !!this.flags.tutorial_meet_url.projects[inbox?.project]
      const isAwaitingTutorial = this.currentUserStatus === 'awaiting_tutorial'

      if(isProjectFlagActive && isAwaitingTutorial) {
        this.tutorialStarted = true

        this.setupTutorials()

        Walkthrough(this.TUTORIAL_OVERVIEW)
        .oncomplete(() => this.$store.dispatch('setCompleteTutorial'))
        .onexit(() => Walkthrough(this.TUTORIAL_PRE_TICKET)
                  .onexit(() => Walkthrough(TICKET_TUTORIAL).start())
                  .start()
                )
        .start()
      }
    },
    showKnowledgeBaseModal(){
      this.isKnowledgeBaseModalVisible = true;
    },
    hideKnowledgeBaseModal(){
      this.isKnowledgeBaseModalVisible = false;
    },
    getKeyboardListenerParams() {
      const allConversations = this.$refs.activeConversation.querySelectorAll(
        'div.conversations-list div.conversation'
      );
      const activeConversation = this.$refs.activeConversation.querySelector(
        'div.conversations-list div.conversation.active'
      );
      const activeConversationIndex = [...allConversations].indexOf(
        activeConversation
      );
      const lastConversationIndex = allConversations.length - 1;
      return {
        allConversations,
        activeConversation,
        activeConversationIndex,
        lastConversationIndex,
      };
    },
    handleKeyEvents(e) {
      if (hasPressedAltAndJKey(e)) {
        const {
          allConversations,
          activeConversationIndex,
        } = this.getKeyboardListenerParams();
        if (activeConversationIndex === -1) {
          allConversations[0].click();
        }
        if (activeConversationIndex >= 1) {
          allConversations[activeConversationIndex - 1].click();
        }
      }
      if (hasPressedAltAndKKey(e)) {
        const {
          allConversations,
          activeConversationIndex,
          lastConversationIndex,
        } = this.getKeyboardListenerParams();
        if (activeConversationIndex === -1) {
          allConversations[lastConversationIndex].click();
        } else if (activeConversationIndex < lastConversationIndex) {
          allConversations[activeConversationIndex + 1].click();
        }
      }
    },
    resetAndFetchData() {
      this.$store.dispatch('conversationPage/reset');
      this.$store.dispatch('emptyAllConversations');
      this.fetchConversations();
    },
    fetchConversations() {
      this.$store
        .dispatch('fetchAllConversations', this.conversationFilters)
        .then(() => this.$emit('conversation-load'));
    },
    updateAssigneeTab(selectedTab) {
      if (this.activeAssigneeTab !== selectedTab) {
        bus.$emit('clearSearchInput');
        this.activeAssigneeTab = selectedTab;
        if (!this.currentPage) {
          this.fetchConversations();
        }
      }
    },
    updateStatusType(index) {
      if (this.activeStatus !== index) {
        this.activeStatus = index;
        this.resetAndFetchData();
      }
    }
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/woot';

.icon{
  margin-right: 8pt;
}

.spinner {
  margin-top: var(--space-normal);
  margin-bottom: var(--space-normal);
}

.knowledge-base-preview{
  border-top-width: 5pt;
  border-color: gray;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  align-self: flex-end;
  margin-bottom: 4pt;
}

.conversations-list-wrap {
  flex-shrink: 0;
  width: 34rem;

  @include breakpoint(large up) {
    width: 36rem;
  }
  @include breakpoint(xlarge up) {
    width: 35rem;
  }
  @include breakpoint(xxlarge up) {
    width: 38rem;
  }
  @include breakpoint(xxxlarge up) {
    flex-basis: 46rem;
  }
}

.faq-modal {
  display:flex;
  justify-content:center;
  margin: 0 0 10px 0;
}
</style>
