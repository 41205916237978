<template>
  <div class="reply-box" :class="replyBoxClass">
    <div v-if="showSelfAssign && isAbleToAssign && !isReplyBoxLoading" class="reply-box__bottom">
      <div class="reply-box__self-assign" :data-step="`${TUTORIAL_TICKET}-assign_button`">
        <woot-button
          class-names="self-assign"
          color-scheme="success"
          icon="ion-person"
          :is-loading="isLoading"
          @click="isAbleToAssign ? onSelfAssign() : null"
          :disabled = "!isAbleToAssign"
        >
          {{ $t('CONVERSATION_SIDEBAR.SELF_ASSIGN') }}
        </woot-button>
      </div>
      <div class="reply-box__bottom__tickets-info">
        <span v-if="showSelfAssign && isAbleToAssign && !isPro && !isReplyBoxLoading" class="reply-box__bottom__tickets-info__tickets-count">
          Período de testes, tickets restantes: {{this.getTicketsRemaining}}
        </span>
      </div>
    </div>
    <div v-if="showSelfAssign && !isPro && !isAbleToAssign && !isReplyBoxLoading" class="reply-box__bottom__tickets-sold-out">
      <div class="reply-box__bottom__tickets-sold-out__icon">
        <i class="ion-information-circled"></i>
      </div>
      <div v-html="this.getWarningRestrictedProMessage" class="reply-box__bottom__tickets-sold-out__text">
      </div>
    </div>
    <woot-loading-state
      v-if="showSelfAssign && isReplyBoxLoading"
    />

    <div v-if="!showSelfAssign && hasUnreadMessages" class="unread-message-warning">
      <img src="../../../assets/images/information-button.png">
      <div class="text-area">
        Existem mensagens prévias nesse ticket, é necessário as ler para um melhor entendimento do contexto ;)
        <br/><br/>
        <b>Preste atenção nos seguintes pontos:</b>
        <br/><br/>
        <ul>
          <li> <b>Não</b> se apresentar novamente caso <b>já tenham se apresentado</b> neste ticket </li>
          <li> Evitar fazer <b>perguntas repetidas</b> </li>
          <li> Não dar uma <b>resposta exatamente igual</b> a alguma que já foi dada anteriormente. </li>
        </ul>
        <br/><br/>
        O ideal é o cliente sentir que é a mesma pessoa atendendo para minimizar os impactos na experiência do mesmo.
      </div>

      <div class="read-all-button" @click="$emit('onReadAll')">Entendi</div>
    </div>

    <reply-top-panel
      v-if="!showSelfAssign && !hasUnreadMessages"
      :mode="replyType"
      :set-reply-mode="setReplyMode"
      :is-message-length-reaching-threshold="isMessageLengthReachingThreshold"
      :characters-remaining="charactersRemaining"
      :popout-reply-box="popoutReplyBox"
      @click="$emit('click')"
    />
    <div class="reply-box__top" v-if="!showSelfAssign && !hasUnreadMessages">
      <canned-response
        v-if="showMentions && hasSlashCommand"
        v-on-clickaway="hideMentions"
        :search-key="mentionSearchKey"
        @click="replaceText"
      />
      <emoji-input
        v-if="showEmojiPicker"
        v-on-clickaway="hideEmojiPicker"
        :on-click="emojiOnClick"
      />
      <resizable-text-area
        id="replyBoxTextArea"
        v-if="!showRichContentEditor"
        ref="messageInput"
        v-model="message"
        :disabled="!isConversationOpen"
        class="input"
        :placeholder="messagePlaceHolder"
        :min-height="4"
        @typing-off="onTypingOff"
        @typing-on="onTypingOn"
        @focus="onFocus"
        @blur="onBlur"
      />
      <woot-message-editor
        v-else
        v-model="message"
        class="input"
        :is-private="isOnPrivateNote"
        :placeholder="messagePlaceHolder"
        :min-height="4"
        @typing-off="onTypingOff"
        @typing-on="onTypingOn"
        @focus="onFocus"
        @blur="onBlur"
        @toggle-user-mention="toggleUserMention"
        @toggle-canned-menu="toggleCannedMenu"
      />
    </div>
    <div v-if="hasAttachments && !hasUnreadMessages" class="attachment-preview-box">
      <attachment-preview
        :attachments="attachedFiles"
        :remove-attachment="removeAttachment"
      />
    </div>
    <reply-bottom-panel
      v-if="!showSelfAssign && !hasUnreadMessages"
      :mode="replyType"
      :send-button-text="replyButtonLabel"
      :on-file-upload="onFileUpload"
      :show-file-upload="showFileUpload"
      :toggle-emoji-picker="toggleEmojiPicker"
      :show-emoji-picker="showEmojiPicker"
      :on-send="() => sendMessage(!underlyingChannelIsEmailOrForm, underlyingChannelIsEmailOrForm)"
      :is-send-disabled="isReplyButtonDisabled"
      :set-format-mode="setFormatMode"
      :is-on-private-note="isOnPrivateNote"
      :is-format-mode="showRichContentEditor"
      :enable-rich-editor="isRichEditorEnabled"
      :enter-to-send-enabled="enterToSendEnabled"
      :underlying-channel="underlyingChannel"
      :total-message-character="message.length"
      :max-message-character="maxMessageCharacter"
      :is-email="underlyingChannelIsEmailOrForm"
      @toggleEnterToSend="toggleEnterToSend"
    />

    <woot-delete-modal
      :show.sync="showSmallEmailMessageConfirmationPopup"
      :on-close="closeSmallEmailMessageConfirmationPopup"
      :on-confirm="() => sendMessage(true, true)"
      title="Você está respondendo um e-mail, tem certeza que deseja enviar uma mensagem desse tamanho?"
      message="Lembre-se: Interações por e-mail devem responder a todas as demandas do cliente em apenas uma mensagem."
      confirm-text="Estou ciente, enviar mesmo assim"
      reject-text="Não enviar"
    />

    <woot-delete-modal
      :show.sync="showBigChatMessageConfirmationPopup"
      :on-close="closeBigChatMessageConfirmationPopup"
      :on-confirm="() => sendMessage(true, true)"
      title="Você está respondendo um chat, tem certeza que deseja enviar uma mensagem desse tamanho? :("
      message="Para oferecer uma experiência mais agradável para os clientes finais e melhorar nossas avaliações de CSAT, recomendamos que envie mensagens com menos que 300 caracteres. Recomendamos também que tente dividir mensagens maiores em varias mensagens menores! :)"
      confirm-text="Estou ciente, enviar mesmo assim"
      reject-text="Não enviar"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { mixin as clickaway } from 'vue-clickaway';
import alertMixin from 'shared/mixins/alertMixin';

import EmojiInput from 'shared/components/emoji/EmojiInput';
import CannedResponse from './CannedResponse';
import ResizableTextArea from 'shared/components/ResizableTextArea';
import AttachmentPreview from 'dashboard/components/widgets/AttachmentsPreview';
import ReplyTopPanel from 'dashboard/components/widgets/WootWriter/ReplyTopPanel';
import ReplyBottomPanel from 'dashboard/components/widgets/WootWriter/ReplyBottomPanel';
import { REPLY_EDITOR_MODES } from 'dashboard/components/widgets/WootWriter/constants';
import WootMessageEditor from 'dashboard/components/widgets/WootWriter/Editor';
import { checkFileSizeLimit } from 'shared/helpers/FileHelper';
import { MAXIMUM_FILE_UPLOAD_SIZE } from 'shared/constants/messages';
import wootConstants from '../../../constants';
import { similarity } from './helpers/string-similarity'

import {
  isEscape,
  isEnter,
  hasPressedShift,
} from 'shared/helpers/KeyboardHelpers';
import { MESSAGE_MAX_LENGTH } from 'shared/helpers/MessageTypeHelper';
import inboxMixin from 'shared/mixins/inboxMixin';
import uiSettingsMixin from 'dashboard/mixins/uiSettings';
import { TICKET_TUTORIAL } from '../../../store/modules/walktrough/walkthroughs';

import { assignSteps, channelSteps, detailsSteps, greetingSteps, historySteps, openDetailsSteps, supportSteps } from './steps/ReplyBoxSteps'

const SMALL_MESSAGE_LENGTH = 300
const MEDIUM_MESSAGE_LENGTH = 500

export default {
  components: {
    EmojiInput,
    CannedResponse,
    ResizableTextArea,
    AttachmentPreview,
    ReplyTopPanel,
    ReplyBottomPanel,
    WootMessageEditor,
  },
  mixins: [clickaway, inboxMixin, uiSettingsMixin, alertMixin],
  props: {
    selectedTweet: {
      type: [Object, String],
      default: () => ({}),
    },
    isATweet: {
      type: Boolean,
      default: false,
    },
    popoutReplyBox: {
      type: Boolean,
      default: false,
    },
    underlyingChannel: {
      type: String,
      default: "",
    },
    hasUnreadMessages: {
      type: Boolean,
    }
  },
  data() {
    return {
      message: '',
      maxMessageCharacter: 300,
      suggestion: null,
      isFocused: false,
      showEmojiPicker: false,
      showMentions: false,
      attachedFiles: [],
      isUploading: false,
      replyType: REPLY_EDITOR_MODES.REPLY,
      mentionSearchKey: '',
      hasUserMention: false,
      hasSlashCommand: false,
      showBigChatMessageConfirmationPopup: false,
      showSmallEmailMessageConfirmationPopup: false,
      cannedResponse: null,
      isLoading: false,
      isReplyBoxLoading: true,
      isRequestingGetProjectMemberRestriction: false,
      ticket_tutorial: null,
      TUTORIAL_TICKET: TICKET_TUTORIAL,
      assignStepsInstance: null,
      openDetailsStepsInstance: null,
      firstMessageWasSent: false
    };
  },
  computed: {

    underlyingChannelIsEmailOrForm() {
      return /email|Mail|form|FORM|mail/.test(this.underlyingChannel)
    },
    showSelfAssign() {
      if (this.currentChat.inbox_id != this.getProjectMemberRestrictionInboxId && !this.isRequestingGetProjectMemberRestriction){
        this.getProjectMemberRestrictions();
      }
      if(this.currentChat.inbox_id === this.getProjectMemberRestrictionInboxId && !this.isRequestingGetProjectMemberRestriction){
        this.isReplyBoxLoading = false;
      }
      return !this.assignedAgent && this.isConversationOpen
    },
    assignedAgent: {
      get() {
        return this.currentChat.meta.assignee;
      },
      set(agent) {
        const agentId = agent ? agent.id : 0;
        this.isLoading = true;
        this.$store
          .dispatch('assignAgent', {
            conversationId: this.currentChat.id,
            agentId,
          })
          .then((response) => {
            this.isLoading = false;
            if (response[0] == false){
              this.showAlert(response[1]);
              this.getProjectMemberRestrictions();
            }else{
              this.showAlert(this.$t('CONVERSATION.CHANGE_AGENT'));
              this.getProjectMemberRestrictions();
            }
          });
      },
    },
    showRichContentEditor() {
      if (this.isOnPrivateNote) {
        return true;
      }

      if (this.isRichEditorEnabled) {
        const {
          display_rich_content_editor: displayRichContentEditor,
        } = this.uiSettings;

        return displayRichContentEditor;
      }
      return false;
    },
    ...mapGetters({
      currentChat: 'getSelectedChat',
      currentUser: 'getCurrentUser',
      isEnableToTicket: 'getEnableToTicket',
      memberStatus: 'projectMemberRestrictions/getState',
      userLevel: 'projectMemberRestrictions/getUserLevel',
      flags: 'getAppFlags',
    }),
    enterToSendEnabled() {
      return !!this.uiSettings.enter_to_send_enabled;
    },
    isPrivate() {
      if (this.currentChat.can_reply || this.isATwilioWhatsappChannel) {
        return this.isOnPrivateNote;
      }
      return true;
    },
    inboxId() {
      return this.currentChat.inbox_id;
    },
    inbox() {
      return this.$store.getters['inboxes/getInbox'](this.inboxId);
    },
    getTicketsRemaining() {
      return this.$store.getters['projectMemberRestrictions/getTicketsRemanding'];
    },
    messagePlaceHolder() {
      return this.isPrivate
        ? this.$t('CONVERSATION.FOOTER.PRIVATE_MSG_INPUT')
        : this.$t('CONVERSATION.FOOTER.MSG_INPUT');
    },
    isMessageLengthReachingThreshold() {
      return this.message.length > this.maxLength - 50;
    },
    isConversationOpen() {
      return this.currentChat.status === wootConstants.STATUS_TYPE.OPEN
    },
    isAbleToAssign() {
      return this.$store.getters['projectMemberRestrictions/isAbleToAssign'];
    },
    isPro() {
      return this.$store.getters['projectMemberRestrictions/isPro'];
    },
    getProjectMemberRestrictionInboxId() {
      return this.$store.getters['projectMemberRestrictions/getProjectMemberRestrictionInboxId'];
    },
    getWarningRestrictedProMessage() {
      return this.$store.getters['projectMemberRestrictions/getWarningRestrictedProMessage'];
    },
    charactersRemaining() {
      return this.maxLength - this.message.length;
    },
    isReplyButtonDisabled() {
      if (this.isATweet && !this.inReplyTo) {
        return true;
      }

      if (this.hasAttachments) return false;

      return (
        this.isMessageEmpty ||
        this.message.length === 0 ||
        this.message.length > this.maxLength
      );
    },

    conversationType() {
      const { additional_attributes: additionalAttributes } = this.currentChat;
      const type = additionalAttributes ? additionalAttributes.type : '';
      return type || '';
    },
    maxLength() {
      if (this.isPrivate) {
        return MESSAGE_MAX_LENGTH.GENERAL;
      }
      if (this.isAFacebookInbox) {
        return MESSAGE_MAX_LENGTH.FACEBOOK;
      }
      if (this.isATwilioWhatsappChannel) {
        return MESSAGE_MAX_LENGTH.TWILIO_WHATSAPP;
      }
      if (this.isATwilioSMSChannel) {
        return MESSAGE_MAX_LENGTH.TWILIO_SMS;
      }
      if (this.isATwitterInbox) {
        if (this.conversationType === 'tweet') {
          return MESSAGE_MAX_LENGTH.TWEET - this.replyToUserLength - 2;
        }
      }
      return MESSAGE_MAX_LENGTH.GENERAL;
    },
    showFileUpload() {
      return (
        this.isAWebWidgetInbox ||
        this.isAFacebookInbox ||
        this.isATwilioWhatsappChannel ||
        this.isAPIInbox ||
        this.isAnEmailChannel ||
        this.isATwilioSMSChannel ||
        this.isATelegramChannel
      );
    },
    replyButtonLabel() {
      if (this.isPrivate) {
        return this.$t('CONVERSATION.REPLYBOX.CREATE');
      }
      if (this.conversationType === 'tweet') {
        return this.$t('CONVERSATION.REPLYBOX.TWEET');
      }
      return this.$t('CONVERSATION.REPLYBOX.SEND');
    },
    replyBoxClass() {
      return {
        'is-private': this.isPrivate,
        'is-focused': this.isFocused || this.hasAttachments,
      };
    },
    hasAttachments() {
      return this.attachedFiles.length;
    },
    isRichEditorEnabled() {
      return this.isAWebWidgetInbox || this.isAnEmailChannel;
    },
    isOnPrivateNote() {
      return this.replyType === REPLY_EDITOR_MODES.NOTE;
    },
    inReplyTo() {
      const selectedTweet = this.selectedTweet || {};
      return selectedTweet.id;
    },
    replyToUserLength() {
      const selectedTweet = this.selectedTweet || {};
      const {
        sender: {
          additional_attributes: { screen_name: screenName = '' } = {},
        } = {},
      } = selectedTweet;
      return screenName ? screenName.length : 0;
    },
    isMessageEmpty() {
      if (!this.message) {
        return true;
      }
      return !this.message.trim().replace(/\n/g, '').length;
    },
    isContactPanelOpen() {
      if (this.currentChat.id) {
        const { is_contact_sidebar_open: isContactSidebarOpen } = this.uiSettings;
        
        return isContactSidebarOpen;
      }

      return false;
    },
  },
  watch: {
    currentChat(conversation, { id: oldId }) {
      const { can_reply: canReply, id } = conversation;
      if (this.isOnPrivateNote) {
        return;
      }

      if (canReply || this.isATwilioWhatsappChannel) {
        this.replyType = REPLY_EDITOR_MODES.REPLY;
      } else {
        this.replyType = REPLY_EDITOR_MODES.NOTE;
      }

      if (id !== oldId){
        this.firstMessageWasSent = false
      }
    },
    message(updatedMessage, oldMessage) {
      const mustCleanCannedResponse = !!this.cannedResponse && (
        updatedMessage.trim() === '' ||
        (updatedMessage.length === 1 && oldMessage.length > 0)
      )

      if(mustCleanCannedResponse) {
        this.cannedResponse = null
      }

      this.hasSlashCommand =
        updatedMessage[0] === '/' && !this.showRichContentEditor;
      const hasNextWord = updatedMessage.includes(' ');
      const isShortCodeActive = this.hasSlashCommand && !hasNextWord;
      if (isShortCodeActive) {
        this.mentionSearchKey = updatedMessage.substr(1, updatedMessage.length);
        this.showMentions = true;
      } else {
        this.mentionSearchKey = '';
        this.showMentions = false;
      }
    },
    isContactPanelOpen(current, old) {
      if(current && current !== old) {
        this.openDetailsStepsInstance?.exit()
        this.initDetailsTutorial()
      }
    }
  },

  created() {
    bus.$off('setReplyBoxMessage');

    bus.$on('setSuggestion', (suggestion) => {
      this.suggestion = suggestion
    });

    bus.$on('setReplyBoxMessage', ({ message }) => {
      this.message = message
    });
  },
  mounted() {
    // Donot use the keyboard listener mixin here as the events here are supposed to be
    // working even if input/textarea is focussed.

    document.addEventListener('keydown', this.handleKeyEvents);

    this.$watch('currentChat', (newChat, oldChat) => {
      if(newChat.id !== oldChat.id) {
        this.message = ''
        this.suggestion = null
      }
    })
  },
  updated() {
    this.initAssignTutorial()
    this.initChannelTutorial()
  },
  destroyed() {
    document.removeEventListener('keydown', this.handleKeyEvents);
    this.ticket_tutorial.exit(true)
  },

  methods: {
    initSupportTutorial() {
      if (!this.firstMessageWasSent) {
        supportSteps(this.currentChat.id, this.memberStatus, this.userLevel, this.currentChat.project, this.flags);
        this.firstMessageWasSent = false;
      }
    },
    initAssignTutorial() {
      const startSteps = this.showSelfAssign && this.isAbleToAssign && !this.isReplyBoxLoading

      if(startSteps && !this.assignStepsInstance) {
        this.assignStepsInstance = assignSteps(this.currentChat.id, this.memberStatus, this.userLevel, this.currentChat.project, this.flags)
      }
    },
    initChannelTutorial() {
      const startSteps = !this.showSelfAssign && !!this.underlyingChannel

      if(startSteps) {
        channelSteps(this.currentChat.id, this.memberStatus, this.userLevel, this.currentChat.project, this.flags)?.onexit(() => {
          if(!this.isContactPanelOpen) {
            this.initOpenDetailsTutorial()
          } else {
            this.initDetailsTutorial()
          }
        })
      }
    },
    initOpenDetailsTutorial() {
      const startSteps = !this.isContactPanelOpen

      if(startSteps) {
        this.openDetailsStepsInstance = openDetailsSteps(this.currentChat.id, this.memberStatus, this.userLevel, this.currentChat.project, this.flags)
      }
    },
    initDetailsTutorial() {
      const startSteps = this.isContactPanelOpen

      if(startSteps) {
        detailsSteps(this.currentChat.id, this.memberStatus, this.userLevel, this.currentChat.project, this.flags)?.onexit(this.initGreetingsTutorial)
      }
    },
    initGreetingsTutorial() {
      greetingSteps(this.currentChat.id, this.memberStatus, this.userLevel, this.currentChat.project, this.flags)?.onexit(this.initHistoryTutorial)
    },
    initHistoryTutorial() {
      historySteps(this.currentChat.id, this.memberStatus, this.userLevel, this.currentChat.project, this.flags);
    },
    closeBigChatMessageConfirmationPopup () {
      this.showBigChatMessageConfirmationPopup = false;
    },
    openBigChatMessageConfirmationPopup() {
      this.showBigChatMessageConfirmationPopup = true;
    },
    closeConfirmationPopup(){
        this.closeSmallEmailMessageConfirmationPopup();
        this.closeBigChatMessageConfirmationPopup();
    },
    closeSmallEmailMessageConfirmationPopup() {
      this.showSmallEmailMessageConfirmationPopup = false;
    },
    openSmallEmailMessageConfirmationPopup() {
      this.showSmallEmailMessageConfirmationPopup = true;
    },
    onSelfAssign() {
      
      this.$emit("onSelfAssign")

      const {
        account_id,
        availability_status,
        available_name,
        email,
        id,
        name,
        role,
        avatar_url,
      } = this.currentUser;
      const selfAssign = {
        account_id,
        availability_status,
        available_name,
        email,
        id,
        name,
        role,
        thumbnail: avatar_url,
      };

      this.assignedAgent = selfAssign;

      analytics.track("assign ticket",
      {
        context: "[REPLY_BOX]"
      });

      this.assignStepsInstance?.exit()
    },
    toggleUserMention(currentMentionState) {
      this.hasUserMention = currentMentionState;
    },
    toggleCannedMenu(value) {
      this.showCannedMenu = value;
    },
    handleKeyEvents(e) {
      if (isEscape(e)) {
        this.hideEmojiPicker();
        this.hideMentions();
      } else if (isEnter(e)) {
        const hasSendOnEnterEnabled =
          (this.showRichContentEditor &&
            this.enterToSendEnabled &&
            !this.hasUserMention &&
            !this.showCannedMenu) ||
          !this.showRichContentEditor;
        const shouldSendMessage =
          hasSendOnEnterEnabled && !hasPressedShift(e) && this.isFocused;
        if (shouldSendMessage) {
          e.preventDefault();
          this.sendMessage(!this.underlyingChannelIsEmailOrForm);
        }
      }
    },
    toggleEnterToSend(enterToSendEnabled) {
      this.updateUISettings({ enter_to_send_enabled: enterToSendEnabled });
    },
    async getProjectMemberRestrictions() {
      this.isReplyBoxLoading = true;
      this.isRequestingGetProjectMemberRestriction = true;
      await this.$store.dispatch('projectMemberRestrictions/get',{ userId: this.currentUser.id, inboxId: this.currentChat.inbox_id} );
      this.isReplyBoxLoading = false;
      this.isRequestingGetProjectMemberRestriction = false;
    },
    async sendMessage(ignoreSmallEmailMessage = true, ignoreBigChatMessage = true) {
      if (this.isReplyButtonDisabled) {
        return;
      }
      if (!this.showMentions) {
        const newMessage = this.message;
        const messagePayload = this.getMessagePayload(newMessage);

        const hasMinLengthNumber = messagePayload.message.length <= 50

        const hasMaxChatLengthNumber = messagePayload.message.length > this.maxMessageCharacter

        if(!ignoreSmallEmailMessage && hasMinLengthNumber) {
          this.openSmallEmailMessageConfirmationPopup()
          return
        }

        if(!ignoreBigChatMessage && hasMaxChatLengthNumber){
           this.openBigChatMessageConfirmationPopup()
           return
        }

        this.closeConfirmationPopup();

        this.clearMessage();

        try {
          await this.$store.dispatch('sendMessage', messagePayload);

          this.suggestion?.id && await this.$store.dispatch('sendSuggestionUsage', {
            conversationId: this.currentChat.id,
            suggestionIntentId: this.suggestion.id,
            finalResponse: newMessage
          })

          this.suggestion = null

          this.$emit('scrollToMessage');

          this.initSupportTutorial();
        } catch (error) {
          const errorMessage =
            error?.response?.data?.error ||
            this.$t('CONVERSATION.MESSAGE_ERROR');
          this.showAlert(errorMessage);
          if (this.currentUser.role === "agent") {
            window.location.reload()
          }
        }
        this.hideEmojiPicker();
      }
    },
    replaceText(cannedResponse) {
      this.cannedResponse = cannedResponse

      setTimeout(() => {
        this.message = cannedResponse.description;
      }, 100);
    },
    setReplyMode(mode = REPLY_EDITOR_MODES.REPLY) {
      const { can_reply: canReply } = this.currentChat;

      if (canReply || this.isATwilioWhatsappChannel) this.replyType = mode;
      if (this.showRichContentEditor) {
        return;
      }
      this.$nextTick(() => this.$refs.messageInput.focus());
    },
    emojiOnClick(emoji) {
      this.message = `${this.message}${emoji} `;
    },
    clearMessage() {
      this.message = '';
      this.attachedFiles = [];
      this.cannedResponse = null
    },
    toggleEmojiPicker() {
      this.showEmojiPicker = !this.showEmojiPicker;
    },
    hideEmojiPicker() {
      if (this.showEmojiPicker) {
        this.toggleEmojiPicker();
      }
    },
    hideMentions() {
      this.showMentions = false;
    },
    onTypingOn() {
      this.toggleTyping('on');
    },
    onTypingOff() {
      this.toggleTyping('off');
    },
    onBlur() {
      this.isFocused = false;
    },
    onFocus() {
      this.isFocused = true;
    },
    toggleTyping(status) {
      const conversationId = this.currentChat.id;
      this.$store.dispatch('conversationTypingStatus/toggleTyping', {
        status,
        conversationId,
      });
    },
    onFileUpload(file) {
      if (!file) {
        return;
      }
      if (checkFileSizeLimit(file, MAXIMUM_FILE_UPLOAD_SIZE)) {
        this.attachedFiles = [];
        const reader = new FileReader();
        reader.readAsDataURL(file.file);
        reader.onloadend = () => {
          this.attachedFiles.push({
            currentChatId: this.currentChat.id,
            resource: file,
            isPrivate: this.isPrivate,
            thumb: reader.result,
          });
        };
      } else {
        this.showAlert(
          this.$t('CONVERSATION.FILE_SIZE_LIMIT', {
            MAXIMUM_FILE_UPLOAD_SIZE,
          })
        );
      }
    },
    removeAttachment(itemIndex) {
      this.attachedFiles = this.attachedFiles.filter(
        (item, index) => itemIndex !== index
      );
    },
    checkCannedSimilarity(message) {
      const { description } = this.cannedResponse
      return similarity(description, message)
    },
    verifyCannedSimilarity(message) {
      if(!this.cannedResponse) {
        return false
      }

      const cannedSimilarity = this.checkCannedSimilarity(message)

      if(message.length <= SMALL_MESSAGE_LENGTH) {
        return cannedSimilarity >= 0.5
      } else if(message.length <= MEDIUM_MESSAGE_LENGTH) {
        return cannedSimilarity >= 0.65
      } else {
        return cannedSimilarity >= 0.75
      }
    },
    getMessagePayload(message) {
      const referenceCanned = this.verifyCannedSimilarity(message)

      const [attachment] = this.attachedFiles;
      const messagePayload = {
        conversationId: this.currentChat.id,
        message,
        private: this.isPrivate,
      };

      if(referenceCanned) {
        messagePayload.cannedResponseId = this.cannedResponse.id
      }

      if (this.inReplyTo) {
        messagePayload.contentAttributes = { in_reply_to: this.inReplyTo };
      }

      if (attachment) {
        messagePayload.file = attachment.resource.file;
      }

      return messagePayload;
    },
    setFormatMode(value) {
      this.updateUISettings({ display_rich_content_editor: value });
    },
  },
};
</script>

<style lang="scss" scoped>
.send-button {
  margin-bottom: 0;
}

.unread-message-warning{

  align-items: flex-end;
  display: flex;
  padding: 5pt;
  background-color: #d9eeff;

  border-top-width: 5pt;

  img{
    align-self: flex-start;
    max-width: 6%;
    max-height: 6%;
  }

  .read-all-button {
    align-items: center;
    background-color: #274492;
    color: white;
    margin: 2.5pt;
    margin-bottom: 4pt;
    padding: 8pt 20pt;
    font-size: 10pt;
    text-align: center;
    font-weight: 450;
    border-radius: 40px;
  }

  .read-all-button:hover {
    background-color: #688ffa;
  }
}

.unread-message-warning .text-area{
  width: 100%;
  height: 100%;
  padding: 5pt;
  font-size: 10pt;

  font-weight: 500;
}


.attachment-preview-box {
  padding: 0 var(--space-normal);
  background: transparent;
}

.reply-box {
  border-top: 1px solid var(--color-border);
  background: white;

  &.is-private {
    background: var(--y-50);
  }

  &__bottom{
    display: flex;
    padding: 20px 0px 20px 20px;

    &__self-assign {
      padding: 20px;
    }

    &__tickets-info {
      display: flex;
      align-items: center;
      font-size: 1.3rem;
      flex: 1;
      justify-content: flex-end;


      &__tickets-count {
        margin-right: 20px;
        padding: 9px 15px;
        background-color: #fce1ac;
        color: #333;
        border: 1px solid #f5d390;
        border-radius: 6px;
      }
    }

    &__tickets-sold-out {
      text-align: left;
      padding: 20px 30px;
      background-color: #ffd9d9;
      border-top: 1px #8f4848;
      color: #8f4848;
      display: flex;
      font-size: 1.1rem;

      &__icon {
        font-size: 40px;
        display: flex;
        align-items: center;
      }

      &__text {
        margin-left: 20px;
        align-items: center;
        text-align: justify;
      }

    }
  }
}
.send-button {
  margin-bottom: 0;
}

.reply-box__top {
  padding: 0 var(--space-normal);
  border-top: 1px solid var(--color-border);
  margin-top: -1px;
}

.emoji-dialog {
  top: unset;
  bottom: 12px;
  left: -320px;
  right: unset;

  &::before {
    right: -16px;
    bottom: 10px;
    transform: rotate(270deg);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.08));
  }
}

.loading-state{
  padding: 3.6rem 0.4rem;
}
</style>
