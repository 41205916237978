<template>
  <div class="assignment">
    <div class="assignment__wrapper">
      <img :src="globalConfig.logo" :alt="globalConfig.installationName" />
        <h1>Olá {{currentUser.name}}</h1>
      <p v-if="!isAlreadyAssigned && isAssignable">Se deseja responder o ticket clique no botão "{{ $t('CONVERSATION_SIDEBAR.SELF_ASSIGN') }}" abaixo</p>
      <p v-else-if="!isAlreadyAssigned && !isAssignable">No momento você não esta apto para atribuir este ticket</p>
      <p v-else>Outro Agente já atribuiu este ticket</p>

      <woot-button 
        :class="buttonClass" 
        color-scheme="success"
        icon="ion-person"
        @click="!isAlreadyAssigned && isAbleToAssign ? assign() : null"
        :is-loading="isLoading"
        :disabled = "!isAbleToAssign"
      >
        {{ $t('CONVERSATION_SIDEBAR.SELF_ASSIGN') }}
      </woot-button>

    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import alertMixin from '../../../../shared/mixins/alertMixin';
export default {
  props: {
    inboxName: {
      type: String,
      default: ''
    },
  },
  data(){
    return {
       isLoading: false,
       isRequestingGetProjectMemberRestriction: false,
       agentStatus: 'pro'
    }
  },
    mixins: [alertMixin],
  computed: {
    ...mapGetters({
      globalConfig: 'globalConfig/get',
      currentChat:'getSelectedChat',
      currentUser: 'getCurrentUser',
      isEnableToTicket: 'getEnableToTicket',
      menberStatus: 'projectMemberRestrictions/getState',
      getProjectMemberRestrictionInboxId: 'projectMemberRestrictions/getProjectMemberRestrictionInboxId',
      isAssignable: 'projectMemberRestrictions/isAbleToAssign'
    }),
    buttonClass(){
      return this.menberStatus === 'pro' ? 'button' : 'none';
    },
    isAlreadyAssigned(){
      return !!this.assignedAgent
    },
      isAbleToAssign() {
        if (this.currentChat.inbox_id != this.getProjectMemberRestrictionInboxId
              && 
            !this.isRequestingGetProjectMemberRestriction
        ) {
          this.getProjectMemberRestrictions()
        }
        switch(this.menberStatus) {
          case 'pro':
              this.$emit('onProUser')
            break;
          case 'trainee':
              this.$emit('onTraineeUser')
            break;
          case 'restricted_pro':
              this.$emit('onRestrictedUser')
            break;
        } 
        if (this.isAssignable && this.menberStatus === 'pro') {
          this.$emit('onDisableToAssing')
          return true;
        } else {
          this.$emit('onAbleToAssing')
          return false;
        }
      },
    assignedAgent: {
      get() {
        return this.currentChat.meta.assignee;
      },
      set(agent) {
        const agentId = agent ? agent.id : 0;
        this.isLoading = true;
        this.$store
          .dispatch('assignAgent', {
            conversationId: this.currentChat.id,
            agentId,
          })
          .then((response) => {
            this.isLoading = false;
            if (response[0] == false){
              this.showAlert(response[1]);
              this.getProjectMemberRestrictions();
            }else{
              this.showAlert(this.$t('CONVERSATION.CHANGE_AGENT'));
              this.getProjectMemberRestrictions();
            }
          });
      },
    },
  },
  methods:{
    async getProjectMemberRestrictions() {
      this.isRequestingGetProjectMemberRestriction = true;
      await this.$store.dispatch('projectMemberRestrictions/get',{ userId: this.currentUser.id, inboxId: this.currentChat.inbox_id} );
      this.isRequestingGetProjectMemberRestriction = false;
    },
    assign(){
      const {
        account_id,
        availability_status,
        available_name,
        email,
        id,
        name,
        role,
        avatar_url,
      } = this.currentUser;
      const selfAssign = {
        account_id,
        availability_status,
        available_name,
        email,
        id,
        name,
        role,
        thumbnail: avatar_url,
      };
      this.assignedAgent = selfAssign;
      analytics.track("assign ticket", {
        context: "[RESTRICTED_SCREEN]",
        user: {...this.currentUser},
        chat: {...this.currentChat}
      });
      this.$emit('on-assign');
    },
  }
}
</script>

<style lang="scss" scoped>
  .assignment {
    background: #f3f3f3;
    height: 100%;
    width: 100%;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &__wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: #fff;
      padding: 40px;
      border-radius: 6px;
      box-shadow: 0px 20px 20px -15px rgba(#000, 0.1)
    }
    img {
      width: 200px;
      margin-bottom: 40px;
    }
    h1 {
      font-size: 24px;
      font-weight: bold;
      max-width: 700px;
      text-align: center;
      line-height: 26px;
    }
    p {
      font-size: 1.4rem;
      max-width: 610px;
      text-align: center;
      margin-top: 20px;
    }
    .button {
      margin-top: 20px;
    }
    .none{
      display: none;
    }
  }
</style>