/* global axios */
import ApiClient from './ApiClient';

class ProjectMemberRestrictionApi extends ApiClient {
  constructor() {
    super('project_member_restrictions', { accountScoped: true });
  }

  getRestriction(userId, project) {
    return axios.get(`${this.url}/${userId}/`,{
      params: {
        project
      }
    });
  }

  getNotifications() {
    return axios.get(`${this.url}/get_notifications`);
  }

  setNotificationsRead() {
    return axios.post(`${this.url}/set_notifications_read`);
  }
}

export default new ProjectMemberRestrictionApi();
