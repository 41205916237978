
export const getters = {
    getWalkthrough: ($state, name) => $state.walkthroughs.get(name),

    getSteps: $state => name => {
        return $state.walkthroughs.get(name).setps_queue
    },

    getState: ($state, name) => $state.walkthroughs.get(name).state,
    getItState: ($state, name) => $state.walkthroughs.get(name).state.it,
}