<template>
  <li
    v-if="menuItem.action"
  >
    <sidebar-button :menu-item="menuItem" />
  </li>
  <router-link
    v-else-if="menuItem.toState"
    :to="menuItem.toState"
    tag="li"
    active-class="active"
    :class="computedClass"
  >
    <sidebar-button :menu-item="menuItem" />
    <ul v-if="menuItem.hasSubMenu" class="nested vertical menu">
      <router-link
        v-for="child in sortMenuChildrenAlphabetically"
        :key="child.id"
        active-class="active flex-container"
        tag="li"
        :class="{
          disabled: chatListLoading
        }"
        :to="child.toState"
      >
        <a href="#" :class="computedChildClass(child)">
          <div class="wrap">
            <i
              v-if="menuItem.key === 'inbox'"
              class="inbox-icon"
              :class="computedInboxClass(child)"
            />
            <span
              v-if="child.color"
              class="label-color--display"
              :style="{ backgroundColor: child.color }"
            />
            <div
              :title="computedChildTitle(child)"
              :class="computedChildClass(child)"
            >
              {{ child.label }}
            </div>
            <urgency-icon-item
              :project="child.label"
              :openConversations="openConversations"
              v-if="showUrgencyIconItem"
            />
          </div>
        </a>
      </router-link>
    </ul>
  </router-link>
</template>

<script>
import { mapGetters } from 'vuex';

import adminMixin from '../../mixins/isAdmin';
import { getInboxClassByType } from 'dashboard/helper/inbox';
import SidebarButton from './SidebarButton.vue'
import UrgencyIconItem from './UrgencyIconItem.vue'

export default {
  components: {
    SidebarButton,
    UrgencyIconItem
  },
  mixins: [adminMixin],
  props: {
    menuItem: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      openConversations: [],
    }
  },
  computed: {
    ...mapGetters({
      activeInbox: 'getSelectedInbox',
      chatListLoading: 'getChatListLoadingStatus',
      allOpenConversations: 'getAllOpenConversations',
      flags: 'getAppFlags',
    }),
    computedClass() {
      // If active Inbox is present
      // donot highlight conversations
      if (this.activeInbox) return ' ';

      if (
        this.$store.state.route.name === 'inbox_conversation' &&
        this.menuItem.toStateName === 'home'
      ) {
        return 'active';
      }
      return ' ';
    },
    sortMenuChildrenAlphabetically() {
      return this.menuItem.children.sort((a, b) => a.label < b.label ? -1 : a.label > b.label ? 1 : 0)
    },
    showUrgencyIconItem() {
      return this.flags.backlog_alert_cloudchat
    },
  },
  mounted() {
    this.openConversations = this.allOpenConversations;
  },
  watch: {
    allOpenConversations(newConversations) {
      this.openConversations = newConversations;
    }
  },
  methods: {
    computedInboxClass(child) {
      const { type, phoneNumber } = child;
      const classByType = getInboxClassByType(type, phoneNumber);
      return classByType;
    },
    computedChildClass(child) {
      if (!child.truncateLabel) return '';
      return 'text-truncate';
    },
    computedChildTitle(child) {
      if (!child.truncateLabel) return false;
      return child.label;
    }
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.wrap {
  display: flex;
  align-items: center;
}

.label-color--display {
  border-radius: $space-smaller;
  height: $space-normal;
  margin-right: $space-small;
  min-width: $space-normal;
  width: $space-normal;
}

.inbox-icon {
  position: relative;
  top: -1px;
  &.ion-ios-email {
    font-size: var(--font-size-medium);
  }
}
.disabled {
  pointer-events: none;
}
</style>
