<template>
  <woot-modal
    :show="modalIsVisible"
    :close-on-backdrop-click="false"
    :on-close = "closeModal"
  >
    <div class="column content-box">
      <woot-modal-header
        header-title="Atenção"
        :header-content="subtitle"
      />

      <div class="announcements">
        <div v-if="loading" class="announcements__loader">
          <woot-spinner size="" />
        </div>


        <div v-if="!loading" class="announcements__content">
          <div
            class="announcements__item"
            v-for="announcement in this.announcements_content_updated()"
            :key="announcement.id"
          >
            <p>
              Alteração de conteúdo em <b>{{announcement.custom_attributes.project}}</b><br /> <br />
              {{ announcement.content }}
            </p>
            <button @click="markAnnouncementRead(announcement)" class="primary button small button-content">
              Ver novo conteúdo
            </button>
          </div>
        </div>
      </div>

      <div v-if="!loading" class="modal-footer delete-item">
        <button @click="closeModal()" class="alert button nice">
          Fechar
        </button>
      </div>
    </div>
  </woot-modal>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      loading: false,
      modalIsVisible: false,
    }
  },
  props: {
    openKnowledgeBaseModal: Function
  },
  async mounted() {
    await this.$store.dispatch('announcements/getUnreads');
    this.showModal()
    bus.$on('show_announcements_content_modal', () => {
      this.showModal()
    });
  },
  computed: {
    ...mapGetters({
      announcements_unreads: 'announcements/unreads',
    }),

    subtitle() {
      const { length } = this.announcements_content_updated() || {}
      const singleNotification = length === 1
      const notificationStr = singleNotification ? 'anúncio' : 'anúncios'
      const readStr = singleNotification ? 'lido' : 'lidos'

      return `Você tem ${length} ${notificationStr} não ${readStr}`
    }
  },
  methods: {
    async markAnnouncementRead(announcement) {
      this.loading = true
      await this.$store.dispatch('announcements/markAsRead', [announcement.id]);
      await this.$store.dispatch('announcements/getUnreads');
      this.loading = false
      this.openKnowledgeBaseModal(announcement.custom_attributes.project_slug +'?section='+ announcement.custom_attributes.section)
    },
    announcements_content_updated(){
      return this.announcements_unreads.filter(announcement => announcement.announcement_type == 'content_updated' );
    },
    showModal() {
      this.modalIsVisible = this.announcements_content_updated().length > 0;
    },
    closeModal() {
      this.modalIsVisible = false
    },
  },
};
</script>

<style lang="scss">
  .content-updated {
    display: flex;

    text-align: justify;
    text-justify: inter-word;
    font-size: 12px;

    p {
      margin-right: 30px;
    }
  }

  .account-selector--modal {
    .modal-container {
      width: 80rem;
    }
  }

  .button-content {
    float: right;
  }

  .announcements {
    margin-top: 30px;
    padding: 0 3.2rem;

    &__loader {
      display: flex;
      justify-content: center;
      padding: 0 0 20px;
    }

    &__item {
      display: flex;
      text-align: justify;
      text-justify: inter-word;
      font-size: 12px;
      // padding: 10px 0;
      border-bottom: 1px solid #e3e3e3;
      padding-bottom: 20px;
      &:first-child {
        border-top: 1px solid #e3e3e3;
      }

      button {
        margin-left: 15px;
      }
    }
  }
</style>
