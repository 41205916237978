import introJs from "intro.js"
import store from "../../index"

export const FIRST_LOGIN_TUTORIAL = 'FIRST_LOGIN_TUTORIAL' 
export const RESET_PASS_TUTORIAL = 'RESET_TUTORIAL'
export const CLOUDCHAT_OVERVIEW = 'CLOUDCHAT_OVERVIEW'  
export const TICKET_TUTORIAL = 'TICKET_TUTORIAL' 
export const PRE_TICKET_TUTORIAL = 'PRE_TICKET_TUTORIAL'

export const step = (title, intro, element) => { return { title, intro, element } }
export const Step = ({step_id, step_desc, target}) => {
    return {title: step_id, intro: step_desc, element: getTarget(target)}
}
export const status = (is_over, last_see) => { return { is_over, last_see } }

export const Walkthrough = (title, exitOnEsc, exitOnOverlayClick, showBullet) => introJs()
    .addSteps(store.getters.getSteps(title))
    .setOptions({
        exitOnEsc: exitOnEsc? exitOnEsc : false,
        exitOnOverlayClick: exitOnOverlayClick ? exitOnOverlayClick : false,
        showBullets: showBullet ? showBullet : true,
    })

export const buildIntroJS = (steps, exitOnEsc, exitOnOverlayClick) => introJs()
    .addSteps(steps)
    .setOptions({
        exitOnEsc: exitOnEsc? exitOnEsc : false,
        exitOnOverlayClick: exitOnOverlayClick ? exitOnOverlayClick : false,
    })

export const getTarget = ref => document.querySelectorAll(`[data-step='${ref}']`).item(0)


export const focus = steps => buildIntroJS(steps).start()