/* global axios */
import ApiClient from './ApiClient';

class ConversationApi extends ApiClient {
  constructor() {
    super('conversations', { accountScoped: true });
  }

  getLabels(conversationID) {
    return axios.get(`${this.url}/${conversationID}/labels`);
  }

  updateLabels(conversationID, labels) {
    return axios.post(`${this.url}/${conversationID}/labels`, { labels });
  }

  createSuggestionUsage(conversationId, suggestionIntentId, suggestionIntentLabel, suggestionIntentResponse, incomingMessage, suggestionConfidence) {
    return axios.post(`${this.url}/${conversationId}/suggestion_usages`, { suggestion_intent_label: suggestionIntentLabel, incoming_message: incomingMessage, suggestion_intent_response: suggestionIntentResponse, suggestion_intent_id: suggestionIntentId, suggestion_confidence: suggestionConfidence });
  }

  updateResponseSended(conversationId, suggestionIntentId, finalResponse) {
    return axios.post(`${this.url}/${conversationId}/suggestion_usages/set_response_sended`, { suggestion_intent_id: suggestionIntentId, final_response: finalResponse });
  }

  setSuggestionClicked(conversationId, suggestionIntentId, clickedBy) {
    return axios.post(`${this.url}/${conversationId}/suggestion_usages/set_suggestion_clicked`, { suggestion_intent_id: suggestionIntentId , clicked_by: clickedBy});
  }
}

export default new ConversationApi();
