<template>
  <form class="contact--form" @submit.prevent="handleSubmit">
    <div class="row">
      <div class="columns">
        <label
          v-for="input in formFields"
          :key="input.id"
          :class="{ error: $v.fields[input.id].$error }"
        >
          {{ input.name }}
          <input
            v-if="input.type != 'textarea' && input.type != 'option' && input.type != 'reminder'"
            v-model="fields[input.id]"
            :type="input.type"
            placeholder=""
            @input="$v.fields[input.id].$touch"
          />
          <textarea
            v-else-if="input.type == 'textarea'"
            v-model="fields[input.id]"
            @input="$v.fields[input.id].$touch"
          />
          <select
            v-else-if="input.type == 'option'"
            v-model="fields[input.id]"
            @input="$v.fields[input.id].$touch"
          >
            <option
              v-for="item in input.values"
              :key="item.value"
              :value="item.value"
            >
              {{ item.display }}
            </option>
          </select>
          
          <div 
            v-else-if="input.type === 'reminder'" 
            v-html="input.content"
            class="reminder" 
          ></div>

          <span v-if="$v.fields[input.id].$error" class="message">
            <span v-if="!$v.fields[input.id].required">Obrigatório</span>
            <span v-if="$v.fields[input.id].deniedValues === false">{{$v.fields[input.id].$params.deniedValues.message}}</span>
          </span>
        </label>
      </div>
      <div class="columns view">
        <messages-view
          :show-reply="false"
          :inbox-id="inboxId"
          :is-contact-panel-open="false"
          :show-ticket-content="true"
          @contact-panel-toggle="() => {}"
        />
      </div>
    </div>
    <div class="modal-footer">
      <div class="medium-12 columns">
        <woot-submit-button :loading="inProgress" button-text="Enviar" />
        <button class="button clear" @click.prevent="onCancel">
          Não enviar
        </button>
      </div>
    </div>

    <woot-delete-modal
      :show.sync="warningModal.visible"
      :on-close="closeWarningModal"
      :on-confirm="() => handleSubmit(null, true)"
      title="Atenção"
      :message="warningModal.message"
      confirm-text="Estou ciente, enviar mesmo assim"
      reject-text="Não enviar"
    />
  </form>
</template>

<script>
import alertMixin from 'shared/mixins/alertMixin';
import { required } from 'vuelidate/lib/validators';
import MessagesView from './../../components/widgets/conversation/MessagesView.vue';
import { mountCustomValidations } from './ResolveActionFormValidations'

export default {
  mixins: [alertMixin],
  components: {
    MessagesView,
  },
  props: {
    inboxId: {
      type: Number,
    },
    formFields: {
      type: Array,
      default: () => [],
    },
    inProgress: {
      type: Boolean,
      default: false,
    },
    onSubmit: {
      type: Function,
      default: () => {},
    },
    onCancel: {
      type: Function,
      default: () => {},
    },
    labels: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      fields: this.formFields.reduce((acc, item) => {
        return { ...acc, [item.id]: null };
      }, {}),
      warningModal: {
        visible: false,
        message: null
      }
    };
  },
  validations() {
    return {
      fields: this.formFields.reduce((acc, item) => {
        let validations = {}

        if(item.required) validations.required = required

        if(item.validations) {
          validations = { ...validations, ...mountCustomValidations(item.validations.filter(v => !v.warning)) }
        }

        return { 
          ...acc, 
          [item.id]: validations 
        }
      }, {}),
    };
  },
  computed: {
    formFieldsWarnings() {
      return this.formFields.reduce((acc, item) => {
        let validations = {}

        if(item.validations) {
          validations = { ...validations, ...mountCustomValidations(item.validations.filter(v => v.warning)) }
        }

        return { 
          ...acc, 
          [item.id]: validations 
        }
      }, {})
    }
  },
  methods: {
    closeWarningModal() {
      this.warningModal = {
        visible: false
      } 
    },
    handleWarnings() {
      return this.formFields.every((field) => {
        const validations = this.formFieldsWarnings[field.id]
        const validationsKeys = Object.keys(validations)

        if(!validationsKeys.length) return true

        return validationsKeys.every((validationKey) => {
          const isValid = validations[validationKey](this.fields[field.id])

          if(!isValid) {
            this.warningModal = {
              visible: true,
              message: field.validations.find(v => v.hasOwnProperty(validationKey)).message
            }

            return false
          }

          return true
        })
      })
    },
    async handleSubmit(_, ignoreWarnings) {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      if(!ignoreWarnings && !this.handleWarnings()) {
        return
      }

      this.closeWarningModal()

      try {
        let message = this.formFields
          .map(
            item =>
              `${item.name}: ${
                this.fields[item.id] || (item.type == 'checkbox' ? false : '')
              }`
          )
          .join('\r\n');

        let data = {}
        this.formFields
          .filter(field => field.sendToBackend !== false)
          .forEach(
            item =>
              data = {
                ...data,
                [item.id] : this.getValueField(item)
              }
          );

        await this.onSubmit(message, data);
      } catch (error) {
        console.error(error);
      }
    },
    getValueField(item) {
        return this.fields[item.id] ? this.fields[item.id] : (item.type == 'checkbox' ? false : '')
    },
  },
};
</script>

<style scoped lang="scss">
.reminder {
  padding: 10px;
  border-radius: 4px;
  background: #ffaeae;
  border: 1px solid #cb7676;
  font-size: 12px;
}
.contact--form {
  padding: var(--space-normal) var(--space-large) var(--space-large);

  .columns {
    padding: 0 var(--space-smaller);
  }
}

.view > .view-box {
  height: 50rem;
}

.input-group-label {
  font-size: var(--font-size-small);
}
</style>
