import fromUnixTime from 'date-fns/fromUnixTime';
import format from 'date-fns/format';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import differenceInMinutesFns from 'date-fns/differenceInMinutes';
import { ptBR } from 'date-fns/locale';
import differenceInSeconds from 'date-fns/differenceInSeconds';

export default {
  methods: {
    messageStamp(time, dateFormat = 'h:mm a') {
      const unixTime = fromUnixTime(time);
      return format(unixTime, dateFormat);
    },
    dynamicTime(time) {
      const unixTime = fromUnixTime(time);
      return formatDistanceToNow(unixTime, { addSuffix: false, locale: ptBR });
    },
    differenceInMinutes(time) {
      return differenceInMinutesFns(Date.now(), fromUnixTime(time), { roundingMethod: 'ceil' })
    },
    differenceInSecondsBetween(timeA, timeB) {
      return differenceInSeconds(timeA, timeB)
    }
  },
};
