import axios from 'axios'

export default {
  getSuggestion(message, inboxId, conversationId, projectName) {
    return axios.post('https://us-central1-cloud-thanos.cloudfunctions.net/intent_messages', {
      message: message,
      enviroment: "aefbd99d-459c-4221-a821-6d180fd3adb6",
      agent_id: "1faf146a-8814-4b7f-816a-8fc06ee44c27",
      inbox_id: inboxId,
      bot_type: "cx",
      session_id: conversationId,
      is_assist: false,
      dialogflow_project_id: projectName
    })
  }
}