<template>
  <mention-box :items="items" @mention-select="handleMentionClick" />
</template>

<script>
import { mapGetters } from 'vuex';
import MentionBox from '../mentions/MentionBox.vue';

export default {
  components: { MentionBox },
  props: {
    searchKey: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters({
      cannedMessages: 'getCannedResponses',
      currentChat: 'getSelectedChat',
      getInbox: 'inboxes/getInbox',
    }),
    items() {
      const inbox = this.getInbox(this.currentChat.inbox_id);

      const globalCannedMessages = this.cannedMessages.filter(
        al => al.kind === 'global'
      );

      const companyCannedMessages = this.cannedMessages
        .filter(al => al.kind === 'company')
        .filter(al => al.identifier === inbox.company);

      const projectCannedMessages = this.cannedMessages
        .filter(al => al.kind === 'project')
        .filter(al => al.identifier === inbox.project);

      const inboxCannedMessages = this.cannedMessages
        .filter(al => al.kind === 'inbox')
        .filter(al => al.identifier === inbox.name.toLowerCase());

      const usedCannedIds = this.currentChat?.messages
        ?.filter(m => m.canned_response_id)
        .map(m => m.canned_response_id);

      return [
        ...globalCannedMessages,
        ...companyCannedMessages,
        ...projectCannedMessages,
        ...inboxCannedMessages,
      ]
        .filter(({ id }) => !usedCannedIds.includes(id))
        .map(cannedMessage => ({
          id: cannedMessage.id,
          label: cannedMessage.short_code,
          key: cannedMessage.id,
          description: cannedMessage.content,
        }));
    },
  },
  watch: {
    searchKey() {
      this.fetchCannedResponses();
    },
  },
  mounted() {
    this.fetchCannedResponses();
  },
  methods: {
    fetchCannedResponses() {
      const inboxId = this.currentChat.inbox_id;
      const inboxName = this.getInbox(inboxId)
        .name.replace(/ /g, '-')
        .toLowerCase();

      this.$store.dispatch('getCannedResponse', {
        searchKey: this.searchKey,
        inbox: inboxName,
      });
    },
    handleMentionClick(item = {}) {
      this.$emit('click', item);
    },
  },
};
</script>
