<template>
  <modal :show.sync="show" :on-close="onClose">
    <woot-modal-header :header-title="title" :header-content="message" />
    <div class="modal-footer delete-item">
      <button type="button" class="alert button nice" @click="onConfirm">
        {{ confirmText }}
      </button>
      <button type="button" class="button clear" @click="onClose">
        {{ rejectText }}
      </button>
    </div>
  </modal>
</template>

<script>
import Modal from '../../Modal';

export default {
  components: {
    Modal,
  },
  props: {
    show: Boolean,
    onClose: Function,
    onConfirm: Function,
    title: String,
    message: String,
    confirmText: String,
    rejectText: String,
  },
};
</script>
