<template>
  <li class="message-suggestion" v-if="suggestion.visible">

    <knowledge-base-modal
         :show="KBData.show"
         :hyperlink="KBData.hyperlink"
         @on-close="hideKB"
    />

    <div class="message-suggestion__container" :class="{'message-suggestion__container--out': animationOut}">
      <div class="message-suggestion__wrapper">
        <div class="message-suggestion__title">Sugestão de seção</div>
        <div class="message-suggestion__subtitle">Estas seções podem te ajudar:</div>
        <div class="message-suggestion__hint"></div>

        <button class="message-suggestion__content" v-for="(data, index) in suggestion.data.response" @click="handleClick(data)" :key="index">
          <spinner v-if="suggestion.loading" />

          <div v-if="!suggestion.loading && suggestion.data">
            {{data.split(separator_key)[0]}}
          </div>
        </button>
      </div>
    </div>
  </li>
</template>
<script>
import { mapGetters } from 'vuex';
import Spinner from 'shared/components/Spinner';
import KnowledgeBaseModal from '../modal/KnowledgeBaseModal.vue';

export default {
  components: {
    Spinner,
    KnowledgeBaseModal
  },
  props: {
    messageContent: {
      type: String,
      required: false,
    }
  },
  data() {
    return {
      animationOut: false,
      KBData: {show: false, hyperlink: ""},
      separator_key: "#$$$#"
    };
  },
  computed: {
    ...mapGetters({
      suggestion: 'getSuggestion',
      currentChat: 'getSelectedChat',
      currentUser: 'getCurrentUser',
    }),
    getInbox(){
      if(this.currentChat) {
        return this.$store.getters['inboxes/getInbox'](this.currentChat.inbox_id);
      }
    },
    getProjectName() {
      return this.getInbox.project
    }
  },
  mounted() {
    this.fetchSuggestion()

    this.$watch('messageContent', this.fetchSuggestion)

    this.$watch('currentChat', (newChat, oldChat) => {  
      if(newChat.id !== oldChat.id) {
        this.$store.dispatch('setVisible', false)
      }
    })

    this.$watch('suggestion.data', () => {
      if(this.suggestion.data.response) {
        const conversationPanel = document.querySelector('.conversation-panel');
        conversationPanel.scrollTop = conversationPanel.scrollHeight

        bus.$emit('setSuggestion', this.suggestion.data)
      }
    })
  },
  methods: {
    fetchSuggestion() {
      if(this.messageContent) {
        this.$store.dispatch('fetchSuggestion', {
          message: this.messageContent,
          conversationId: this.currentChat.id,
          inboxId: this.currentChat.inbox_id,
          projectName: this.getProjectName
        })
      }
    },
    handleClick(data){
      this.setClicked()
      this.openSectionOnKB(data)
    },
    openSectionOnKB(data) {
      this.KBData = {...this.KBData, show: true, hyperlink: data.split(this.separator_key)[1]}
    },
    hideKB(){
      this.KBData = {...this.KBData, show: false}
    },
    setClicked(){
       this.$store.dispatch('setSuggestionClicked', {
        conversationId: this.currentChat.id,
        suggestionIntentId: this.suggestion.data.id,
        clickedBy: this.currentUser.id,
      })
    }
  },
};
</script>

<style lang="scss" scoped>
  .message-suggestion {
    margin: 1.4rem 1.6rem;
    display: flex;
    justify-content: flex-end;

    &__container {
      background: #FFDBBA;
      border: 1px solid #DEC2A7;
      border-radius: 10px 4px 4px 10px;
      padding: 1.4rem 1.4rem 2rem 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      transform: scale(0);
      animation: initContainer .5s cubic-bezier(0.49, 1.7, 0.47, 0.64) forwards;

      &--out {
        transform-origin: center bottom;
        animation: outContainer .3s ease-out forwards;
      }

      @keyframes outContainer {
        from { transform: scale(1) }
        to { transform: scale(0) }
      }

      @keyframes initContainer {
        from { transform: scale(0) }
        to { transform: scale(1) }
      }
    }

    &__wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      opacity: 0;
      animation: initWrapper 1s .5s ease-out forwards;

      @keyframes outWrapper {
        from { opacity: 1 }
        to {  opacity: 0}
      }

      @keyframes initWrapper {
        from { opacity: 0 }
        to { opacity: 1 }
      }
    }

    &__title {
      font-weight: bold;
      color: #AA7E56;
      font-size: 18px;
      margin-bottom: 1rem;
    }

    &__subtitle,
    &__hint {
      color: #C38F5E;
      font-size: 12px;
      font-weight: 400;
    }

    &__hint {
      font-style: italic;
    }

    &__content {
      border: none;
      background: #fff;
      box-shadow: 0px 4px 10px -5px rgba(0, 0, 0, 0.25);
      margin-top: 1.4rem;
      font-size: 12px;
      font-weight: 500;
      border-radius: 4px;
      padding: 1.4rem;
      cursor: pointer;
      text-align: left;
      line-height: 16px;

      &:hover {
        background: #eee;
      }
    }
  }
</style>
