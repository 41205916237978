<template>
  <div
    v-if="items.length"
    class="mention--box"
    :style="{ top: getTopPadding() + 'rem' }"
  >
    <div class="mention--box__preview" v-if="items[selectedIndex]">
      <div class="mention--box__preview__title">Conteúdo:</div>
      {{items[selectedIndex].description}}
    </div>
    <ul class="menu vertical dropdown">
      <li
        v-for="(item, index) in items"
        :id="`mention-item-${index}`"
        :key="item.key"
        :class="{ active: index === selectedIndex }"
        @click="onListItemSelection(index)"
        @mouseover="onHover(index)"
      >
        <a class="text-truncate">
          <strong>{{ item.label.replace(/.*__/, '') }}</strong> - {{ item.description }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => {},
    },
  },
  data() {
    return {
      selectedIndex: 0,
    };
  },
  watch: {
    items(newItems) {
      if (newItems.length < this.selectedIndex + 1) {
        this.selectedIndex = 0;
      }
    },
  },
  mounted() {
    document.addEventListener('keydown', this.keyListener);
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.keyListener);
  },
  methods: {
    getTopPadding() {
      if (this.items.length <= 4) {
        return -(this.items.length * 2.8 + 1.7);
      }
      return -15;
    },
    isUp(e) {
      return e.keyCode === 38 || (e.ctrlKey && e.keyCode === 80); // UP, Ctrl-P
    },
    isDown(e) {
      return e.keyCode === 40 || (e.ctrlKey && e.keyCode === 78); // DOWN, Ctrl-N
    },
    isEnter(e) {
      return e.keyCode === 13;
    },
    keyListener(e) {
      if (this.isUp(e)) {
        if (!this.selectedIndex) {
          this.selectedIndex = this.items.length - 1;
        } else {
          this.selectedIndex -= 1;
        }
      }
      if (this.isDown(e)) {
        if (this.selectedIndex === this.items.length - 1) {
          this.selectedIndex = 0;
        } else {
          this.selectedIndex += 1;
        }
      }
      if (this.isEnter(e)) {
        this.onMentionSelect();
      }
      this.$el.scrollTop = 28 * this.selectedIndex;
    },
    onHover(index) {
      this.selectedIndex = index;
    },
    onListItemSelection(index) {
      this.selectedIndex = index;
      this.onMentionSelect();
    },
    onMentionSelect() {
      this.$emit('mention-select', this.items[this.selectedIndex]);
    },
  },
};
</script>

<style scoped lang="scss">
.mention--box {
  background: var(--white);
  border-bottom: var(--space-small) solid var(--white);
  border-top: 1px solid var(--color-border);
  left: 0;
  padding-top: var(--space-small);
  position: absolute;
  width: 100%;
  z-index: 100;

  .menu {
    max-height: 14rem;
    overflow: auto;
  }

  .active a {
    background: var(--w-500);
  }

  &__preview {
    position: absolute;
    bottom: 100%;
    background: #fff;
    width: 100%;
    padding: 10px 20px 10px;
    border-bottom: 1px solid #ddd;
    border-top: 1px solid #ddd;
    font-size: 12px;

    &__title {
      font-weight: bold;
      font-size: 16px;
      margin-bottom: 10px;
    }
  }
}
</style>
