<template>
  <div class="flex-container actions--container">
    <!--
      # Issue: Remove mute and transcription buttons
      # https://cloudhumans.atlassian.net/jira/software/c/projects/BA2/boards/11?modal=detail&selectedIssue=BA2-184
      <woot-button
      v-if="!currentChat.muted"
      v-tooltip="$t('CONTACT_PANEL.MUTE_CONTACT')"
      class="hollow secondary actions--button"
      icon="ion-volume-mute"
      @click="mute"
    />
    <woot-button
      v-else
      v-tooltip.left="$t('CONTACT_PANEL.UNMUTE_CONTACT')"
      class="hollow secondary actions--button"
      icon="ion-volume-medium"
      @click="unmute"
    />
    <woot-button
      v-tooltip="$t('CONTACT_PANEL.SEND_TRANSCRIPT')"
      class="hollow secondary actions--button"
      icon="ion-share"
      @click="toggleEmailActionsModal"
    /> -->
    <woot-button
      v-if="isTutorialButtonVisible"
      color-scheme="primary"
      class-names="resolve-help"
      @click="initResolveTutorial()"
    >
    Preciso encerrar o ticket, o que eu faço?
    </woot-button>

    <resolve-action
      v-if="!isBlocked"
      :conversation-id="currentChat.id"
      :status="currentChat.status"
    />

    <woot-button
      v-if="showNextAuditButton"
      color-scheme="primary"
      class-names="button"
      @click="nextAudit"
    >
      Próxima devolutiva
      <i class="ion-arrow-right-c" />
    </woot-button>

    <email-transcript-modal
      v-if="showEmailActionsModal"
      :show="showEmailActionsModal"
      :current-chat="currentChat"
      @cancel="toggleEmailActionsModal"
    />

  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { mixin as clickaway } from 'vue-clickaway';
import alertMixin from 'shared/mixins/alertMixin';
import EmailTranscriptModal from './EmailTranscriptModal';
import ResolveAction from '../../buttons/ResolveAction';
import { resolveSteps } from '../../widgets/conversation/steps/ReplyBoxSteps'
import flags from '../../../store/modules/flags';

export default {
  components: {
    EmailTranscriptModal,
    ResolveAction,
  },
  mixins: [alertMixin, clickaway],
  data() {
    return {
      showConversationActions: false,
      showEmailActionsModal: false,
    };
  },
  computed: {
    ...mapGetters({
      currentChat: 'getSelectedChat',
      currentUser: 'getCurrentUser',
      memberStatus: 'projectMemberRestrictions/getState',
      userLevel: 'projectMemberRestrictions/getUserLevel',
      flags: 'getAppFlags',

    }),
    isBlocked() {return this.currentUser.inboxes_blocked?.includes(this.currentChat.inbox_id)},
    showNextAuditButton() {
      const audit = new URLSearchParams(location.search).get('audit');
      return audit && this.currentChat.audit_comments;
    },
    isTutorialButtonVisible() {
      return this.memberStatus === 'trainee' && this.userLevel === 0 && !!this.flags.tutorial_ticket.projects.includes(this.currentChat.project)
    }
  },
  methods: {
    mute() {
      this.$store.dispatch('muteConversation', this.currentChat.id);
      this.showAlert(this.$t('CONTACT_PANEL.MUTED_SUCCESS'));
      this.toggleConversationActions();
    },
    unmute() {
      this.$store.dispatch('unmuteConversation', this.currentChat.id);
      this.showAlert(this.$t('CONTACT_PANEL.UNMUTED_SUCCESS'));
      this.toggleConversationActions();
    },
    toggleEmailActionsModal() {
      this.showEmailActionsModal = !this.showEmailActionsModal;
      this.hideConversationActions();
    },
    toggleConversationActions() {
      this.showConversationActions = !this.showConversationActions;
    },
    hideConversationActions() {
      this.showConversationActions = false;
    },
    async nextAudit() {
      await this.$store.dispatch('getAgentHasUnreadAudit');
    },
    initResolveTutorial() {
      resolveSteps(this.currentChat.id, this.memberStatus, this.userLevel, this.currentChat.project, this.flags);
    },
  },
};
</script>
<style scoped lang="scss">
.actions--container {
  align-items: center;
  .button {
    margin-left: 10px;
  }
  .resolve-help {
    margin-right: 10px;
  }
}
.more--button {
  align-items: center;
  display: flex;
  margin-left: var(--space-small);
}
.actions--container {
  position: relative;
}
.dropdown-pane {
  right: var(--space-minus-small);
  top: 48px;
}
.icon {
  margin-right: var(--space-smaller);
  min-width: var(--space-normal);
}
</style>
