<template>
  <span v-if="!assignedView" class="inbox--name">
    <i :class="computedInboxClass" />
    {{ inbox.name }}
  </span>
  <span v-else class="inbox-name__container">
    <span class="inbox-name__text">Você está atendendo um ticket do projeto</span>
    <span class="inbox-name__project">
      {{ inbox.project }}
    </span>
  </span>
</template>
<script>
import { getInboxClassByType } from 'dashboard/helper/inbox';

export default {
  props: {
    assignedView: {
      type: Boolean,
      default: false
    },
    inbox: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    computedInboxClass() {
      const { phone_number: phoneNumber, channel_type: type } = this.inbox;
      const classByType = getInboxClassByType(type, phoneNumber);
      return classByType;
    },
  },
};
</script>

<style scoped lang="scss">
.inbox--name {
  padding: var(--space-micro) 0;
  line-height: var(--space-slab);
  font-weight: var(--font-weight-medium);
  background: none;
  color: var(--s-500);
  font-size: var(--font-size-mini);
}

.inbox-name {
  &__container {
    padding: var(--space-micro) 0;
    line-height: var(--space-slab);
    font-weight: var(--font-weight-medium);
    background: none;
    color: #333;
    font-size: 14px;
    background: #fff0e0;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #e7d0b8;
  }

  &__project {
    font-weight: bold;
  }
}
</style>
